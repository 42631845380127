import { ComponentFactoryResolver, ViewChild, Input, Type, OnInit, Injector, ViewContainerRef } from "@angular/core";
import { ComponentHostDirective } from "./component-host.directive";
import { GlobalRegistryService } from "../registry/global-registry.service";

export abstract class AbstractDynamicComponent implements OnInit {

    public abstract get componentTopic(): string;
      
    @Input() component: string | Type<any>;
    @ViewChild(ComponentHostDirective) placeholder: ComponentHostDirective;
    protected get viewContainerRef(): ViewContainerRef { return this.placeholder.viewContainerRef; }
    constructor(protected componentFactoryResolver: ComponentFactoryResolver,
                protected registry: GlobalRegistryService,
                protected injector: Injector) {
    }

    ngOnInit() {
        this.loadComponent();
    }

    protected loadComponent(): void {
        let componentType: Type<any>
        if (typeof this.component === "string")
            componentType = this.registry.getValue(this.componentTopic, <string>this.component);
        else componentType = <Type<any>> this.component;

        if (componentType === undefined) {
            console.log('Unknown component of type: ', this.component);
            return;
        }
        let viewContainerRef = this.viewContainerRef;
        viewContainerRef.clear();

        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        let componentRef = viewContainerRef.createComponent(componentFactory, undefined, this.injector)
        this.initializeComponent(componentRef.instance);
    }

    protected abstract initializeComponent(component: any): void;
}

