import { Observable } from "rxjs";
import { ClaimRequest, AuthenticationContext, AuthenticationRequest, Authorizable, ChangePasswordModel } from "./access-control.model";

export type AuthorizeRequest = string | string[] | ClaimRequest | ClaimRequest[] | Authorizable;

export abstract class AccessControlService {

    context: AuthenticationContext;
    settings: AccessControlSetting;

    /** Authenticate a user with given username and password */
    abstract authenticate(request: AuthenticationRequest): Observable<AuthenticationContext>;
    /** Update user password */
    abstract changePassword(data: ChangePasswordModel): Observable<any>;
    abstract signOut(): void;

    /** obtain the security context for the current security */
    abstract requestSecurityContext(): Observable<AuthenticationContext>;

    /** Check if the current principle (if any) is authorized for given request(s) */
    abstract authorize(request: AuthorizeRequest, element?: AuthorizableResource): boolean;
}


export interface AccessControlSetting {
    autoLogin?: AutoAuthenticationRequest;
    tokenServer?: string;
    authenticationApi?: string;
}

export interface AuthorizableResource {
    ownerId?: string;
    properties?: ResourceAccessControlList;
}

export interface ResourceAccessControlList {
    objectACL?: ResourceAccessControlEntry[];
}

export interface ResourceAccessControlEntry {
    readable?: boolean;
    writable?: boolean;
    deletable?: boolean;
    ownersihp?: boolean;
}

export interface AutoAuthenticationRequest extends AuthenticationRequest {
    enabled: boolean;
}