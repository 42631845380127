import { Component, Input, forwardRef } from "@angular/core";
import { DashboardOption } from "./dashboard.component";

@Component({
    selector: "widget",
    template: require("./widget.component.pug")
    // viewProviders: [
    //     { provide: WIDGET_CONTAINER_TOKEN, useExisting: forwardRef(()=> WidgetContainerComponent) }
    // ]
})
export class WidgetContainerComponent implements WidgetContainer {
    @Input("definition")
    widget: WidgetDescription;
    @Input("option")
    option: DashboardOption;
}

export interface WidgetContainer {
    widget?: WidgetDescription;
}

export interface WidgetDescription {
    name?: string;
    caption?: string;
    dataSet?: string;
    component?: string;
    data?: WidgetData;
    column?: number;
}

export interface WidgetData {
    period?: string;
    chartType?: string;
    tableManifest?: string;
}
