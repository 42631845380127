import {
    ActionHandlingContext,
    ActionExecuteHandler,
    DefaultActionHandlingContext,
    ActionExecutingHandler,
    ActionExecutedHandler,
    ActionPreparingContextHandler,
    ActionHandlingStatus
} from "./action-handler";
import { Conditional } from "../condition/conditional";
import { ConditionChecker } from "../condition/conditional-checker";
import { ConditionalItem } from "../condition/conditional-item";
import { MessageService } from "../message/message.service";
import { AbstractMessageHandler } from "../message/message-handler";
import { MessageSubscription } from "..";
import { deepCopy } from "../utils/json.util";

export type ActionType = "link" | "event";

export namespace ActionMessageChannel {
    export const CHANNEL_ID = "action_handler";
    export const EXECUTE_TOPIC = "execute";
    export const POST_EXECUTE_TOPIC = "executed";
    export const PRE_EXECUTE_TOPIC = "executing";
    export const PREPARE_CONTEXT = "prepate_context";

    export function subscribeActionMessageHandler(
        messageService: MessageService,
        handler: AbstractMessageHandler
    ): MessageSubscription {
        let subscription = messageService.subsribe(
            ActionMessageChannel.CHANNEL_ID,
            msg => {
                let context = <ActionHandlingContext>msg.payload;
                if (!context || context.status != ActionHandlingStatus.Unhandled) return;
                switch (msg.topic) {
                    case ActionMessageChannel.PRE_EXECUTE_TOPIC:
                        if ("handleActionExecuting" in handler) {
                            (<ActionExecutingHandler>handler).handleActionExecuting(context);
                            msg.reciever = context.result || msg.reciever;
                        }
                        return;
                    case ActionMessageChannel.EXECUTE_TOPIC:
                        if ("handleActionExecute" in handler) {
                            (<ActionExecuteHandler>handler).handleActionExecute(context);
                            msg.result = context.result;
                        }
                        return;
                    case ActionMessageChannel.POST_EXECUTE_TOPIC:
                        if ("handleActionExecuted" in handler) {
                            (<ActionExecutedHandler>handler).handleActionExecuted(context);
                            msg.result = context.result;
                        }
                        return;
                    case ActionMessageChannel.PREPARE_CONTEXT:
                        if ("handleActionPreparingContext" in handler) {
                            (<ActionPreparingContextHandler>handler).handleActionPreparingContext(context);
                        }
                        return;
                    default:
                        break;
                }
            },
            handler
        );
        handler.messageSubscriptions.push(subscription);
        return subscription;
    }
}

export class Action extends ConditionalItem {
    private _caption?: string;
    private _hint?: string;
    private _handler?: string | ActionExecuteHandler;

    public name?: string;
    public icon?: string;
    public type?: ActionType;
    public url?: string;
    public context?: ActionHandlingContext;
    public options?: any;
    public messageService?: MessageService;

    public get caption(): string {
        return this._caption || this.name;
    }
    public set caption(value: string) {
        this._caption = value;
    }
    public get hint(): string {
        return this._hint || this.caption;
    }
    public set hint(value: string) {
        this._hint = value;
    }

    public get handler(): string | ActionExecuteHandler {
        return this._handler;
    }

    constructor(
        action?: Action | string,
        handler?: ActionExecuteHandler,
        context?: ActionHandlingContext,
        checker?: ConditionChecker
    ) {
        super(action);
        if (typeof action === "string") {
            this.url = action;
        } else if (typeof action === "object") {
            this._caption = action.caption;
            this._hint = action.hint;
            this._handler = action.handler;
            this.name = action.name;
            this.icon = action.icon;
            this.type = action.type;
            this.url = action.url;
            this.options = action.options;
            this.enabledWhen = Conditional.create(action.enabledWhen);
            this.visibleWhen = Conditional.create(action.visibleWhen);
        }
        this.checker = checker;
        this.context = context;
        this._handler = this._handler || handler;
    }

    public mergeProperty(action: Action | string) {
        if (!action) return;
        if (typeof action === "string") {
            this.url = <string>action;
            return;
        }
        this._caption = this._caption || action.caption;
        this._hint = this._hint || action.hint;
        this._handler = this._handler || action._handler;
        this.name = this.name || action.name;
        this.icon = this.icon || action.icon;
        this.type = this.type || action.type;
        this.url = this.url || action.url;
        this.options = this.options || action.options;
        this.enabledWhen = Conditional.mergeCondition([this.enabledWhen, action.enabledWhen]);
        this.visibleWhen = Conditional.mergeCondition([this.visibleWhen, action.visibleWhen]);
    }

    public execute(context?: ActionHandlingContext): void {
        let ctx = new DefaultActionHandlingContext(this.context).mergeProperties(context);
        ctx.action = this;
        if (this.handler && typeof this.handler !== "string") {
            this.handler.handleActionExecute(ctx);
            return;
        }
        if (!this.messageService) throw new Error("Message service is undefined for action: " + this.name);
        let executingMessage = this.messageService.send({
            channel: ActionMessageChannel.CHANNEL_ID,
            topic: ActionMessageChannel.PRE_EXECUTE_TOPIC,
            sender: this,
            reciever: this.handler,
            payload: ctx
        });
        this.messageService.send({
            channel: ActionMessageChannel.CHANNEL_ID,
            topic: ActionMessageChannel.EXECUTE_TOPIC,
            sender: this,
            reciever: this.handler || executingMessage.reciever,
            payload: ctx
        });
    }
}
