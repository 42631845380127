import {
    Component,
    OnDestroy,
    OnChanges,
    AfterViewInit,
    forwardRef,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    ContentChild,
    TemplateRef,
    ViewEncapsulation,
    HostListener,
    HostBinding,
    ViewChild,
    ElementRef,
    ChangeDetectionStrategy,
    Inject,
    SimpleChanges,
    ContentChildren,
    QueryList,
    InjectionToken,
    Attribute,
    Optional
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, RequiredValidator, NG_VALIDATORS } from "@angular/forms";
import { takeUntil, startWith, tap, debounceTime, map, filter } from "rxjs/operators";
import { Subject, merge } from "rxjs";

import {
    NgOptionTemplateDirective,
    NgLabelTemplateDirective,
    NgHeaderTemplateDirective,
    NgFooterTemplateDirective,
    NgOptgroupTemplateDirective,
    NgNotFoundTemplateDirective,
    NgTypeToSearchTemplateDirective,
    NgLoadingTextTemplateDirective,
    NgMultiLabelTemplateDirective
} from "./ng-templates.directive";

import { ConsoleService } from "./console.service";
import { isDefined, isFunction, isPromise, isObject } from "./value-utils";
import { ItemsList } from "./items-list";
import { NgOption, KeyCode, NgSelectConfig } from "./ng-select.types";
import { newId } from "./id";
import { NgDropdownPanelComponent } from "./ng-dropdown-panel.component";
import { NgOptionComponent } from "./ng-option.component";

export const NG_SELECT_DEFAULT_CONFIG = new InjectionToken<NgSelectConfig>("ng-select-default-options");
export type DropdownPosition = "bottom" | "top" | "auto";
export type AddTagFn = ((term: string) => any | Promise<any>);
export type CompareWithFn = (a: any, b: any) => boolean;

@Component({
    selector: "ng-select",
    template: require("./ng-select.component.html"),
    styleUrls: [],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NgSelectComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        role: "listbox",
        class: "ng-select",
        "[class.ng-select-single]": "!multiple"
    }
})
export class NgSelectComponent implements OnDestroy, OnChanges, AfterViewInit, ControlValueAccessor {
    // inputs
    @Input()
    items: any[] = [];
    @Input()
    bindLabel: string;
    @Input()
    bindValue: string;
    @Input()
    clearable = true;
    @Input()
    markFirst = true;
    @Input()
    placeholder: string;
    @Input()
    readOnly: boolean = false;
    @Input()
    notFoundText: string;
    @Input()
    typeToSearchText: string;
    @Input()
    addTagText: string;
    @Input()
    loadingText: string;
    @Input()
    clearAllText: string;
    @Input()
    dropdownPosition: DropdownPosition = "auto";
    @Input()
    appendTo: string;
    @Input()
    loading = false;
    @Input()
    closeOnSelect = true;
    @Input()
    hideSelected = false;
    @Input()
    selectOnTab = false;
    @Input()
    maxSelectedItems: number;
    @Input()
    groupBy: string;
    @Input()
    bufferAmount = 4;
    @Input()
    virtualScroll = false;
    @Input()
    selectableGroup = false;
    @Input()
    searchFn: any = null;
    @Input()
    clearSearchOnAdd = true;
    @Input()
    labelForId: any = null;
    @Input()
    @HostBinding("class.ng-select-typeahead")
    typeahead: Subject<string>;
    @Input()
    @HostBinding("class.ng-select-multiple")
    multiple = false;
    @Input()
    @HostBinding("class.ng-select-taggable")
    addTag: boolean | AddTagFn = false;
    @Input()
    @HostBinding("class.ng-select-searchable")
    searchable = true;
    @Input()
    @HostBinding("class.ng-select-opened")
    isOpen = false;

    @Input()
    get compareWith() {
        return this._compareWith;
    }
    set compareWith(fn: CompareWithFn) {
        if (!isFunction(fn)) {
            throw Error("`compareWith` must be a function.");
        }
        this._compareWith = fn;
    }

    // output events
    @Output("blur")
    blurEvent = new EventEmitter();
    @Output("focus")
    focusEvent = new EventEmitter();
    @Output("change")
    changeEvent = new EventEmitter();
    @Output("open")
    openEvent = new EventEmitter();
    @Output("close")
    closeEvent = new EventEmitter();
    @Output("search")
    searchEvent = new EventEmitter();
    @Output("clear")
    clearEvent = new EventEmitter();
    @Output("add")
    addEvent = new EventEmitter();
    @Output("remove")
    removeEvent = new EventEmitter();
    @Output("lazyLoad")
    lazyLoadEvent = new EventEmitter();
    @Output("scrollToEnd")
    scrollToEnd = new EventEmitter<{ start: number; end: number }>();

    // custom templates
    @ContentChild(NgOptionTemplateDirective, { read: TemplateRef })
    optionTemplate: TemplateRef<any>;
    @ContentChild(NgOptgroupTemplateDirective, { read: TemplateRef })
    optgroupTemplate: TemplateRef<any>;
    @ContentChild(NgLabelTemplateDirective, { read: TemplateRef })
    labelTemplate: TemplateRef<any>;
    @ContentChild(NgMultiLabelTemplateDirective, { read: TemplateRef })
    multiLabelTemplate: TemplateRef<any>;
    @ContentChild(NgHeaderTemplateDirective, { read: TemplateRef })
    headerTemplate: TemplateRef<any>;
    @ContentChild(NgFooterTemplateDirective, { read: TemplateRef })
    footerTemplate: TemplateRef<any>;
    @ContentChild(NgNotFoundTemplateDirective, { read: TemplateRef })
    notFoundTemplate: TemplateRef<any>;
    @ContentChild(NgTypeToSearchTemplateDirective, { read: TemplateRef })
    typeToSearchTemplate: TemplateRef<any>;
    @ContentChild(NgLoadingTextTemplateDirective, { read: TemplateRef })
    loadingTextTemplate: TemplateRef<any>;

    @ViewChild(forwardRef(() => NgDropdownPanelComponent))
    dropdownPanel: NgDropdownPanelComponent;
    @ContentChildren(NgOptionComponent, { descendants: true })
    ngOptions: QueryList<NgOptionComponent>;
    @ViewChild("filterInput")
    filterInput: ElementRef;

    @HostBinding("class.ng-select-disabled")
    disabled = false;
    @HostBinding("class.ng-select-filtered")
    get filtered() {
        return !!this.filterValue && this.searchable;
    }

    itemsList = new ItemsList(this);
    viewPortItems: NgOption[] = [];
    filterValue: string = null;
    dropdownId = newId();
    selectedItemId = 0;

    private _defaultLabel = "label";
    private _primitive: boolean;
    private _focused: boolean;
    get _required(): boolean {
        return this._requireValidator && this._requireValidator.required ? true : false;
    }
    private _manualOpen: boolean;
    private _pressedKeys: string[] = [];
    private _compareWith: CompareWithFn;
    private _requireValidator: RequiredValidator;

    private readonly _destroy$ = new Subject<void>();
    private readonly _keyPress$ = new Subject<string>();
    private _onChange = (_: NgOption) => {};
    private _onTouched = () => {};

    clearItem = (item: any) => {
        const option = this.selectedItems.find(x => x.value === item);
        this.unselect(option);
    };

    constructor(
        @Inject(NG_SELECT_DEFAULT_CONFIG) config: NgSelectConfig,
        @Attribute("class") public classes: string,
        private _cd: ChangeDetectorRef,
        private _console: ConsoleService,
        public elementRef: ElementRef,
        @Optional()
        @Inject(NG_VALIDATORS)
        validators: Array<any>
    ) {
        this._mergeGlobalConfig(config);
        if (validators) this._requireValidator = validators.find(v => v instanceof RequiredValidator);
    }

    get firstSelectedItem(): NgOption {
        return this.hasValue ? this.itemsList.selectedItems[0] : undefined;
    }

    get selectedItems(): NgOption[] {
        return this.itemsList.selectedItems;
    }

    get selectedValues() {
        return this.selectedItems.map(x => x.value);
    }

    get selectedLabels() {
        return this.selectedItems.map(x => x.label);
    }

    get hasValue() {
        return this.selectedItems.length > 0;
    }

    ngOnInit() {
        this._handleKeyPresses();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.multiple) {
            this.itemsList.clearSelected();
        }
        if (changes.items) {
            this._setItems(changes.items.currentValue || []);
        }
        if (changes.isOpen) {
            this._manualOpen = true;
        }
    }

    ngAfterViewInit() {
        if (this.items && this.items.length === 0) {
            this._setItemsFromNgOptions();
        }
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    @HostListener("keydown", ["$event"])
    handleKeyDown($event: KeyboardEvent) {
        if (KeyCode[$event.which]) {
            switch ($event.which) {
                case KeyCode.ArrowDown:
                    this._handleArrowDown($event);
                    break;
                case KeyCode.ArrowUp:
                    this._handleArrowUp($event);
                    break;
                case KeyCode.Space:
                    this._handleSpace($event);
                    break;
                case KeyCode.Enter:
                    this._handleEnter($event);
                    break;
                case KeyCode.Tab:
                    this._handleTab($event);
                    break;
                case KeyCode.Esc:
                    this.close();
                    $event.preventDefault();
                    $event.stopPropagation();
                    break;
                case KeyCode.Backspace:
                    this._handleBackspace();
                    break;
            }
        } else if ($event.key && $event.key.length === 1) {
            this._keyPress$.next($event.key.toLocaleLowerCase());
        }
    }

    handleMousedown($event: MouseEvent) {
        $event.stopPropagation();
        $event.preventDefault();

        const target = $event.target as HTMLElement;
        if (target.className === "ng-clear") {
            this.handleClearClick();
            return;
        }
        if (target.className === "ng-arrow-wrapper") {
            this.handleArrowClick();
            return;
        }

        if (target.className.includes("ng-value-icon")) {
            return;
        }

        if (!this._focused) {
            this.focus();
        }

        if (this.searchable) {
            this.open();
        } else {
            this.toggle();
        }
    }

    handleArrowClick() {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    handleClearClick() {
        if (this.hasValue) {
            this.clearModel();
        }
        this._clearSearch();
        this.focus();
        if (this._isTypeahead) {
            this.typeahead.next(null);
        }
        this.clearEvent.emit();
    }

    clearModel() {
        if (!this.clearable) {
            return;
        }
        this.itemsList.clearSelected();
        this._updateNgModel();
    }

    writeValue(value: any | any[]): void {
        this.itemsList.clearSelected();
        this._handleWriteValue(value);
        this._cd.markForCheck();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._cd.markForCheck();
    }

    toggle() {
        if (!this.isOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    open() {
        if (this.readOnly || this.disabled || this.isOpen || this.itemsList.maxItemsSelected || this._manualOpen) {
            return;
        }

        if (!this._isTypeahead && !this.addTag && this.itemsList.noItemsToSelect) {
            return;
        }
        this.isOpen = true;
        this.itemsList.markSelectedOrDefault(this.markFirst);
        this.openEvent.emit();
        if (!this.filterValue) {
            this.focus();
        }
        this.detectChanges();
    }

    close() {
        if (!this.isOpen || this._manualOpen) {
            return;
        }
        this.isOpen = false;
        this._clearSearch();
        this._onTouched();
        this.closeEvent.emit();
        this._cd.markForCheck();
    }

    toggleItem(item: NgOption) {
        if (!item || item.disabled || this.disabled) {
            return;
        }

        if (this.multiple && item.selected) {
            this.unselect(item);
        } else {
            this.select(item);
        }
    }

    select(item: NgOption) {
        if (!item.selected) {
            this.itemsList.select(item);
            if (this.clearSearchOnAdd) {
                this._clearSearch();
            }

            if (this.multiple) {
                this.addEvent.emit(item.value);
            }
            this._updateNgModel();
        }

        if (this.closeOnSelect || this.itemsList.noItemsToSelect) {
            this.close();
        }
    }

    focus() {
        this.filterInput.nativeElement.focus();
    }

    unselect(item: NgOption) {
        console.log("unselect", item);
        this.itemsList.unselect(item);
        this.focus();
        this._updateNgModel();
        this.removeEvent.emit(item);
    }

    selectTag() {
        let tag;
        if (isFunction(this.addTag)) {
            tag = (<AddTagFn>this.addTag)(this.filterValue);
        } else {
            tag = this._primitive ? this.filterValue : { [this.bindLabel]: this.filterValue };
        }

        const handleTag = (item: any) =>
            this._isTypeahead ? this.itemsList.mapItem(item, null) : this.itemsList.addItem(item);
        if (isPromise(tag)) {
            tag.then((item: any) => this.select(handleTag(item))).catch(() => {});
        } else if (tag) {
            this.select(handleTag(tag));
        }
    }

    showClear() {
        return this.clearable && (this.hasValue || this.filterValue) && !this.disabled && !this.readOnly;
    }

    showAddTag() {
        return (
            this.addTag &&
            this.filterValue &&
            !this.selectedItems.some(x => x.label.toLowerCase() === this.filterValue.toLowerCase()) &&
            !this.loading
        );
    }

    showNoItemsFound() {
        const empty = this.itemsList.filteredItems.length === 0;
        return (
            ((empty && !this._isTypeahead && !this.loading) ||
                (empty && this._isTypeahead && this.filterValue && !this.loading)) &&
            !this.showAddTag()
        );
    }

    showTypeToSearch() {
        const empty = this.itemsList.filteredItems.length === 0;
        return empty && this._isTypeahead && !this.filterValue && !this.loading;
    }

    filter(term: string) {
        this.filterValue = term;
        this.open();

        if (this._isTypeahead) {
            this.typeahead.next(this.filterValue);
        } else {
            this.itemsList.filter(this.filterValue);
            if (this.isOpen) {
                this.itemsList.markSelectedOrDefault(this.markFirst);
            }
        }
    }

    onInputFocus($event: any) {
        if (this._focused) {
            return;
        }

        (<HTMLElement>this.elementRef.nativeElement).classList.add("ng-select-focused");
        this.focusEvent.emit($event);
        this._focused = true;
    }

    onInputBlur($event: any) {
        (<HTMLElement>this.elementRef.nativeElement).classList.remove("ng-select-focused");
        this.blurEvent.emit($event);
        if (!this.isOpen && !this.disabled) {
            this._onTouched();
        }
        this._focused = false;
    }

    onItemHover(item: NgOption) {
        if (item.disabled) {
            return;
        }
        this.itemsList.markItem(item);
    }

    detectChanges() {
        if (!(<any>this._cd).destroyed) {
            this._cd.detectChanges();
        }
    }

    updateDropdownPosition() {
        if (this.dropdownPanel) {
            this.dropdownPanel.updateDropdownPosition();
        }
    }

    public _setItems(items: any[]) {
        const firstItem = items[0];
        this.bindLabel = this.bindLabel || this._defaultLabel;
        this._primitive = !firstItem ? this._primitive : !isObject(firstItem);
        this.itemsList.setItems(items);
        if (items.length > 0 && this.hasValue) {
            this.itemsList.mapSelectedItems();
        }
        if (this.isOpen && isDefined(this.filterValue) && !this._isTypeahead) {
            this.itemsList.filter(this.filterValue);
        }
        if (this._isTypeahead || this.isOpen) {
            this.itemsList.markSelectedOrDefault(this.markFirst);
        }
    }

    private _setItemsFromNgOptions() {
        const handleNgOptions = (options: QueryList<NgOptionComponent>) => {
            this.items = options.map(option => ({
                $ngOptionValue: option.value,
                $ngOptionLabel: option.elementRef.nativeElement.innerHTML,
                disabled: option.disabled
            }));
            this.itemsList.setItems(this.items);
            if (this.hasValue) {
                this.itemsList.mapSelectedItems();
            }
            this.detectChanges();
        };

        const handleOptionChange = () => {
            const changedOrDestroyed = merge(this.ngOptions.changes, this._destroy$);
            merge(...this.ngOptions.map(option => option.stateChange$))
                .pipe(takeUntil(changedOrDestroyed))
                .subscribe(option => {
                    const item = this.itemsList.findItem(option.value);
                    item.disabled = option.disabled;
                    this._cd.markForCheck();
                });
        };

        this.ngOptions.changes
            .pipe(
                startWith(this.ngOptions),
                takeUntil(this._destroy$),
                filter((items: QueryList<any>) => !!items.length)
            )
            .subscribe(options => {
                this.bindLabel = this._defaultLabel;
                handleNgOptions(options);
                handleOptionChange();
            });
    }

    private _isValidWriteValue(value: any): boolean {
        if (!isDefined(value) || (this.multiple && value === "") || (Array.isArray(value) && value.length === 0)) {
            return false;
        }

        const validateBinding = (item: any): boolean => {
            if (isObject(item) && this.bindValue) {
                this._console.warn(`Binding object(${JSON.stringify(item)}) with bindValue is not allowed.`);
                return false;
            }
            return true;
        };

        if (this.multiple) {
            if (!Array.isArray(value)) {
                this._console.warn("Multiple select ngModel should be array.");
                return false;
            }
            return value.every(item => validateBinding(item));
        } else {
            return validateBinding(value);
        }
    }

    private _handleWriteValue(ngModel: any | any[]) {
        if (!this._isValidWriteValue(ngModel)) {
            return;
        }

        const select = (val: any) => {
            let item = this.itemsList.findItem(val);
            if (item) {
                this.itemsList.select(item);
            } else {
                const isValObject = isObject(val);
                const isPrimitive = !isValObject && !this.bindValue;
                if (isValObject || isPrimitive) {
                    this.itemsList.select(this.itemsList.mapItem(val, null));
                } else if (this.bindValue) {
                    item = {
                        [this.bindLabel]: null,
                        [this.bindValue]: val
                    };
                    this.itemsList.select(this.itemsList.mapItem(item, null));
                }
                this.lazyLoadEvent.emit(val);
            }
        };

        if (this.multiple) {
            (<any[]>ngModel).forEach(item => select(item));
        } else {
            select(ngModel);
        }
    }

    private _handleKeyPresses() {
        if (this.searchable) {
            return;
        }

        this._keyPress$
            .pipe(
                takeUntil(this._destroy$),
                tap(letter => this._pressedKeys.push(letter)),
                debounceTime(200),
                filter(() => this._pressedKeys.length > 0),
                map(() => this._pressedKeys.join(""))
            )
            .subscribe(term => {
                const item = this.itemsList.findByLabel(term);
                if (item) {
                    if (this.isOpen) {
                        this.itemsList.markItem(item);
                        this._cd.markForCheck();
                    } else {
                        this.select(item);
                    }
                }
                this._pressedKeys = [];
            });
    }

    private _updateNgModel() {
        const model = [];
        for (const item of this.selectedItems) {
            if (this.bindValue) {
                let resolvedValue = null;
                if (item.children) {
                    resolvedValue = this.itemsList.resolveNested(item.value, this.groupBy);
                } else {
                    resolvedValue = this.itemsList.resolveNested(item.value, this.bindValue);
                }
                model.push(resolvedValue);
            } else {
                model.push(item.value);
            }
        }

        const selected = this.selectedItems.map(x => x.value);
        if (this.multiple) {
            this._onChange(model);
            this.changeEvent.emit(selected);
        } else {
            this._onChange(isDefined(model[0]) ? model[0] : null);
            this.changeEvent.emit(selected[0]);
        }

        this._cd.markForCheck();
    }

    private _clearSearch() {
        if (!this.filterValue) {
            return;
        }

        this.filterValue = null;
        this.itemsList.resetFilteredItems();
    }

    private _scrollToMarked() {
        if (!this.isOpen || !this.dropdownPanel) {
            return;
        }
        this.dropdownPanel.scrollInto(this.itemsList.markedItem);
    }

    private _scrollToTag() {
        if (!this.isOpen || !this.dropdownPanel) {
            return;
        }
        this.dropdownPanel.scrollIntoTag();
    }

    private _handleTab($event: KeyboardEvent) {
        if (!this.isOpen) {
            return;
        }
        if (this.selectOnTab) {
            if (this.itemsList.markedItem) {
                this.toggleItem(this.itemsList.markedItem);
                $event.preventDefault();
            } else if (this.showAddTag()) {
                this.selectTag();
                $event.preventDefault();
            } else {
                this.close();
            }
        } else {
            this.close();
        }
    }

    private _handleEnter($event: KeyboardEvent) {
        if (this.isOpen || this._manualOpen) {
            if (this.itemsList.markedItem) {
                this.toggleItem(this.itemsList.markedItem);
            } else if (this.addTag && this.filterValue) {
                this.selectTag();
            }
        } else {
            this.open();
        }
        $event.preventDefault();
        $event.stopPropagation();
    }

    private _handleSpace($event: KeyboardEvent) {
        if (this.isOpen) {
            return;
        }
        this.open();
        $event.preventDefault();
    }

    private _handleArrowDown($event: KeyboardEvent) {
        if (this._nextItemIsTag(+1)) {
            this.itemsList.unmarkItem();
            this._scrollToTag();
        } else {
            this.itemsList.markNextItem();
            this._scrollToMarked();
        }
        this.open();
        $event.preventDefault();
    }

    private _handleArrowUp($event: KeyboardEvent) {
        if (!this.isOpen) {
            return;
        }

        if (this._nextItemIsTag(-1)) {
            this.itemsList.unmarkItem();
            this._scrollToTag();
        } else {
            this.itemsList.markPreviousItem();
            this._scrollToMarked();
        }
        $event.preventDefault();
    }

    private _nextItemIsTag(nextStep: number): boolean {
        const nextIndex = this.itemsList.markedIndex + nextStep;
        return (
            this.addTag &&
            this.filterValue &&
            this.itemsList.markedItem &&
            (nextIndex < 0 || nextIndex === this.itemsList.filteredItems.length)
        );
    }

    private _handleBackspace() {
        if (this.filterValue || !this.clearable || !this.hasValue) {
            return;
        }

        if (this.multiple) {
            this.unselect(this.itemsList.lastSelectedItem);
        } else {
            this.clearModel();
        }
    }

    private get _isTypeahead() {
        return this.typeahead && this.typeahead.observers.length > 0;
    }

    private _mergeGlobalConfig(config: NgSelectConfig) {
        this.placeholder = this.placeholder || config.placeholder;
        this.notFoundText = this.notFoundText || config.notFoundText;
        this.typeToSearchText = this.typeToSearchText || config.typeToSearchText;
        this.addTagText = this.addTagText || config.addTagText;
        this.loadingText = this.loadingText || config.loadingText;
        this.clearAllText = this.clearAllText || config.clearAllText;
    }
}
