import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AccessControlService, AuthorizeRequest, AccessControlSetting, AuthorizableResource } from "../access-control.service";
import { AuthenticationContext, AuthenticationRequest, ClaimRequest, Authorizable, Claim, IPrinciple, ChangePasswordModel } from "../access-control.model";
import { AuthenticatorService } from "./authenticator";
import { parseClaims } from "../../utils/access-control.util";
import { TypeUtils } from "../../utils/type-utils";
import { ApiAdvisor } from "../../api/api-adivsor";
import { Api } from "../../api/api";
import { ApiAdvisorProvider } from "../../api/api-advisor.provider";

@Injectable()
export class DefaultAccessControlService implements AccessControlService {
    private _settings: AccessControlSetting;
    private _advisor: ApiAdvisor;
    private _requestPrincipleApi: Api<SecurityContext>;
    private _changePasswordApi: Api<any>;
    
    public get settings(): AccessControlSetting { return this._settings; }
    public set settings(value: AccessControlSetting) {
        this._settings = value;
        this._advisor = this.apiProvider.get(this._settings.authenticationApi);
        if (!this._advisor) {
            console.error('Authentication api is not found: ', this._settings.authenticationApi);
            return;
        }
        this._requestPrincipleApi = this._advisor.getApi('getPrinciple', true);
        this._changePasswordApi = this._advisor.getApi('changePassword', true);

        this.authenticator.settings = this._settings;
        if (value.autoLogin && value.autoLogin.enabled)
            this.authenticate(value.autoLogin)
                .subscribe({
                    next: context => console.log("Authentication completed:", context),
                    error: err => console.log("Authentication fail: ", err)
                });
    }

    constructor(private authenticator: AuthenticatorService,
                private apiProvider: ApiAdvisorProvider,
                public context: AuthenticationContext) {
    }

    public authenticate(request: AuthenticationRequest): Observable<AuthenticationContext> {
        let contextSubject = new Subject<AuthenticationContext>();
        this.authenticator.authenticate(request).subscribe({
            next: _ctx => this.internalRequestSecurityContext(contextSubject),
            error: err => contextSubject.error(err)
        })
        return contextSubject;
    }

    public changePassword(data: ChangePasswordModel): Observable<any> {
        if (!this.context || !this.context.authenticated)
            return null;
        if (!data.userId)
            data.userId = this.context.principle.objectId;
        return this._changePasswordApi.invoke({payload: data});
    }

    public signOut() {
        this.context.clear();
    }

    public requestSecurityContext(): Observable<AuthenticationContext> {
        if (!this._requestPrincipleApi)
            return Observable.of(this.context);
        let contextSubject = new Subject<AuthenticationContext>();
        this.internalRequestSecurityContext(contextSubject);
        return contextSubject;
    }

    private internalRequestSecurityContext(contextSubject: Subject<AuthenticationContext>) {
        this._requestPrincipleApi.invoke({args: this.context.request}).subscribe({
            next: context => {
                this.context.claims = context.claims.map<Claim>(x => { 
                    return {type: x.type, value: x.value}
                 });
                this.context.principle = context.user;
                this.context.updateContext();
                contextSubject.next(this.context);
            },
            error: err => contextSubject.error(err),
            complete: () => contextSubject.complete()
        });
    }

    public authorize(request: AuthorizeRequest, element?: AuthorizableResource): boolean {
        if (!request)
            return true;

        if (typeof request === 'string')
            return this.checkAuthorization(parseClaims(request), element);
        if (request instanceof ClaimRequest) 
            return this.checkAuthorization([request], element);
        if (TypeUtils.isArrayOfType(request, 'ClaimRequest'))
            return this.checkAuthorization(request as ClaimRequest[], element);
        if (TypeUtils.isArrayOfType(request, 'string')) {
            let stringRequests = request as string[];
            return stringRequests.some(r => this.checkAuthorization(parseClaims(r)), element);
        }
        let item = request as Authorizable;
        if (!item)
            return true;
        return this.authorize(item.request, element);
    }

    private checkAuthorization(requests: ClaimRequest[], element?: AuthorizableResource): boolean {
        return !requests.some(request => !this.hasClaim(request));
    }

    private hasClaim(request: ClaimRequest): boolean {
        if (!this.context.authenticated || !this.context.principle || !this.context.claims) 
            return false;

        let claims = this.context.claims;
        let requestName = (request.name || '').toLowerCase();
        let negative = requestName.startsWith("!");
        if (negative)
            requestName = requestName.substr(1);

        // let claim = claims.find(claim => claim.type.toLowerCase() == requestName)
        // if (!claim) 
        //     return negative;
        // let result = `,${claim.value.toLowerCase()},`.indexOf(`,${request.value.toLowerCase()},`) >= 0;
        // if (negative)
        //     result = !result
        // return result 
        let claim = claims.filter(claim => claim.type.toLowerCase() == requestName)
        if (!claim || claim.length <= 0) 
            return negative;
        let result = false;
        claim.forEach(element => {
            let tmp = `,${element.value.toLowerCase()},`.indexOf(`,${request.value.toLowerCase()},`) >= 0;
            result = result || tmp;
        });
        if (negative)
            result = !result
        return result 

    }
}

interface SecurityContext {
    claims: Claim[],
    user: IPrinciple;
}