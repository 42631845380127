import { Component } from "@angular/core";
import { column_renderer } from "../../object-explorer";
import { AbstractColumnRenderer } from "../../object-explorer/models/column-renderer";

@Component({
    selector: 'object-diary-event',
    template: require('./object-diary-event.renderer.pug')
})
@column_renderer('ObjectDiaryEventRenderer')
export class ObjectDiaryEventRenderer extends AbstractColumnRenderer {
    getValue(value: any): any {
        if (value === null || value === undefined)
            return '<no value>';
        return value;
    }
}

