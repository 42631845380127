import { NgModule, Provider } from "@angular/core";
import { HttpModule } from "@angular/http";
import { ApiService } from "./api/api.service";
import { AuthorizePipe, AuthenticatorService, DefaultAccessControlService, 
        AccessControlService, PasswordAuthenticatorService, AuthenticationContext } from "./access-control";
import { ApiAdvisorProvider } from "./api/api-advisor.provider";
import { DataSourceProvider } from "./data-source/data-source-provider.service";
import { ActionProvider } from "./action/action-provider.service";
import { GlobalRegistryService } from "./registry/global-registry.service";
import { ComponentHostDirective } from "./component-host";
import { RemoteCsvLoaderService } from "./remote-data/remote-csv-loader.service";
import { IntegerValueValidator } from "./validator/int-value-validator";
import { IconProvider } from "./registry/icon-provider.service";
import { MessageService, MessageServiceProvider } from "./message/message.service";
import { StringSplitPipe } from "./pipe/string-split.pipe";

const CORE_PROVIDERS: Provider[] = [
    { provide: ApiService, useClass: ApiService },
    { provide: ApiAdvisorProvider, useClass: ApiAdvisorProvider },
    { provide: DataSourceProvider, useClass: DataSourceProvider },
    { provide: ActionProvider, useClass: ActionProvider},
    { provide: GlobalRegistryService, useValue: GlobalRegistryService.instance },
    { provide: RemoteCsvLoaderService, useClass: RemoteCsvLoaderService },
    { provide: AuthenticatorService, useClass: PasswordAuthenticatorService },
    { provide: AccessControlService, useClass: DefaultAccessControlService },
    { provide: AuthenticationContext, useClass: AuthenticationContext },
    { provide: IconProvider, useClass: IconProvider },
    { provide: MessageService, useClass: MessageServiceProvider }
]

const CORE_DIRECTIVES: any[] = [
    AuthorizePipe,
    StringSplitPipe,
    IntegerValueValidator,
    ComponentHostDirective
]
 
@NgModule({
    imports: [ HttpModule ],
    exports: [ ...CORE_DIRECTIVES ],
    declarations: [ ...CORE_DIRECTIVES ],
    providers: [
        ...CORE_PROVIDERS
    ]
})
export class CoreModule {

}

