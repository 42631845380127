import { ObjectExploringDescriptor } from "../models/object-exploring-descriptor";
import { Type } from "@angular/core";
import { StringUtil } from "../../core";

export abstract class AbstractDescriptorProvider<T> {
    
    private _descriptors: any = {}

    public clear() {
        this._descriptors = {};
    }

    public add(data: T[] | any) {
        if (Array.isArray(data)) {
            for(var item of <ObjectExploringDescriptor[]>data) {
                this.internalAdd(new this.itemCtor(item));
            }
            return;
        }
        for (var name in data) 
            this.internalAdd(new this.itemCtor(data[name], name));
    }

    protected abstract get itemCtor(): Type<T>;

    protected internalAdd(data: any) {
        let descriptor = data;
        if (StringUtil.isBlank(data.name))
            throw new Error(`Name is missing in data: ${data}`)
        this._descriptors[(<string>descriptor.name).toLowerCase()] = descriptor;
    }

    public getDescriptor(name: string): T {
        return this._descriptors[name.toLowerCase()];
    }
}