import { Directive, ViewContainerRef, ElementRef, Input, OnInit, ChangeDetectorRef, HostBinding, AfterViewInit } from "@angular/core";
import { Subject } from "rxjs";
import { NgSelectComponent } from "../ng-select.component";
import { DataSource, DataSourceProvider, CRUD, DataContext } from "../../core";
import { QueryOption, DefaultFieldSet, RangeOperator, BinaryOperator } from "../../core/data-source/query-option";


@Directive({
    selector: `ng-select[ngDataSource]`,
    exportAs: 'ngDataSource'
})
export class NgDataSourceDirective implements OnInit, AfterViewInit {
    private _typeaheadInput$ = new Subject<string>();

    private static _emptyData: any[] = [];

    @Input('ngDataSource') get dataSource(): DataSource<any> | string {
        return this._dataSource;
    }
    set dataSource(value: DataSource<any> | string ) {
        if (value === this._dataSource)
            return;
        if (typeof value === 'string') {
            let ds = this.dataSourceProvider.createDataSource(value);
            if (!ds)
                console.log('Data source id not found:', value)
            this._dataSource = ds;
        }
        else {
            this._dataSource = value;
        }
    }
    private _dataSource:  DataSource<any>;

    @Input('ngDataContext') get dataContext(): DataContext<any> { return this._dataContext; }
    set dataContext(value: DataContext<any>) {
        if (value === this._dataContext)
            return;
        this._dataContext = value;
        this.dataSource = value.dataSource;
    }
    private _dataContext: DataContext<any>;

    /** Bind to object instead of Id */
    @Input('bindObject') bindObject: boolean = false;
    @Input('lazyLoad') lazyLoad: boolean = false;
    @Input('tracking') tracking: boolean = true;
    @Input() filterField: string;
    @Input() filterOperation: BinaryOperator = '=';
    @Input() get filterValue(): any { return this._filterValue; }
    set filterValue(value: any) { 
        this._filterValue = value;
        this.loadData();
    }
    private _filterValue: any;

    get objectType(): string { return this._objectType; }
    @Input() set objectType(value: string) {
        this._objectType = value;
        this.loadData();
    }
    private _objectType: string;

    constructor(private element: NgSelectComponent,
                private changeRef: ChangeDetectorRef,
                private elementRef: ElementRef,
                private dataSourceProvider: DataSourceProvider) {
    }

    ngOnInit(): void {
        if (!this.elementRef.nativeElement.attributes.getNamedItem('bindLabel'))
            this.element.bindLabel = 'name';
        if (!this.bindObject && !this.element.bindValue)
            this.element.bindValue = 'objectId';
        if (this.tracking && !this.element.multiple && this.dataContext)
            this.element.changeEvent.subscribe(
                (selected: any) => {
                    this.dataContext.active = selected 
                }
            )
        if (this.lazyLoad) {
            this.element.lazyLoadEvent.subscribe(
                (value: any) => this.performLazyLoadData(value)
            );
            this.element.typeahead = this._typeaheadInput$;
            this._typeaheadInput$.subscribe({
                next: x => this.incrementalSearch(x)
            });
        }
        if (!this._dataSource) 
            return;

        if (!this._dataSource.ready)
            this.loadData();
        else
            this.updateItems();
    }

    ngAfterViewInit(): void {
        
    }

    private updateItems() {
        this.element.loading = false;
        if (!this._dataSource) 
            return;
        let data = this._dataSource.data || NgDataSourceDirective._emptyData;
        if (data !== this.element.items) {
            this.element._setItems(data);
            this.changeRef.detectChanges();
        }
    }

    private handleError(err: any) {
        this.element.loading = false;
    }

    private fetchData(option: QueryOption) {
        if (!this._dataSource)
            return;
        this.element.loading = true;
        if (this.objectType)
            option.properties = { objectType: this.objectType };
        this._dataSource.fetchData(CRUD.List, null, option)
            .subscribe({
                next: _ => this.updateItems(),
                error: err => this.handleError(err),
                complete: () => this.element.loading = false
            })
    }

    private loadData(): void {        
        if (this.lazyLoad) 
            return;
        let option: QueryOption = {
            fieldSet: DefaultFieldSet.LOOKUP
        }
        if (this.filterField)
            option.searchFields = [{
                name: this.filterField, operator: this.filterOperation, value: this.filterValue
            }];
        this.fetchData(option);
    }

    private performLazyLoadData(value: any) {
        if (!this._dataSource || typeof value !== 'string')
            return;
        
        let option: QueryOption = {
            fieldSet: DefaultFieldSet.LOOKUP,
            searchFields: [{
                operator: '=', 
                name: this.element.bindValue || 'objectId',
                value: value    
            }],
            properties: {
                lazyLoad: true
            }
        };        
        this.fetchData(option);
    }

    private incrementalSearch(value: any) {
        if (!this._dataSource || typeof value !== 'string') 
            return;
        let option: QueryOption = {
            fieldSet: DefaultFieldSet.LOOKUP,
            searchFields: [{ name: '@quicksearch', value: value }]
        }
        this.fetchData(option);
    }
}