import {
    AbstractObjectExplorerHost,
    ObjectExploringDescriptorProvider,
    DataSourceProvider,
    ActionProvider,
    AccessControlService,
    CRUD,
    MessageService,
    Message,
    MessageSubscription
} from "../../../../../jupiter/components";
import { widget } from "./widget-decorator";
import { Component, OnDestroy } from "@angular/core";
import { WidgetContent } from "./abstract-widget-content";
import { WidgetContainerComponent, WidgetData } from "../widget.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "table-widget",
    template: `
        <ng-container object-viewer-part="MaterialOvpListViewerComponent" [host]="this" [options]="options">
        </ng-container>
    `
})
@widget("TableWidget")
export class TableWidget extends AbstractObjectExplorerHost implements WidgetContent, OnDestroy {
    get widgetContainer(): WidgetContainerComponent {
        return this._widgetContainer;
    }
    set widgetContainer(value: WidgetContainerComponent) {
        if (value === this._widgetContainer) return;
        this._widgetContainer = value;
        let w = this.widgetData;
        if (w && w.tableManifest) this.updateManifest(w.tableManifest);
    }
    private _widgetContainer: WidgetContainerComponent;

    get widgetData(): WidgetData {
        if (!this.widgetContainer || !this.widgetContainer.widget) return undefined;
        return this.widgetContainer.widget.data;
    }

    private widgetSubscription: MessageSubscription;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected browsingDescriptor: ObjectExploringDescriptorProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(
            activatedRoute,
            browsingDescriptor,
            dataSourceProvider,
            actionProvider,
            accessControl,
            messageService,
            router
        );
        this.widgetSubscription = this.messageService.subsribe("widget", msg => this.handleWidgetMessage(msg));
    }

    loadData() {
        this.context.dataSource.fetchData(CRUD.List, {
            dataset: this.widgetContainer.widget.dataSet,
            period: this.widgetContainer.widget.data.period,
            territory: this.widgetContainer.option.territory
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.widgetSubscription.unsubscribe();
    }

    protected handleWidgetMessage(msg: Message) {
        switch (msg.topic) {
            case "refresh":
                this.loadData();
                break;
        }
    }
}
