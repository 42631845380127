import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, Provider } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreModule } from "../core";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpModule } from "@angular/http";
import {
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule
} from "@angular/material";
import { ReportViewerComponent } from "./report-viewer/report-viewer.component";
import { ObjectViewerModule } from "../object-explorer";
import { REPORT_DATASOURCE, ReportProvider } from "./report-viewer/report";
import { MaterialComponentModule } from "../material-components";
import { NgSelectModule } from "../ng-select";

const FORM_COMPONENTS: any[] = [ReportViewerComponent];

const FORM_PROVIDERS: Provider[] = [
    { provide: REPORT_DATASOURCE, useValue: "report" },
    { provide: ReportProvider, useClass: ReportProvider }
];

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        ObjectViewerModule,
        MaterialComponentModule,
        NgSelectModule,
        FormsModule,
        HttpModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatTabsModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule
    ],
    exports: [...FORM_COMPONENTS],
    declarations: [...FORM_COMPONENTS],
    providers: [...FORM_PROVIDERS],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CommonPagesModule {}
