module.exports = {
	"advisors": {
		"/esale/activity": {
			"type": "CRUD"
		},
		"/esale/opportunity": {
			"type": "CRUD",
			"actions": {
				"clone": {
					"method": "post",
					"url": "clone?opportunityId={args.objectId}",
					"secure": true
				}
			}
		},
		"/esale/organization": {
			"type": "CRUD"
		},
		"/esale/person": {
			"type": "CRUD"
		},
		"/esale/organizationtype": {
			"type": "CRUD"
		},
		"/esale/organizationstatus": {
			"type": "CRUD"
		},
		"/esale/activitytype": {
			"type": "CRUD"
		},
		"/esale/activitystatus": {
			"type": "CRUD"
		},
		"/esale/territory": {
			"type": "CRUD"
		},
		"/esale/country": {
			"type": "CRUD"
		},
		"/esale/province": {
			"type": "CRUD"
		},
		"/esale/saltuation": {
			"type": "CRUD"
		},
		"/esale/influencelevel": {
			"type": "CRUD"
		},
		"/esale/leadsource": {
			"type": "CRUD"
		},
		"/esale/leadtime": {
			"type": "CRUD"
		},
		"/esale/contactmethod": {
			"type": "CRUD"
		},
		"/esale/currency": {
			"type": "CRUD"
		},
		"/esale/businessline": {
			"type": "CRUD"
		},
		"/esale/supplyscope": {
			"type": "CRUD"
		},
		"/esale/segment": {
			"type": "CRUD"
		},
		"/esale/opportunitystage": {
			"type": "CRUD"
		},
		"/esale/opportunityworkflowstage": {
			"type": "CRUD"
		},
		"/esale/projectstage": {
			"type": "CRUD"
		},
		"/esale/incoterm": {
			"type": "CRUD"
		},
		"/esale/projectclosing": {
			"type": "CRUD"
		},
		"/esale/winreason": {
			"type": "CRUD"
		},
		"/esale/lostreason": {
			"type": "CRUD"
		},
		"/esale/priority": {
			"type": "CRUD"
		},
		"/equotation/quotation": {
			"type": "CRUD"
		},
		"/esale/project": {
			"type": "CRUD"
		},
		"/esale/dashboard": {
			"actions": {
				"list": {
					"url": "list?dataset={args.dataset}&period={args.period}&territory={args.territory}",
					"secure": true
				}
			}
		},
		"/accesscontrol/account": {
			"type": "CRUD",
			"actions": {
				"resetPassword": {
					"method": "put",
					"url": "resetPassword?userId={args.userId}&userName={args.userName}",
					"secure": true
				}
			}
		},
		"/accesscontrol/role": {
			"type": "CRUD"
		},
		"/accesscontrol/claim": {
			"type": "CRUD"
		},
		"/accesscontrol/assignedclaim": {
			"type": "CRUD"
		},
		"/accesscontrol/authentication": {
			"actions": {
				"getPrinciple": {
					"url": "getuserclaims?userName={args.username}",
					"secure": true
				},
				"changePassword": {
					"method": "put",
					"secure": true
				}
			}
		},
		"/basecontent/object": {
			"actions": {
				"changeOwner": {
					"method": "put",
					"secure": true,
					"url": "changeOwner?fromUser={args.fromUserId}&toUser={args.toUserId}"
				}
			}
		},
		"/basecontent/objectdiary": {
			"actions": {
				"count": {
					"method": "put",
					"secure": true
				},
				"list": {
					"method": "put",
					"secure": true
				}
			}
		},
		"/basecontent/attachment": {
			"type": "CRUD",
			"actions": {
				"getAttachments": {
					"url": "getattachments?objectId={args.id}",
					"secure": true
				},
				"attach": {
					"url": "attach?objectId={args.id}&name={args.id}",
					"method": "put"
				},
				"getAttachment": {
					"url": "getattachment?id={args.id}&name={args.name}"
				}
			}
		},
		"/reportengine/report": {
			"actions": {
				"getReports": {
					"url": "getreports?module=esale",
					"secure": true
				},
				"fetchData": {
					"method": "put",
					"url": "renderreport?name={args.name}&renderer=simple",
					"secure": true
				},
				"renderReport": {
					"method": "put",
					"url": "render?name={args.name}",
					"secure": true
				}
			}
		},
		"/equotation/validationrule": {
			"type": "CRUD"
		},
		"/equotation/skupart": {
			"type": "CRUD"
		},
		"/equotation/brand": {
			"type": "CRUD"
		},
		"/equotation/term": {
			"type": "CRUD"
		},
		"/equotation/attribute": {
			"type": "CRUD"
		},
		"/equotation/unit": {
			"type": "CRUD"
		},
		"/equotation/productcategory": {
			"type": "CRUD"
		},
		"/equotation/branch": {
			"type": "CRUD"
		},
		"/equotation/qproduct": {
			"type": "CRUD"
		},
		"/equotation/part": {
			"type": "CRUD"
		},
		"/equotation/product": {
			"type": "CRUD"
		},
		"/equotation/genericpart": {
			"type": "CRUD"
		},
		"/equotation/compositepart": {
			"type": "CRUD"
		}
	},
	"accessControl": {
		"autoLogin": {
			"enabled": false,
			"username": "admin",
			"password": "admin"
		},
		"tokenServer": "default",
		"authenticationApi": "/accesscontrol/authentication"
	},
	"servers": {
		"default.production": "http://api.esale.hainamswitchboards.com"
	}
};