import { isNullOrUndefined } from "util";
import { isArray } from "rxjs/internal/util/isArray";
import { ObjectFeature } from "./object-feature";

/**
 * Provide meta information about an object.
 */
export class ObjectDescriptor {
    
    private _name: string;
    private _type: string;
    private _features: ObjectFeature[] = [];

    public actions: any;

    public get name(): string { return this._name; }
    public get type(): string { return this._type; }
    public get features(): ObjectFeature[] { return this._features; }

    constructor(data?: ObjectDescriptor, name?: string){
        if (data) {
            this._name = data.name || name;
            this._type = data.type;
            this.actions = data.actions;
            
            if (isNullOrUndefined(data.features))
                return;
            if (isArray(data.features))
                this.createFeaturesFromArray(data.features);
            else
                this.createFeaturesFromObject(data.features);
        }
        this._name = this._name || name;        
    }

    private createFeaturesFromArray(features: any[]) {
        for (const feature of features) {
            this._features.push(new ObjectFeature(feature));
        }
    }

    private createFeaturesFromObject(features: any) {
        for (const featureName in features) {
            this._features.push(new ObjectFeature(features[featureName], featureName));
        }
    }
}



