import { ObjectViewerPart, ObjectViewerHost, ObjectViewerDescriptor } from "../models";
import { Input, OnInit } from "@angular/core";
import { DataContext, DataSource, Action } from "../../core";
import { AbstractMessageHandler } from "../../core/message/message-handler";

export class AbstractViewerPartComponent<TManifest extends ObjectViewerDescriptor>
            extends AbstractMessageHandler
            implements ObjectViewerPart<TManifest>, OnInit {

    @Input() host: ObjectViewerHost<TManifest>;
    @Input() options: any;

    public get manifest(): TManifest { return this.host ?  <any> this.host.manifest : undefined; }
    public get dataContext(): DataContext<any> { return this.host ? this.host.context : undefined; }
    public get dataSource(): DataSource<any> { 
        return this.dataContext !== undefined ? this.dataContext.dataSource : undefined; 
    }

    ngOnInit() {
        // do nothing
    }

    protected getActions(name: string): Action[] {
        if (!this.host)
            return undefined;
        return this.host.getActions(name);
    }
}