import { Component, forwardRef, Input, Optional, Inject } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { AbstractFormField } from "../abstract-form-field";

@Component({
    selector: 'input-field',
    template: require('./input-field.component.pug'),
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputFieldComponent),
        multi: true
    }
]
})
export class InputFieldComponent extends AbstractFormField {
    @Input()
    public type: string = 'text';

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
        super(validators, asyncValidators);
     }
}