import {
    AccessControlService,
    ApiAdvisorProvider,
    ApiAdvisor,
    DataSourceProvider,
    DataSource
} from "../../../../jupiter/components";
import { ActivatedRoute, Router } from "@angular/router";
import { Component } from "@angular/core";
import { Location } from "@angular/common";

@Component({
    selector: "change-owner",
    template: require("./change-owner.component.pug")
})
export class ChangeOwnerComponent {
    fromUser: string;
    toUser: string;
    returnAddress: string;
    acknowledge: boolean;

    userDataSource: DataSource<any>;
    objectApi: ApiAdvisor;
    working: boolean = false;

    get isSubmittable(): boolean {
        return !this.working && this.acknowledge && this.fromUser && this.toUser && this.fromUser !== this.toUser;
    }

    get loginName(): string {
        return this.auth.context.principle.caption;
    }

    constructor(
        private auth: AccessControlService,
        private apiAdvisorProvider: ApiAdvisorProvider,
        private dataSourceProvider: DataSourceProvider,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private router: Router
    ) {
        this.activatedRoute.queryParams.subscribe({
            next: params => (this.returnAddress = params["return"] || "/")
        });
        this.objectApi = this.apiAdvisorProvider.get("/basecontent/object");
        this.userDataSource = this.dataSourceProvider.createDataSource("appUser");
        this.userDataSource.list({ fieldSet: "lookup" });
    }

    changeOwner(): void {
        this.working = true;
        this.objectApi
            .getApi("changeOwner", true)
            .invoke({ args: { fromUserId: this.fromUser, toUserId: this.toUser } })
            .subscribe({
                next: (_: any) => this.changedSuccessfully(),
                error: err => {
                    this.working = false;
                    window.alert("Unable to change ownership !");
                },
                complete: () => (this.working = false)
            });
    }

    changedSuccessfully(): void {
        this.working = false;
        window.alert("Ownership has been changed!");
        if (this.returnAddress) this.router.navigateByUrl(this.returnAddress);
        else this.location.back();
    }
}
