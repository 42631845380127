import {
    property_page,
    AbstractPropertyPage,
    getOrCreateProperty,
    DataSourceProvider,
    DataSource
} from "../../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "object-history-property-page",
    template: `
        <ng-container *ngIf="editingItem">
            <object-explorer-host manifest="objectDiary" [dataSource]="diary"> </object-explorer-host>
        </ng-container>
    `
})
@property_page("ObjectHistoryPage")
export class ObjectHistoryPage extends AbstractPropertyPage<any> {
    diary: DataSource<any>;

    constructor(private dataSourceProvider: DataSourceProvider) {
        super();
        this.diary = dataSourceProvider.createDataSource("objectDiary");
        this.diary.option.updatePage(1, 10);
    }

    private updateParentObjectId() {
        if (!this.editingItem) return;
        this.diary.option.properties = { parentObjectId: this.editingItem.objectId };
        this.updateData();
    }
    protected onEditingItemChanged(): void {
        super.onEditingItemChanged();
        this.updateParentObjectId();
    }

    private updateData() {
        this.diary.list();
    }
}
