import { Component, forwardRef, Input, Optional, Inject } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { AbstractFormField } from "../abstract-form-field";

@Component({
    selector: 'chip-option-edit',
    template: `
    <mat-form-field>
        <mat-label *ngIf='label'> {{label}} </mat-label>
        <mat-chip-list [multiple]='true' #domainChip>
            <mat-chip *ngFor='let value of domain'
                        [selected] = 'isSelected(value)'
                        [color] = 'isSelected(value) ? "primary" : null'
                        (click) = 'toggle(value)' > {{value}}
            </mat-chip>
            <input readonly [matChipInputFor]='domainChip'>
        </mat-chip-list>
    </mat-form-field>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ChipOptionEditComponent),
        multi: true
    }
]
})
export class ChipOptionEditComponent extends AbstractFormField {

    private _lastAssignedValue: string;
    private _assignedValues: string[] = [];


    @Input() domain: string[] = [];

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
        super(validators, asyncValidators);
     }

    get assignedValues(): string[] {
        if (this._lastAssignedValue === this.value)
            return this._assignedValues;
        this._assignedValues = this.parseValue(this.value);
        this._lastAssignedValue = this.value;
        return this._assignedValues;
    }

    private toggle(value: any) {
        if (this.isSelected(value))
            this.removeValue(value);
        else
            this.addValue(value);
    }

    private parseValue(text: string): string[] {
        if (!text)
            return [];
        return text.split(',');
    }

    private isSelected(text: string): boolean {
        return this.assignedValues.indexOf(text) >= 0;
    }

    private addValue(text: string) {
        if (this.assignedValues.indexOf(text) >= 0)
            return;
        this._assignedValues.push(text);
        this.updateAssignedValues();
    }

    private removeValue(text: string) {
        let idx = this.assignedValues.indexOf(text);
        if (idx < 0)
            return;
        this._assignedValues.splice(idx, 1)
        this.updateAssignedValues();
    }

    private updateAssignedValues() {
        this._lastAssignedValue =  this._assignedValues.join(',');
        this.value = this._lastAssignedValue;
    }
}