import { Component, forwardRef, Input, Optional, Inject, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { AbstractFormField } from "../abstract-form-field";

@Component({
    selector: 'date-field',
    template: `
    <mat-form-field [class.mat-form-field-invalid] = 'invalid | async'>
        <mat-label *ngIf='label'> {{label}} </mat-label>
        <input class='date-edit' matInput [disabled]='disabled' [matDatepicker]='datePicker' [(ngModel)]='value'>
        // <input *ngIf='timeEdit' class='time-edit' type='time' [disabled]='disabled' [(ngModel)]='value'>
        <mat-datepicker-toggle matSuffix [for]='datePicker'></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
        <span matPrefix> 
            <ng-content select='[prefix]' ></ng-content>
        </span>
        <span matSuffix> 
            <ng-content select='[suffix]' ></ng-content>
        </span>
        <mat-hint> 
            <ng-content select='hint' ></ng-content>
        </mat-hint>
    </mat-form-field>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateFieldComponent),
        multi: true
    }
]
})
export class DateFieldComponent extends AbstractFormField {

    @Input() timeEdit: boolean;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
        super(validators, asyncValidators);
     }
}