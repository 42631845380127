import { property_page, AbstractPropertyPage } from "../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "quotation-property-page",
    template: require("./quotation.proppage.pug")
})
@property_page("QuotationPropertyPage")
export class QuotationPropertyPage extends AbstractPropertyPage<any> {
    ngOnInit() {
        super.ngOnInit();
        let parentId = this.host.routeSnapshot.params["parentId"];
        if (parentId) this.editingItem.parentId = parentId;
    }
    
}
