import { AbstractWidgetContent } from "./abstract-widget-content";
import { Component } from "@angular/core";
import { DataSourceProvider, MessageService } from "../../../../../jupiter/components";
import { WidgetContainerComponent, WidgetData } from "../widget.component";
import { widget } from "./widget-decorator";
import { message } from "../../../../../jupiter/components/material-form-fields/validate";

@Component({
    selector: "chart-widget",
    template: `
        <div *ngIf="chartData" [ngSwitch]="chartType">
            <ngx-charts-pie-chart
                *ngSwitchCase="'pie'"
                [view]="[350, 300]"
                [labels]="true"
                [gradient]="true"
                [results]="chartData"
            >
            </ngx-charts-pie-chart>
            <ngx-charts-gauge *ngSwitchCase="'gauge'" units="%" [view]="[400, 300]" [results]="chartData">
            </ngx-charts-gauge>
            <ngx-charts-bar-horizontal
                *ngSwitchCase="'bar-horizontal'"
                [view]="[350, 300]"
                [yAxis]="true"
                [barPadding]="1"
                [showDataLabel]="true"
                [results]="chartData"
            >
            </ngx-charts-bar-horizontal>
            <ngx-charts-bar-vertical-2d
                *ngSwitchCase="'grouped-bar-vertical'"
                [view]="[350, 300]"
                [yAxis]="true"
                [showDataLabel]="false"
                [results]="chartData"
            >
            </ngx-charts-bar-vertical-2d>
            <ngx-charts-bar-horizontal-2d
                *ngSwitchCase="'grouped-bar-horizontal'"
                [view]="[350, 300]"
                [yAxis]="true"
                [xAxis]="true"
                [showDataLabel]="false"
                [legend]="false"
                [results]="chartData"
            >
            </ngx-charts-bar-horizontal-2d>
            <h2 *ngSwitchDefault>
                Chart type <b>{{ chartType }}</b> is not supported
            </h2>
            <div></div>
        </div>
    `
})
@widget("ChartWidget")
export class ChartWidget extends AbstractWidgetContent {
    chartData: any[];

    constructor(protected dataSourceProvider: DataSourceProvider, protected messageService: MessageService) {
        super(dataSourceProvider, messageService);
    }

    get widgetData(): WidgetData {
        if (!this.widgetContainer || !this.widgetContainer.widget) return undefined;
        return this.widgetContainer.widget.data;
    }

    get chartType(): string {
        if (!this.widgetData) return undefined;
        return this.widgetData.chartType;
    }

    protected updateContents(): void {
        this.chartData = this.processChartData(this.dataSource.data);
    }

    protected processChartData(input: any) {
        switch (this.chartType) {
            case "grouped-bar-vertical":
            case "grouped-bar-horizontal":
                return this.generateGroupData(input);
            default:
                return input;
        }
    }

    private generateGroupData(input: any[]) {
        var result: any[] = [];
        var lastGroup: any = {};

        input.forEach(item => {
            let groupby = item.groupby;
            if (groupby !== lastGroup.name) {
                lastGroup = { name: groupby, series: [] };
                result.push(lastGroup);
            }
            lastGroup.series.push({ name: item.name, value: item.value });
        });
        console.log("chart-data:", result);
        return result;
    }
}
