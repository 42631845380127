import { AppUserPropertyPage } from "./common-pages/app-user.proppage";
import { AppRolePropertyPage } from "./common-pages/app-role.proppage";
import { ClaimPropertyPage } from "./common-pages/claim.proppage";
import { AttachmentDetailPage } from "./common-pages/attachment-detail.proppage";
import { AttachmentPropertyPage } from "./common-pages/attachment.proppage";
import { ObjectPermissionPage } from "./common-pages/object-permission.proppage";
import { AssignedClaimPropertyPage } from "./common-pages/assigned-claim.proppage";
import { ObjectHistoryPage } from "./common-pages/object-history.proppage";
import { DefaultDirectoryPropertyPage } from "./common-pages/default-directory.proppage";
import { AccountAssignedClaimPropertyPage } from "./common-pages/account-assigned-claim.proppage";
import { SubPropertyPage } from "./common-pages/sub-property-page.proppage";

import { OrganizationPropertyPage } from "./organization.proppage";
import { PersonPropertyPage } from "./person.proppage";
import { OrganizationPersonPropertyPage } from "./org-person.proppage";
import { OpportunityPropertyPage } from "./opportunity.proppage";
import { ActivityPropertyPage } from "./activity.proppage";
import { ProvincePropertyPage } from "./province.proppage";
import { OpportunityStagePropertyPage } from "./opportunity-stage.proppage";
import { OpportunityWinLostColumnRenderer } from "./column-renderer/win-lost-column.renderer";
import { ActivityTypePropertyPage } from "./activity-type.proppage";
import { CompactOpportunityValueColumnRenderer } from "./column-renderer/compact-opp-value.renderer";
import { AttachmentPreviewComponent } from "../comps/attachment-viewImage";
import { QuotationPropertyPage } from "./quotation.proppage";
import { ProjectPropertyPage } from "./project.proppage";
import { OppQuotationPropertyPage } from "./opp-quotation.proppage";
export const PROPERTY_PAGE_COMPONENTS: any[] = [
    OrganizationPropertyPage,
    PersonPropertyPage,
    ObjectPermissionPage,
    ObjectHistoryPage,
    ActivityTypePropertyPage,
    AppUserPropertyPage,
    AppRolePropertyPage,
    OrganizationPersonPropertyPage,
    OpportunityPropertyPage,
    ActivityPropertyPage,
    ProvincePropertyPage,
    OpportunityStagePropertyPage,
    ClaimPropertyPage,
    AccountAssignedClaimPropertyPage,
    AssignedClaimPropertyPage,
    SubPropertyPage,
    DefaultDirectoryPropertyPage,
    OpportunityWinLostColumnRenderer,
    CompactOpportunityValueColumnRenderer,
    AttachmentDetailPage,
    AttachmentPropertyPage,
    AttachmentPreviewComponent,
    QuotationPropertyPage,
    ProjectPropertyPage,
    OppQuotationPropertyPage
];
