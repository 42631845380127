import { Injectable } from "@angular/core";
import { MessageSubscription, MessageHandler, Message, 
         DefaultMessageSubscription, MessageFilter } from "./message";
import { Subject } from "rxjs";

@Injectable()
export abstract class MessageService {
    public abstract subsribe(channel: string, handler: MessageHandler, receiver?: any): MessageSubscription;
    public abstract send(message: Message): Message;
}

export class MessageServiceProvider extends MessageService {
    
    private channels: Map<string, Subject<any>> = new Map();

    public subsribe(topic: string, handler: MessageHandler, sender?: any, receiver?: any): MessageSubscription {
        let channel = this.getChannel(topic);
        if (!channel)
            return null;
        let filter: MessageFilter = sender ? { sender: sender } : undefined;
        let subscription = new DefaultMessageSubscription(topic, handler, receiver, filter);
        subscription.subscription = channel.subscribe({
            next: msg => subscription.handle(msg)
        })
        return subscription;
    }    
    
    public send(message: Message): Message {
        if (!message || !message.channel || !this.hasChannel(message.channel))
            return;
        this.getChannel(message.channel).next(message);
        return message;
    }

    private hasChannel(topic: string): boolean {
        if (!topic) return false;
        return this.channels.has(topic);
    }

    private getChannel(topic: string): Subject<any> {
        if (!topic)
            return null;
        let channel = this.channels.get(topic.toLowerCase());
        if (!channel) {
            channel = new Subject<any>();
            this.channels.set(topic.toLowerCase(), channel);
        }
        return channel;
    }

}