export namespace DisplayMode {
    export const MAX_COMPACT_MODE_WIDTH: number = 800;

    export function isCompactMode(): boolean {
        return window.innerWidth <= MAX_COMPACT_MODE_WIDTH;
    }

    export function isFullMode(): boolean {
        return window.innerWidth > MAX_COMPACT_MODE_WIDTH;
    }

    export function getMode(): "compact" | "full" {
        return isCompactMode() ? "compact" : "full";
    }

    export function isVisible(mode: "compact" | "full" | "both", expectedMode?: "compact" | "full") {
        return mode === "both" || mode === (expectedMode != undefined ? expectedMode : getMode());
    }
}
