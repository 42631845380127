import {
    property_page,
    AbstractPropertyPage,
    getOrCreateProperty,
    DataSourceProvider,
    DataSource,
    AccessControlService,
    ActionExecutingHandler,
    ActionHandlingContext,
    correctDateTimeZone,
    ActionMessageChannel,
    MessageService
} from "../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "activity-property-page",
    template: require("./activity.proppage.pug")
})
@property_page("ActivityPropertyPage")
export class ActivityPropertyPage extends AbstractPropertyPage<any> implements ActionExecutingHandler {
    private personDataSource: DataSource<any>;
    private _remindTime: Date;
    private _dueTime: Date;
    private _endTime: Date;
    private initialized: boolean = false;

    get remindTime(): any {
        if (!this._remindTime && this.activity.remindDate) {
            this._remindTime = new Date(this.activity.remindDate);
        }
        return this._remindTime;
    }

    set remindTime(value: any) {
        if (typeof value === "string") value = new Date(`2000-01-01 ${value}`);
        this._remindTime = value;
    }

    get dueTime(): any {
        if (!this._dueTime && this.activity.dueDate) this._dueTime = new Date(this.activity.dueDate);
        return this._dueTime;
    }

    set dueTime(value: any) {
        if (typeof value === "string") value = new Date(`2000-01-01 ${value}`);
        this._dueTime = value;
    }

    get endTime(): any {
        if (!this._endTime && this.activity.dueDate) this._endTime = new Date(this.activity.endDate);
        return this._endTime;
    }

    set endTime(value: any) {
        if (typeof value === "string") value = new Date(`2000-01-01 ${value}`);
        this._endTime = value;
    }

    get activity(): any {
        return getOrCreateProperty(this.editingItem, "properties.activity");
    }

    get externalParticipants(): any[] {
        return getOrCreateProperty(this.editingItem, "properties.externalParticipants", []);
    }

    constructor(
        private dataSourceProvider: DataSourceProvider,
        private auth: AccessControlService,
        private messageSerivce: MessageService
    ) {
        super();
        this.personDataSource = this.dataSourceProvider.createDataSource("person");
        ActionMessageChannel.subscribeActionMessageHandler(messageSerivce, this).setFilter({
            topic: ActionMessageChannel.PRE_EXECUTE_TOPIC
        });
    }

    protected onHostChanged(): void {
        getOrCreateProperty(this.editingItem, "properties.internalParticipants", []);
        getOrCreateProperty(this.editingItem, "properties.externalParticipants", []);
        getOrCreateProperty(this.editingItem, "properties.dependantActivities", []);
        getOrCreateProperty(this.editingItem, "properties.dependeeActivities", []);
    }

    ngOnInit() {
        super.ngOnInit();
        if (!this.isNewItem) {
            this.initialized = true;
            return;
        }
        this.activity.assigneeId = this.auth.context.principle.objectId;
        let parentManifest = this.host.routeSnapshot.params["parentManifest"];
        let parentId = this.host.routeSnapshot.params["parentId"];
        if (parentManifest && parentId)
            switch (parentManifest) {
                case "organization":
                    this.activity.organizationId = parentId;
                    this.initialized = true;
                    break;
                case "opportunity":
                    this.activity.opportunityId = parentId;
                    this.initialized = true;
                    break;
                case "person":
                    this.personDataSource
                        .list({
                            fieldSet: "lookup",
                            searchFields: [{ name: "objectId", operator: "=", value: parentId }]
                        })
                        .subscribe({
                            next: people => {
                                if (people.length > 0) this.externalParticipants.push(people[0]);
                                this.initialized = true;
                            }
                        });

                    break;
            }
        else this.initialized = true;
        console.log(this.activity);
    }

    updateProjectCode(item: any) {
        console.log(item);
    }

    handleActionExecuting(context: ActionHandlingContext): void {
        if (context.action.name === "save") {
            this.activity.dueDate = correctDateTimeZone(this.activity.dueDate, this._dueTime);
            this.activity.remindDate = correctDateTimeZone(this.activity.remindDate, this._remindTime);
            this.activity.endDate = correctDateTimeZone(this.activity.endDate, this._endTime);
        }
    }
}
