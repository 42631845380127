import * as Form from "./forms";
import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import * as Jupiter from "../../../jupiter/components";
import { AttachmentPreviewComponent } from "../comps/attachment-viewImage";
import { PROPERTY_PAGE_COMPONENTS } from "../property-pages/property-pages.module";
import { AuthGuardService } from "./auth-guard.service";
import { DASHBOARD_WIDGETS, DASHBOARD_COMPONENTS, DashboardComponent } from "./dashboard";

const ROUTES: Routes = [
    { path: "login", component: Form.LoginForm },
    { path: "changepassword", component: Form.ChangePasswordComponent, canActivate: [AuthGuardService] },
    { path: "changeowner", component: Form.ChangeOwnerComponent, canActivate: [AuthGuardService] },
    { path: "attachment/:parentId/preview/:objectId", component: AttachmentPreviewComponent },
    {
        path: "",
        component: Form.MainForm,
        canActivate: [AuthGuardService],
        children: [
            { path: "", component: DashboardComponent, canActivate: [AuthGuardService] },
            { path: "dashboard/:type", component: DashboardComponent, canActivate: [AuthGuardService] },
            {
                path: ":manifest",
                children: [
                    { path: "", component: Jupiter.ObjectExplorerHost, canActivate: [AuthGuardService] },
                    { path: "new", component: Jupiter.ObjectPropertyForm, canActivate: [AuthGuardService] },
                    { path: "property/:id", component: Jupiter.ObjectPropertyForm, canActivate: [AuthGuardService] }
                ]
            },
            {
                path: ":parentManifest/property/:parentId",
                children: [
                    { path: ":manifest/new", component: Jupiter.ObjectPropertyForm, canActivate: [AuthGuardService] },
                    {
                        path: ":manifest/property/:id",
                        component: Jupiter.ObjectPropertyForm,
                        canActivate: [AuthGuardService]
                    }
                ]
            },
            { path: "report/:reportName", component: Jupiter.ReportViewerComponent }
        ]
    }
];

export const DYNAMIC_COMPONENTS: any[] = [...PROPERTY_PAGE_COMPONENTS, ...DASHBOARD_WIDGETS];

export const APP_COMPONENTS: any[] = [
    ...Form.FORM_COMPONENTS,
    ...DASHBOARD_COMPONENTS,
    ...DYNAMIC_COMPONENTS,
    AttachmentPreviewComponent
];

export const APP_ROUTES: ModuleWithProviders = RouterModule.forRoot(ROUTES);
