import { GlobalRegistryService } from "../../../../../jupiter/components";

export const WIDGET = 'dashboard.widget';

export function widget(name: string) {
    return function(target: any) {
        console.debug('widget ', name, ":", target)
        GlobalRegistryService.instance.register(WIDGET, name, target);
        return target;
    }
}