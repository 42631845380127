import { Directive, forwardRef } from "@angular/core";
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, Validators } from "@angular/forms";

@Directive({
    selector: '[requireInteger][ngModel]',
    exportAs: 'requireInteger',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => IntegerValueValidator), multi: true }
    ]
})
export class IntegerValueValidator implements Validator {
    
    private _onChange !: () => void; 

    validate(c: AbstractControl): ValidationErrors | null {
        if (!c.value)
            return null;
        if ( c.value.toString().indexOf('.') < 0)
            return null;
        return {requireInteger: 'Value is not an integer number' };
    }

    registerOnValidatorChange?(fn: () => void): void { this._onChange = fn; }

    
}