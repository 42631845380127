import { Component } from "@angular/core";
import { AccessControlService } from "../../../../jupiter/components";
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { __RELEASE_DATE__ } from "../app.component";

@Component({
    selector: "login-form",
    template: require("./login-form.component.pug")
})
export class LoginForm {
    loginName: string;
    password: string;
    logging: boolean = false;
    returnAssress: string = "/";
    loginFail: boolean = false;

    constructor(private auth: AccessControlService, private activatedRoute: ActivatedRoute, private router: Router) {
        this.activatedRoute.queryParams.subscribe({
            next: params => (this.returnAssress = params["return"] || "/")
        });
    }

    get ReleaseDate(): string {
        return __RELEASE_DATE__;
    }

    login() {
        this.logging = true;
        this.loginFail = false;
        this.auth.authenticate({ type: "password", username: this.loginName, password: this.password }).subscribe({
            next: (_: any) => this.router.navigateByUrl(this.returnAssress),
            error: err => (this.logging = !(this.loginFail = true)),
            complete: () => (this.logging = false)
        });
    }
}
