import {
    property_page,
    AbstractPropertyPage,
    getOrCreateProperty,
    ActionHandlingContext,
    AccessControlService,
    MessageService,
    ActionMessageChannel,
    ActionExecuteHandler,
    ActionHandlingStatus,
    ApiAdvisorProvider
} from "../../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "appuser-property-page",
    template: require("./app-user.proppage.pug")
})
@property_page("AppUserPropertyPage")
export class AppUserPropertyPage extends AbstractPropertyPage<any> implements ActionExecuteHandler {
    constructor(private accessControl: AccessControlService, private messageService: MessageService) {
        super();
        ActionMessageChannel.subscribeActionMessageHandler(this.messageService, this).setFilter({});
    }

    protected onHostChanged(): void {
        getOrCreateProperty(this.editingItem, "properties.roles", []);
    }

    handleActionExecute(context: ActionHandlingContext) {
        if (context.status !== ActionHandlingStatus.Unhandled) return;
        if (context.action.name === "resetPassword") {
            if (!window.confirm("Are you sure to reset the password of this user?")) return;
            this.dataContext.dataSource
                .executeApi("resetPassword", { userId: this.editingItem.objectId, userName: this.editingItem.name })
                .subscribe({
                    next: result => {
                        window.alert("Password reset !");
                    }
                });
        }
    }
}
