import { DataSourceProvider, DataSource } from "../../../jupiter/components";
import { Component, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { __DEFAULT_API_DOMAIN__ } from "../app/app.component";

@Component({
    selector: "attachment-page",
    template: require("./attachment-viewImage.pug")
})
export class AttachmentPreviewComponent implements OnInit {
    private attachmentDataSource: DataSource<any>;
    private attachmentFiles: any[];
    private parentId: string;
    private objectId: string;
    public returnUrl: string;
    private checkExtension(c: string) {
        let valToLower = c.toLowerCase();
        let regex = new RegExp("(.*?).(jpg|png|jpeg)$"); //add or remove required extensions here
        return regex.test(valToLower);
    }

    constructor(private dataSourceProvider: DataSourceProvider, private route: ActivatedRoute) {
        this.parentId = this.route.snapshot.params["parentId"];
        this.objectId = this.route.snapshot.params["objectId"];
        this.attachmentDataSource = this.dataSourceProvider.createDataSource("attachment");
    }

    ngOnInit() {
        this.attachmentDataSource.fetchData("getAttachments", { id: this.parentId }).subscribe({
            next: result => {
                this.attachmentFiles = result;
            }
        });
        this.route.params.subscribe(params => {
            this.parentId = this.route.snapshot.params["parentId"];
            this.objectId = this.route.snapshot.params["objectId"];
        });
        this.route.queryParams.subscribe({
            next: params => {
                this.returnUrl = params["ret"];
                
            }
        });
    }

    get apiDomain(): string {
        return __DEFAULT_API_DOMAIN__;
    }
    get currentFileName(): string {
        for (var i in this.attachmentFiles) {
            if (this.attachmentFiles[i].objectId === this.objectId) return this.attachmentFiles[i].caption;
        }
    }
    get leftId(): string {
        if (this.attachmentFiles == undefined) return;
        for (let index = this.attachmentFiles.length - 1; index >= 0; index--) {
            if (index < this.currentIndex && this.checkExtension(this.attachmentFiles[index].caption))
                return this.attachmentFiles[index].objectId;
        }
        for (let index = this.attachmentFiles.length - 1; index >= 0; index--) {
            if (this.checkExtension(this.attachmentFiles[index].caption)) return this.attachmentFiles[index].objectId;
        }
    }
    get rightId(): string {
        if (this.attachmentFiles == undefined) return;
        for (let index = 0; index < this.attachmentFiles.length; index++) {
            if (index > this.currentIndex && this.checkExtension(this.attachmentFiles[index].caption))
                return this.attachmentFiles[index].objectId;
        }
        for (let index = 0; index < this.attachmentFiles.length; index++) {
            if (this.checkExtension(this.attachmentFiles[index].caption)) return this.attachmentFiles[index].objectId;
        }
    }
    get currentIndex(): number {
        for (var i in this.attachmentFiles) {
            if (
                this.attachmentFiles[i].objectId === this.objectId &&
                this.checkExtension(this.attachmentFiles[i].caption)
            )
                return this.attachmentFiles.indexOf(this.attachmentFiles[i]);
        }
        for (var i in this.attachmentFiles) {
            if (this.checkExtension(this.attachmentFiles[i].caption))
                return this.attachmentFiles.indexOf(this.attachmentFiles[i]);
        }
    }
}
