module.exports = {
	"definitions": {
		"propertyForms": {
			"appUser": {
				"caption": {
					"edit": "{editingItem.caption}",
					"new": "New account"
				},
				"actions": {
					"new": null,
					"save": null,
					"resetPassword": null,
					"close": null
				},
				"pages": {
					"AppUserPropertyPage": {
						"caption": "Detail"
					},
					"AccountAssignedClaimPropertyPage": {
						"caption": "User Permission"
					}
				}
			},
			"appRole": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New role"
				},
				"pages": {
					"AppRolePropertyPage": {
						"caption": "Detail"
					},
					"AccountAssignedClaimPropertyPage": {
						"caption": "Role Permission"
					}
				}
			},
			"claim": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New claim"
				},
				"pages": {
					"ClaimPropertyPage": {
						"caption": "Detail"
					}
				}
			},
			"assignedClaim": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New claim"
				},
				"actions": {
					"new": "/{extra.parentManifest}/property/{extra.parentObjectId}/assignedclaim/new",
					"save": {
						"options": {
							"redirectOnSaveNew": "/{extra.parentManifest}/property/{extra.parentObjectId}/assignedclaim/property/{activeElement.objectId}",
							"class": "primay"
						}
					},
					"close": null
				},
				"pages": {
					"AssignedClaimPropertyPage": {
						"caption": "Detail"
					}
				}
			},
			"activityType": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New activity type"
				},
				"pages": {
					"ActivityTypePropertyPage": {
						"caption": "Detail"
					}
				}
			},
			"organizationType": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New organization type"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"organizationStatus": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New organization status"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"territory": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New territory"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"country": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New country"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"province": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New province"
				},
				"pages": {
					"ProvincePropertyPage": {
						"caption": "Details"
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"$ref": "#/definitions/templates/objectPermissionPage"
					}
				}
			},
			"saltuation": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New saltuation"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"influenceLevel": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New influence level"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"leadsource": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New lead source"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"contactMethod": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New contact method"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"currency": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New currency"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"businessLine": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New business line"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"supplyScope": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New scope of supply"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"segment": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New segment"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"opportunityStage": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New opportunity stage"
				},
				"pages": {
					"OpportunityStagePropertyPage": {
						"caption": "Details"
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"$ref": "#/definitions/templates/objectPermissionPage"
					}
				}
			},
			"opportunityWorkflowStage": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New stage of opportunity workflow"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"projectStage": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New project stage"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"priority": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New priority"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"incoterm": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New incoterm"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"winReason": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New win reason"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"lostReason": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New lost reason"
				},
				"pages": {
					"$ref": "#/definitions/templates/directoryPropertyPages"
				}
			},
			"cncjob": {
				"caption": {
					"edit": "{editingItem.name}",
					"new": "New CNC job"
				},
				"pages": {
					"CncTaskOverviewPropertyPage": {
						"caption": "Overview"
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							],
							"security": [
								"role:Administrators"
							]
						}
					}
				}
			}
		},
		"templates": {
			"directoryPropertyPages": {
				"DefaultDirectoryPropertyPage": {
					"caption": "Detail"
				},
				"ObjectHistoryPage": {
					"caption": "History",
					"visibleWhen": {
						"status": [
							"!new"
						]
					}
				},
				"ObjectPermissionPage": {
					"caption": "Permission",
					"visibleWhen": {
						"status": [
							"!new"
						],
						"security": [
							"role:Administrators"
						]
					}
				}
			}
		}
	}
};