import { Injectable, Injector, InjectionToken, Type } from "@angular/core";
import { ApiAdvisor, ApiAdvisorDeclaration } from "./api-adivsor";
import { ApiService } from "./api.service";
import { isArray } from "rxjs/internal/util/isArray";
import { StringUtil } from "../utils/string-util";
import { CrudApiAdvisor } from "./crud-api-advisor";
import { RemoteCsvLoaderService } from "../remote-data/remote-csv-loader.service";

@Injectable({
    providedIn: 'root'
})
export class ApiAdvisorProvider {
    private _advisors: Map<string, ApiAdvisor> = new Map<string, ApiAdvisor>();
    
    private _advisorTypes: any = {
        default: ApiAdvisor,
        crud: CrudApiAdvisor,
        CRUD: CrudApiAdvisor
    }

    public servers: any = {}

    constructor(public apiService: ApiService, 
                private remoteCsv: RemoteCsvLoaderService) {
        console.log('Create ApiAdvisorProvider...');
    }

    public registerApiAdvisorType(name: string, advisorType: Type<ApiAdvisor>) {
        this._advisorTypes[name] = advisorType;
    }

    public add(data: ApiAdvisor | object | object[]) {
        if (data instanceof ApiAdvisor) {
            let advisor = data as ApiAdvisor;
            this._advisors.set(advisor.name, advisor);
            return;
        }
        if (isArray(data)) {
            for(let advisorData of <object[]>data) {
                this.add(this.createApiAdvisor(this.apiService, this.remoteCsv, advisorData));
            }
            return;
        }
        if (typeof data === "object") {
            for(let advisorName in data) {
                this.add(this.createApiAdvisor(this.apiService, this.remoteCsv, data[advisorName], advisorName));
            }
        }
    }

    public get(name: string): ApiAdvisor {
        return this._advisors.get(name);
    }

    protected createApiAdvisor(apiService: ApiService, 
                                remoteCsv: RemoteCsvLoaderService,
                                data?: any, name?: string): ApiAdvisor {
        var advisorType = this.inferAdvisorType(data);
        var advisorTypeCtor = this._advisorTypes[advisorType];
        if (advisorTypeCtor === undefined)
            throw new Error(`Unknown api advisor type: '${advisorType}'`);
        return this.updateServer(new advisorTypeCtor(apiService, remoteCsv, data, name));
    }

    private updateServer(advisor: ApiAdvisor): ApiAdvisor {
        advisor.server = this.resolveServer(advisor.server);
        return advisor;
    }

    public resolveServer(name: string): string {
        if (name && this.servers)
            return this.servers[name] || name;
        return name;
    }

    protected inferAdvisorType(data: any): string {
        if (typeof data === "string") {
            if (data.startsWith("/"))
                return DEFAULT_API_ADVISOR;
            if (!StringUtil.isBlank(data))
                return data;
        }
        if (typeof data === "object") {
            return data["type"] || DEFAULT_API_ADVISOR;
        }
        return DEFAULT_API_ADVISOR;
    }
}

const DEFAULT_API_ADVISOR: string = "default";

