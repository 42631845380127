import { isNullOrUndefined, isArray } from "util";
import { SortDirection } from "../../core/data-source/query-option";

export type FeatureDisplayMode = "compact" | "full" | "both";

export class ObjectFeature {
    private _name: string;
    private _caption: string;
    private _field: string | string[];
    private _pagingField: string | string[];
    private _sortable: boolean;
    private _sortDirection: SortDirection;
    private _renderer: string;
    private _option: any;
    private _displayMode: FeatureDisplayMode;

    public get name(): string {
        return this._name;
    }
    public get caption(): string {
        return this._caption || this._name;
    }
    public get isMultiFields(): boolean {
        return isArray(this._field);
    }
    public get field(): string | string[] {
        return this.isMultiFields ? this._field : <string>this._field || this._name;
    }
    public get pagingField(): string | string[] {
        return this._pagingField || this.field;
    }
    public template: string;
    public formatter: string[];
    public get sortable(): boolean {
        return this._sortable || false;
    }
    public get sortDirection(): SortDirection {
        return this._sortDirection || "";
    }
    public get renderer(): string {
        return this._renderer;
    }
    public get option(): any {
        return this._option;
    }
    public get displayMode(): FeatureDisplayMode {
        return this._displayMode || "both";
    }

    constructor(data?: ObjectFeature | string, name?: string) {
        if (typeof data === "string") {
            this._field = <string>data;
        } else if (data && typeof data === "object") {
            this._name = data.name;
            this._caption = data.caption;
            this._field = data.field;
            this._pagingField = data.pagingField;
            this._sortable = data.sortable;
            this._sortDirection = data.sortDirection;
            this._renderer = data.renderer;
            this.template = data.template;
            this.formatter = data.formatter;
            this._option = data.option;
            this._displayMode = data.displayMode || data["mode"];
        }
        this._name = this._name || name;
    }
}
