import { Action, deepCopy } from "../../core";
import { Report } from "./report";

const VIEW_REPORT_ACTION: any = {
    icon: "search",
    caption: "View",
    hint: "View the report data",
    type: "event",
    enabledWhen: {
        status: ["valid"]
    }
};

const DOWNLOAD_REPORT_ACTION: any = {
    icon: "cloud_download",
    caption: "Download",
    hint: "Download report data",
    type: "event",
    enabledWhen: {
        status: ["valid"]
    }
};

export const REPORT_ACTIONS: any = {
    view_report: VIEW_REPORT_ACTION,
    download_report: DOWNLOAD_REPORT_ACTION
};

export function createReportActions(report: Report): any {
    let result = Object.assign({}, REPORT_ACTIONS);
    if (report.definition.viewRequest)
        result.view_report.visibleWhen = { security: [`${report.definition.viewRequest}`] };
    if (report.definition.downloadRequest)
        result.download_report.visibleWhen = { security: [`${report.definition.downloadRequest}`] };
    return result;
}
