import { Component } from "@angular/core";
import { AbstractColumnRenderer, column_renderer } from "../../../../jupiter/components";

@Component({
    selector: "compact-opportunity-value",
    template: `
    <div class='flex multi-field-column-container'>
        <span>{{opportunity.expectedDate | date:"yyyy-MM-dd"}}</span>
        <b>{{opportunity.value | currency:"USD":"symbol":"1.0-2"}}</b>
        <span class='grid-column-subvalue'><b>{{winLostText}}</b></span>
    </div>`
})
@column_renderer("CompactOpportunityValue")
export class CompactOpportunityValueColumnRenderer extends AbstractColumnRenderer {
    get opportunity(): any {
        return this.element.properties.opportunity;
    }

    get winLostText(): string {
        let value = this.element.properties.opportunity.stage.metaValue;
        if (value === 100) return "Win";
        if (value === undefined) return "Lost/Canceled";
        return "";
    }
}
