import { data_formatter } from "./data-formatter-decorators.utils";
import { CurrencyPipe } from "@angular/common";

@data_formatter("currency")
export class CurrencyFormatter {
    private static DEFAULT_NUMBER_FORMAT: string = "1.0-2";

    formatter: CurrencyPipe = new CurrencyPipe("en-US");
    format(value: any, options?: any): string {
        if (!options)
            options = {
                format: CurrencyFormatter.DEFAULT_NUMBER_FORMAT,
                code: "USD",
                display: "symbol"
            };
        return this.formatter.transform(value, options.code, options.display, options.format);
    }
}
