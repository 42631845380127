import { Component, OnDestroy } from "@angular/core";
import { widget } from "./widget-decorator";
import {
    AbstractObjectExplorerHost,
    ObjectExploringDescriptorProvider,
    DataSourceProvider,
    ActionProvider,
    AccessControlService,
    MessageService,
    DataSource,
    CRUD,
    SearchField
} from "../../../../../jupiter/components";
import { WidgetContent } from "./abstract-widget-content";
import { WidgetContainerComponent } from "../widget.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "Opportunity-New-Widget",
    template: `
        <ng-container>
            <object-explorer-host manifest="opportunity_new_widget" [dataSource]="tmp" [searchFields]= "searchFields" > </object-explorer-host>
        </ng-container>
    `
})
@widget("OpportunityNewWidget")
export class OpportunityNewWidget extends AbstractObjectExplorerHost implements WidgetContent, OnDestroy {
    widgetContainer: WidgetContainerComponent;
    tmp: DataSource<any>;
    searchFields : SearchField[]
    constructor(
        protected activatedRoute: ActivatedRoute,
        protected browsingDescriptor: ObjectExploringDescriptorProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(
            activatedRoute,
            browsingDescriptor,
            dataSourceProvider,
            actionProvider,
            accessControl,
            messageService,
            router
        );

        this.tmp = this.dataSourceProvider.createDataSource("opportunity");
        this.tmp.option.updatePage(1, 10);
        this.searchFields = [{
            name : 'properties.opportunity.workflowStageId',
            operator : 'isNull'
        }]
    }
    ngOnInit() {
      
    }
}
