module.exports = {
	"definitions": {
		"explorerForms": {
			"topten_opportunity": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": {
						"edit": "/opportunity/property/{element.objectId}"
					},
					"features": {
						"name": {
							"renderer": "MultiFieldColumn",
							"template": "{name} | `grid-column-subvalue` {stageName}",
							"sortable": false
						},
						"chance": null,
						"value": {
							"sortable": false,
							"formatter": [
								"currency"
							],
							"option": {
								"format": "1.0-0"
							}
						}
					}
				}
			},
			"overdue_opportunity": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": {
						"edit": "/opportunity/property/{element.objectId}"
					},
					"features": {
						"name": {
							"renderer": "MultiFieldColumn",
							"template": "{name} | `grid-column-subvalue` {stageName}"
						},
						"expectedDate": {
							"caption": "Exp. Date",
							"renderer": "MultiFieldColumn",
							"template": "{expectedDate} | `grid-column-subvalue` {overdueDays} day(s) ago"
						}
					}
				}
			},
			"active_opportunity": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": {
						"edit": "/opportunity/property/{element.objectId}"
					},
					"features": {
						"name": {
							"renderer": "MultiFieldColumn",
							"template": "{name} | `grid-column-subvalue` {stageName}"
						},
						"expectedDate": {
							"caption": "Exp. Date",
							"renderer": "MultiFieldColumn",
							"template": "{expectedDate} | `grid-column-subvalue` ETA: {overdueDays} day(s)"
						}
					}
				}
			},
			"topten_accounts": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": null,
					"features": {
						"accountName": {
							"sortable": false
						},
						"value": {
							"sortable": false,
							"formatter": [
								"currency"
							],
							"option": {
								"format": "1.0-0"
							}
						}
					}
				}
			},
			"topten_organization": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": {
						"edit": "/organization/property/{element.objectId}"
					},
					"features": {
						"name": null,
						"value": {
							"formatter": [
								"currency"
							],
							"option": {
								"format": "1.0-0"
							}
						},
						"successRate": {
							"caption": "Rate",
							"formatter": [
								"percentage"
							],
							"option": {
								"format": "1.2-2"
							}
						}
					}
				}
			},
			"customer_visit": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"salesman": null,
						"customerVisit": {
							"formatter": [
								"number"
							],
							"option": {
								"format": "1.0-0"
							}
						}
					}
				}
			},
			"active_activity": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": {
						"edit": "/activity/property/{element.objectId}"
					},
					"features": {
						"subject": {
							"renderer": "MultiFieldColumn",
							"template": "{subject} | `grid-column-subvalue` <b>{type}</b>, <em>{assignee}</em>"
						},
						"duedate": {
							"renderer": "MultiFieldColumn",
							"template": "{dueDate} | `grid-column-subvalue` ETA: {dayToComplete} day(s)"
						}
					}
				}
			},
			"widget_OppBySuccessChance": {
				"dataSource": "dashboard",
				"hideSelector": true,
				"settings": {
					"actions": null,
					"features": {
						"successChance": {
							"caption": "Chance"
						},
						"opportunities": null,
						"value": {
							"formatter": [
								"currency"
							],
							"option": {
								"format": "1.0-0"
							}
						}
					}
				}
			}
		}
	}
};