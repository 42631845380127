import { ObjectPropertyCaption, PropertyPageDescriptor } from "./property-page-descriptor";
import { ObjectViewerDescriptor } from "./object-viewer-descriptor";

export class ObjectPropertyDescriptor extends ObjectViewerDescriptor {
    private _caption: ObjectPropertyCaption;

    public pages: PropertyPageDescriptor[] = [];
    public get caption(): ObjectPropertyCaption { return this._caption; }
    public set caption(value: ObjectPropertyCaption) { this._caption = value;}

    constructor(data: any, name?: string) {
        super(data, name)       
        this.caption = new ObjectPropertyCaption(data.caption);
        if (Array.isArray(data.pages)) {
            for(var item of data.pages)
                this.pages.push(new PropertyPageDescriptor(item));
            return;
        }
        for(var page in data.pages) {
            this.pages.push(new PropertyPageDescriptor(data.pages[page], page));
        }
    }
}