import { DataContext, Action, ActionHandlingContext } from "../../core";
import { ObjectViewerDescriptor } from "./object-viewer-descriptor";
import { EventEmitter } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";


export interface ObjectViewerHost<TManifest extends ObjectViewerDescriptor> {
    manifest?: TManifest;
    context?: DataContext<any>;
    title?: string;
    subtitle?: string;
    ready: boolean;
    routeSnapshot: ActivatedRouteSnapshot;
    // onExecuting: EventEmitter<ActionHandlingContext>;
    // onExecute: EventEmitter<ActionHandlingContext>;
    // onExecuted: EventEmitter<ActionHandlingContext>;

    getActions(name: string): Action[];
}

export interface ObjectPropertyHost<TManifest extends ObjectViewerDescriptor> 
                extends ObjectViewerHost<TManifest> {
    editingItem?: any;
    isNewItem?: boolean;
    isDirty: boolean;
    isValid: boolean;

    onEditingItemChanged: EventEmitter<any>;
}

export interface ObjectViewerPart<TManifest extends ObjectViewerDescriptor> {
    host?: ObjectViewerHost<TManifest>;
    options?: any;
}

export namespace StandardActionSet {
    export const ListItemActions = 'actions/explorer/list';
    export const ExplorerActions = 'actions/explorer';
    export const PropertyActions = 'actions/property'
    export const DefaultActions = "actions"
}