import { AccessControlService } from "../../../../jupiter/components";
import { ActivatedRoute, Router } from "@angular/router";
import { Component } from "@angular/core";

@Component({
    selector: 'change-password',
    template: require('./change-password.component.pug')
})
export class ChangePasswordComponent {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
    returnAddress: string;

    working: boolean = false;

    get isPasswordMatched(): boolean {
        return (this.newPassword && this.newPassword.length > 0 
                && this.newPassword === this.repeatPassword)
    }

    get isSubmittable(): boolean {
        return this.currentPassword && this.isPasswordMatched;
    }

    get loginName(): string {
        return this.auth.context.principle.caption;
    }

    constructor(private auth: AccessControlService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {

        this.activatedRoute.queryParams.subscribe({
            next: params => this.returnAddress = params['return'] || '/'
        });
    }

    changePassword(): void {
        this.working = true;
        this.auth.changePassword({ currentPassword: this.currentPassword, 
                                   newPassword: this.newPassword })
            .subscribe({
                next: (_:any) => this.router.navigateByUrl(this.returnAddress),
                error: err => {
                    this.working = false;
                    window.alert('Unable to change password !') ;
                },
                complete: () => this.working = false
            });
    }
}