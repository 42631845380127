module.exports = {
	"datasources": {
		"activity": "/esale/activity",
		"activityType": "/esale/activitytype",
		"activityStatus": "/esale/activitystatus",
		"opportunity": "/esale/opportunity",
		"organization": "/esale/organization",
		"person": "/esale/person",
		"organizationType": "/esale/organizationtype",
		"organizationStatus": "/esale/organizationstatus",
		"territory": "/esale/territory",
		"country": "/esale/country",
		"province": "/esale/province",
		"saltuation": "/esale/saltuation",
		"influenceLevel": "/esale/influencelevel",
		"leadSource": "/esale/leadsource",
		"leadTime": "/esale/leadtime",
		"contactMethod": "/esale/contactmethod",
		"currency": "/esale/currency",
		"businessLine": "/esale/businessline",
		"supplyScope": "/esale/supplyscope",
		"segment": "/esale/segment",
		"opportunityStage": "/esale/opportunitystage",
		"opportunityWorkflowStage": "/esale/opportunityworkflowstage",
		"quotation": "/equotation/quotation",
		"projectStage": "/esale/projectstage",
		"incoterm": "/esale/incoterm",
		"projectClosing": "/esale/projectclosing",
		"winReason": "/esale/winreason",
		"lostReason": "/esale/lostreason",
		"priority": "/esale/priority",
		"project": "/esale/project",
		"dashboard": "/esale/dashboard",
		"appUser": "/accesscontrol/account",
		"appRole": "/accesscontrol/role",
		"claim": "/accesscontrol/claim",
		"assignedClaim": "/accesscontrol/assignedclaim",
		"objectDiary": "/basecontent/objectdiary",
		"report": {
			"api": "/reportengine/report",
			"type": "csv"
		},
		"attachment": "/basecontent/attachment",
		"validationrule": "/equotation/validationrule",
		"skupart": "/equotation/skupart",
		"brand": "/equotation/brand",
		"term": "/equotation/term",
		"attribute": "/equotation/attribute",
		"unit": "/equotation/unit",
		"product": "/equotation/product",
		"productcategory": "/equotation/productcategory",
		"genericpart": "/equotation/genericpart",
		"compositepart": "/equotation/compositepart",
		"part": "/equotation/part",
		"branch": "/equotation/branch",
		"qproduct": "/equotation/qproduct"
	}
};