import { Input, ComponentFactoryResolver, Injector, Type, Directive, ViewContainerRef } from "@angular/core";
import { GlobalRegistryService, AbstractComponentLoader } from "../../core";
import { OBJECT_VIEWER_PART_ENTRY, ObjectViewerHost, ObjectViewerPart } from "../models";



@Directive({
    selector: '[object-viewer-part]'
})
export class ObjectViewerPartDirective extends AbstractComponentLoader {
    public get componentTopic(): string { return OBJECT_VIEWER_PART_ENTRY; }

    @Input('object-viewer-part') component: string | Type<any>;
    @Input() host: ObjectViewerHost<any>;
    @Input() options: any;

    constructor(protected componentFactoryResolver: ComponentFactoryResolver,
        protected registry: GlobalRegistryService,
        public viewContainerRef: ViewContainerRef,
        protected injector: Injector) {
        
        super(componentFactoryResolver, registry, viewContainerRef, injector);
    }

    protected initializeComponent(component: any): void {
        let part = component as ObjectViewerPart<any>;
        part.host = this.host;
        if (this.options){
            part.options = this.options
        };
    }

}
