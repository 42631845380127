import { property_page, AbstractPropertyPage, SearchField, ActionHandlingContext } from "../../../jupiter/components";
import { Component } from "@angular/core";
import { __DEFAULT_WORK_FLOW_STAGE_REQUEST_ID__ } from "../app/app.component";

@Component({
    selector: "sub-property-page",
    template: `
        <ng-container *ngIf="editingItem">
            <object-explorer-host
                [manifest]="options.manifest"
                [parentField]="options.foreignField"
                [parentManifest]="host.manifest"
                [parentObject]="editingItem.objectId"
                [searchFields]= "searchFields"
                (onExecute)="testfunc($event)"
            >
            </object-explorer-host>
        </ng-container>
    `
})
@property_page("OppQuotationPropertyPage")
export class OppQuotationPropertyPage extends AbstractPropertyPage<any> {
    searchFields : SearchField[]
    constructor(
    ) {
        super();
        
        this.searchFields = [{
            name : 'properties.quotation.status',
            value : '2',
            operator : '='
        }]
    }

    testfunc(context: any) {
        console.log('>>>>>>>> action', context);
        if (context.action.name === "RequestUpdate") {
            this.editingItem.properties.opportunity.workflowStageId = __DEFAULT_WORK_FLOW_STAGE_REQUEST_ID__;
            this.editingItem.properties.opportunity.needUpdateQuotation = true; 

            this.dataSource.update(this.editingItem).subscribe({
                next: (y) => {
                    alert("Request Update Quotation Successfull!")
                }   
                });
        }
        // if (context.action.name === "update") {
        //     this.attachmentDataSource.get(context.element.objectId).subscribe(x => {
        //         x.isDeleted = true;
        //         this.attachmentDataSource.update(x).subscribe(x=>{
        //             this.attachmentListViewer.loadData();
        //         })
        //     })

        //     // window.location.href =
        //     //     this.apiDomain +
        //     //     `/basecontent/attachment/getAttachment?id=${this.editingItem.objectId}&name=${context.element.name}`;
        //     // this.attachmentDataSource
        //     //     .executeApi("getAttachment", { id: this.editingItem.objectId, name: context.element.name })
        //     //     .subscribe({
        //     //         next: result => {}
        //     //     });
        // }
    }
}
