import { Directive, ElementRef, Input, HostListener, AfterViewInit, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { CurrencyPipe } from "@angular/common";

@Directive({
    selector: "input[currencyInput]"
})
export class CurrencyInputDirective implements OnInit {
    @Input()
    code: string = "USD";
    @Input()
    symbol: string = "$";
    @Input()
    display: boolean | string = true;
    @Input()
    digitInfo: string = "1.0-2";
    @Input()
    locale: string = "en";

    private focused: boolean;

    private currencyPipe: CurrencyPipe;
    constructor(private el: ElementRef, private ngModel: NgModel) {
        console.log(el);
        console.log(ngModel);
        this.currencyPipe = new CurrencyPipe(this.locale);
    }

    ngOnInit() {
        this.formatValue();
    }

    @HostListener("blur")
    formatValue() {
        if (!this.ngModel) return;
        this.el.nativeElement.value = this.currencyPipe.transform(
            this.ngModel.model,
            this.code,
            this.display,
            this.digitInfo
        );
    }

    @HostListener("focus")
    formatEditingValue() {
        if (!this.ngModel) return;
        this.el.nativeElement.value = this.ngModel.model;
    }
}
