import { property_page, AbstractPropertyPage } from "../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "organization-person-property-page",
    template: `
        <ng-container *ngIf='editingItem'>
            <object-explorer-host manifest='org_person' 
                            parentField='properties.person.organizationId'
                            [parentManifest] = 'host.manifest'
                            [parentObject]='editingItem.objectId'>
            </object-explorer-host>
        </ng-container>
    `
})
@property_page("OrganizationPersonPropertyPage")
export class OrganizationPersonPropertyPage extends AbstractPropertyPage<any> {}
