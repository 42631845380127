export abstract class ObjectViewerDescriptor {
    public name: string;
    public actions: any;

    private _dataSource: string;
    public get dataSource(): string {
        return this._dataSource || this.name;
    }
    public set dataSource(value: string) {
        this._dataSource = value;
    }

    private _secureToken: string;
    public get secureToken(): string {
        return this._secureToken || this.dataSource;
    }
    public set secureToken(value: string) {
        this._secureToken = value;
    }

    constructor(data: any, name?: string) {
        this.name = data.name || name;
        this.dataSource = data.datasource || data.dataSource;
        this.actions = data.actions;
        this.secureToken = data.secureToken;
    }
}
