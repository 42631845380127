import { ObjectFeature } from "./object-feature";
import { ObjectViewerHost } from "./object-viewer-part";
import { ObjectExploringDescriptor } from "./object-exploring-descriptor";

export interface ColumnRenderer {
    feature: ObjectFeature;
    host: ObjectViewerHost<ObjectExploringDescriptor>;
    element: any;

    initialize(): void;
}

export class AbstractColumnRenderer implements ColumnRenderer {
    public feature: ObjectFeature;
    public host: ObjectViewerHost<ObjectExploringDescriptor>;
    public element: any;

    initialize(): void {}
}
