import { ApiAdvisor, ApiAdvisorDeclaration, ApiDeclaration } from "./api-adivsor";
import { Api, ApiType } from "./api";
import { ApiService, ApiMethod } from "./api.service";
import { RemoteCsvLoaderService } from "../remote-data/remote-csv-loader.service";

export class CrudApiAdvisor extends ApiAdvisor {
    
    constructor(protected apiService: ApiService, 
                protected remoteCsv: RemoteCsvLoaderService,
                data?: ApiAdvisorDeclaration, name?: string) {
        super(apiService, remoteCsv,  data, name);
        this.populateCrudApi();
    }

    private populateCrudApi(): void {
        this.getOrCreateApi(CRUD.List, 'api', 'put', 'list', undefined, true);
        this.getOrCreateApi(CRUD.Get, 'api','get', 'get?id={args.id}', undefined, true);
        this.getOrCreateApi(CRUD.Save, 'api','post', 'save', undefined, true );
        this.getOrCreateApi(CRUD.Delete, 'api','put', 'delete?id={args.id}', undefined, true );
        this.getOrCreateApi(CRUD.Count, 'api','put', 'count', undefined, true);
        let newApi = this.getOrCreateApi(CRUD.New, 'data', 'get', null, null, false);
        if (newApi.type === 'data' && !newApi.data)
            newApi.data = { };
    }

    private getOrCreateApi(name: string, 
                            type: ApiType,
                            method?: ApiMethod, 
                            url?: string, 
                            payload?: string, 
                            secure?: boolean): Api<any> {
        var api = super.getApi(name);
        if (api === undefined) {
            api = super.addApi(url, name);
            api.type = type;
        }
        api.method = api.method || method;
        api.type = api.type || type;
        api.url = api.url || url;
        api.payload = api.payload || payload;
        api.secure = api.secure === undefined ? secure : api.secure;
        return api;
    }
}

export namespace CRUD {
    export const List: string = 'list';
    export const Get: string = 'get';
    export const Save: string = 'save';
    export const Delete: string = 'delete';
    export const New: string = 'new';
    export const Count: string = 'count';
}