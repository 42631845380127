import { Api } from "./api";
import { ApiService } from "./api.service";
import { RemoteCsvLoaderService } from "../remote-data/remote-csv-loader.service";

export class ApiAdvisor {
    private _api: Map<string, Api<any>> = new Map<string, Api<any>>();
    private _controller: string 
    private _server: string;

    public name: string;
    public get server(): string { return this._server || 'default'; }
    public set server(value: string) { this._server = value; }
    public get controller(): string { return this._controller || this.name; }

    constructor(protected apiService: ApiService, 
                protected remoteCsv: RemoteCsvLoaderService,
                data?: ApiAdvisorDeclaration, 
                name?: string) {
        if (typeof data === "string") {
            var controllerOrType = data as string
            this._controller = controllerOrType.startsWith('/') ? controllerOrType : undefined;
            this.name = name;
            return;
        }
        if (data !== undefined) {
            this.name = data.name || name;
            this._server = data.server;
            this._controller = data.controller;
            var actions = data.actions
            if (actions !== undefined && typeof actions === "object") {
                for(var apiName in actions) {
                    var api = actions[apiName];
                    if (typeof api === "string")
                        this.addApi(api, apiName);
                    else if (typeof api === "object") 
                        this.addApi(new Api(this.apiService, this.remoteCsv, api, apiName));
                }
            }
        }
    }
    
    public addApi(api: ApiDeclaration, name?: string): Api<any> {
        let api_var = undefined;
        if (typeof api === "string")
            api_var = new Api<any>(this.apiService, this.remoteCsv, { url: api }, name)
        else if (api instanceof Api)
            api_var = api as Api<any>;
        else if (typeof api === "object")
            api_var = new Api<any>(this.apiService, this.remoteCsv, api, name);

        api_var.advisor = this;

        if (api_var.name !== undefined) {
            this._api.set(api_var.name, api_var);
            return api_var;
        }
        console.error("Api name is undefined. Value: ", api_var);
    }

    public getApi<T>(name: string, errorOnNotFound?: boolean): Api<T> {
        let api = this._api.get(name) as Api<T>;
        if (!api && errorOnNotFound)
            log.error(`Api not found: ${this.name}/${name}`)
        return api;
    }
}

export type ApiAdvisorDeclaration = string | 
                            {
                                name?: string;
                                server?: string;
                                controller?: string;
                                actions?: string | object
                            };

export type ApiDeclaration = string | Api<any> |
                            {
                                method?: string;
                                name?: string;
                                url?: string;
                                payload?: string;
                                secure?: boolean;
                            };

