import { Component, forwardRef, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ObjectExploringDescriptorProvider } from "../provider";
import { DataSourceProvider, ActionProvider, AccessControlService, MessageService } from "../../core";
import { AbstractObjectExplorerHost } from "./abstract-object-explorer-host";
import { SearchableComponent } from "../provider/searchable-provider";

@Component({
    selector: "object-explorer-host",
    template: require("./object-explorer.host.pug"),
    providers: [{ provide: SearchableComponent, useExisting: forwardRef(() => ObjectExplorerHost) }]
})
export class ObjectExplorerHost extends AbstractObjectExplorerHost implements SearchableComponent {
    @Input() showActionBar: boolean = true;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected browsingDescriptor: ObjectExploringDescriptorProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(
            activatedRoute,
            browsingDescriptor,
            dataSourceProvider,
            actionProvider,
            accessControl,
            messageService,
            router
        );
        this.routeSnapshot = this.activatedRoute.snapshot;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.manifestName) this.activatedRoute.params.subscribe(x => this.processUrlParam(x));
    }

    protected processUrlParam(params: any) {
        let name = params["manifest"];
        if (name) this.updateManifest(name);
    }

    search(value: any): void {
        let datasource = this.context.dataSource;
        if (!datasource) return;
        if (typeof value === "string") {
            datasource.option.searchFields = datasource.option.searchFields ? (datasource.option.searchFields).filter(x => x.name != "@quicksearch") : [];
            datasource.option.searchFields.push(
                {
                    name: "@quicksearch",
                    value: value
                }
            );
            datasource.option.page = 1;
            datasource.list();
        }
    }
}
