import { property_page,  AbstractPropertyPage, getOrCreateProperty } from '../../../jupiter/components';
import { Component } from '@angular/core';

@Component({
    selector: 'opportunity-stage-property-page',
    template: require('./opportunity-stage.proppage.pug')
})
@property_page('OpportunityStagePropertyPage')
export class OpportunityStagePropertyPage extends AbstractPropertyPage<any> {
}
