
import { Http } from "@angular/http";
import { Observable, Subject } from "rxjs";
import { Injector, Injectable } from "@angular/core";
import { map, flatMap } from "rxjs/operators";

const CSV2JSON = require('csvtojson');

@Injectable()
export class RemoteCsvLoaderService {
    constructor(private http: Http) {

    }

    public fetch(url: string): Observable<any> {
        let subject = new Subject<any>();
        let data: any[] = [];

        this.http.get(url).pipe(
            map(res => CSV2JSON({}).fromString(res.text())),
            flatMap(converter => this.createJsonStream(converter))
        ).subscribe({
            next: json => data.push(json),
            error: err => subject.error(err),
            complete: () => {
                subject.next(data);
                subject.complete();
            }
        });

        return subject;
    }

    private createJsonStream(converter: any): Observable<any> {
        let s = new Subject<any>();
        converter.subscribe(
                (json:any) => s.next(json), 
                (err: any) => s.error(err),
                (_: any) => s.complete());
        return s;
    }
}

