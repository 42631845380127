import { DataSource } from "./data-source";
import { SelectionModel } from "@angular/cdk/collections";

export class DataContext<T> {
    public dataSource: DataSource<T>;
    public selection: SelectionModel<T>;
    public active: T;
    
    constructor(datasource?: DataSource<T>, selection?: T[]) {
        this.dataSource = datasource;
        this.selection = new SelectionModel(true, selection, true);
        this.active = undefined;
    }
}

export class EditingDataContext<T> extends DataContext<T> {

    public get active(): T {
        if (!this.dataSource || !this.dataSource.ready || this.dataSource.data.length === 0)
            return undefined;
        return this.dataSource.data[0];
    }

    public set active(_: T) { /** ignoring */ };

    constructor(dataSource?: DataSource<T>, selection?: T[]) {
        super(dataSource, selection);
    }
}