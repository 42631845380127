export function getOrCreateProperty(item: any, field: string, defaultValue: any = {}): any {
    if (!item) return undefined;

    let obj = item;
    let parts = field.split(".");
    let len = parts.length;
    for (var i = 0; obj && i < len; i++) {
        obj = obj[parts[i]] || (obj[parts[i]] = i < len - 1 ? {} : defaultValue);
    }
    return obj;
}
export function readField(item: any, field: string): any {
    if (!item) return undefined;
    let obj = item;
    let parts = field.split(".");
    let len = parts.length;
    for (var i = 0; obj && i < len - 1; i++) {
        obj = obj[parts[i]];
    }
    if (!obj) return null;
    return obj[parts[len - 1]];
}

export function evalField(text: string, context: any): string {
    if (!text) return undefined;
    var reg = /{.+?}/g;
    return text.replace(reg, match => safeValue(readField(context, match.substr(1, match.length - 2))));
}

function safeValue(value: any): any {
    if (value === undefined || value === null)
        return "";
    return value;
}

export function deepCopy(source: any) {
    if (!source) return source;
    if (typeof source !== "object") return source;
    var target = {};
    for (var prop in source) {
        target[prop] = deepCopy(source[prop]);
    }
    return target;
}
