import { property_page,  AbstractPropertyPage, getOrCreateProperty, NgSelectComponent } from '../../../jupiter/components';
import { Component } from '@angular/core';

@Component({
    selector: 'person-property-page',
    template: require('./person.proppage.pug')
})
@property_page('PersonPropertyPage')
export class PersonPropertyPage extends AbstractPropertyPage<any> {

    get person(): any { return getOrCreateProperty(this.editingItem, 'properties.person'); }

    updateName(saltuation: NgSelectComponent) {
        this.editingItem.name = `${saltuation.selectedLabels.join('')} ${this.person.firstName} ${this.person.lastName}`
    }

    ngOnInit() {
        super.ngOnInit();
        let parentId = this.host.routeSnapshot.params['parentId'];
        if (parentId)
            this.person.organizationId = parentId;
    }
}
