import { Component, OnDestroy } from "@angular/core";
import { widget } from "./widget-decorator";
import {
    AbstractObjectExplorerHost,
    ObjectExploringDescriptorProvider,
    DataSourceProvider,
    ActionProvider,
    AccessControlService,
    MessageService
} from "../../../../../jupiter/components";
import { WidgetContent } from "./abstract-widget-content";
import { WidgetContainerComponent } from "../widget.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "activity-widget",
    template: `
        <ng-container>
            <object-explorer-host manifest="activity_widget"> </object-explorer-host>
        </ng-container>
    `
})
@widget("ActivityWidget")
export class ActivityWidget extends AbstractObjectExplorerHost implements WidgetContent, OnDestroy {
    widgetContainer: WidgetContainerComponent;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected browsingDescriptor: ObjectExploringDescriptorProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(
            activatedRoute,
            browsingDescriptor,
            dataSourceProvider,
            actionProvider,
            accessControl,
            messageService,
            router
        );
    }
}
