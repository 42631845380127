module.exports = {
	"definitions": {
		"shared": {
			"createdDate": {
				"caption": "Created date",
				"mode": "full",
				"formatter": [
					"datetime"
				],
				"option": {
					"format": "dd-MM-yyyy HH:mm"
				}
			}
		},
		"explorerForms": {
			"organization": {
				"title": "Organizations",
				"settings": {
					"features": {
						"codeName": {
							"field": "ident",
							"template": "C-{ident}",
							"mode": "full"
						},
						"name": {
							"renderer": "MultiFieldColumn",
							"template": "{caption} | `grid-column-subvalue` {name}",
							"caption": "Name/Local name",
							"mode": "full"
						},
						"phone": {
							"field": "properties.organization.phone",
							"mode": "full"
						},
						"creator": {
							"field": "owner.caption",
							"mode": "full"
						},
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						},
						"compact_name": {
							"renderer": "MultiFieldColumn",
							"template": "{caption} | `grid-column-subvalue` {name} | `grid-column-subvalue` <em>{properties.organization.phone}</em>",
							"caption": "Name/Local name",
							"mode": "compact"
						}
					}
				}
			},
			"person": {
				"title": "Contact People",
				"actions": {
					"close": null
				},
				"settings": {
					"features": {
						"codeName": {
							"field": "ident",
							"template": "ES-{ident}",
							"mode": "full"
						},
						"name": {
							"caption": "Name/Organization",
							"renderer": "MultiFieldColumn",
							"mode": "full",
							"template": "{name} | `grid-person-organization` {properties.person.organization.caption}"
						},
						"department": {
							"caption": "Position/Department",
							"renderer": "MultiFieldColumn",
							"mode": "full",
							"template": "{properties.person.title} | `grid-person-department` <em>{properties.person.department}</em>"
						},
						"phone": {
							"field": [
								"properties.person.mobile",
								"properties.person.phone"
							],
							"mode": "full"
						},
						"email": {
							"field": "properties.person.email",
							"mode": "full"
						},
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						},
						"compact_name": {
							"caption": "Name/Organization",
							"renderer": "MultiFieldColumn",
							"mode": "compact",
							"template": "{name} | `grid-column-subvalue` <em>{properties.person.title}</em> | `grid-person-organization` {properties.person.organization.caption} "
						},
						"compact_contact": {
							"caption": "Email/Phone",
							"renderer": "MultiFieldColumn",
							"mode": "compact",
							"template": "{properties.person.email} | `grid-column-subvalue` HP: {properties.person.mobile} | `grid-column-subvalue` Tel: {properties.person.phone} "
						}
					}
				}
			},
			"person_activity": {
				"title": "Activities",
				"actions": {
					"new": "/person/property/{extra.parentObjectId}/subactivity/new"
				},
				"datasource": "activity",
				"settings": {
					"actions": {
						"edit": "/person/property/{extra.parentObjectId}/subactivity/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"$ref": "#/definitions/explorerForms/activity/settings/features"
					}
				}
			},
			"org_person": {
				"title": "Contact People",
				"actions": {
					"new": "/organization/property/{extra.parentObjectId}/person/new"
				},
				"datasource": "person",
				"settings": {
					"actions": {
						"edit": "/organization/property/{extra.parentObjectId}/person/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"$ref": "#/definitions/explorerForms/person/settings/features"
					}
				}
			},
			"org_activity": {
				"title": "Activities",
				"actions": {
					"new": "/organization/property/{extra.parentObjectId}/subactivity/new"
				},
				"datasource": "activity",
				"settings": {
					"actions": {
						"edit": "/organization/property/{extra.parentObjectId}/subactivity/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"$ref": "#/definitions/explorerForms/activity/settings/features"
					}
				}
			},
			"attachment": {
				"title": "Attachment",
				"actions": null,
				"datasource": "attachment",
				"settings": {
					"actions": {
						"edit": "/organization/property/{extra.parentObjectId}/subattachment/property/{element.objectId}",
						"getAttachment": {
							"icon": "cloud_download",
							"caption": "Download",
							"type": "event"
						},
						"view": {
							"icon": "search",
							"caption": "View",
							"type": "link",
							"url": "attachment/{extra.parentObjectId}/preview/{element.objectId}"
						},
						"update": {
							"icon": "delete",
							"caption": "Delete",
							"type": "event",
							"visibleWhen": {
								"security": [
									"role:administrators",
									"{manifest.secureToken}:delete"
								]
							}
						}
					},
					"features": {
						"codeName": {
							"field": "ident",
							"template": "ES-{ident}"
						},
						"name": {
							"caption": "Name",
							"renderer": "MultiFieldColumn",
							"template": "{name}"
						},
						"caption": {
							"caption": "Caption",
							"renderer": "MultiFieldColumn",
							"template": "{caption}"
						},
						"fileSize": {
							"caption": "File Size (kB)",
							"field": "metaValue"
						},
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"org_opportunity": {
				"title": "Opportunities",
				"actions": {
					"new": "/organization/property/{extra.parentObjectId}/subopportunity/new"
				},
				"datasource": "opportunity",
				"settings": {
					"actions": {
						"edit": "/organization/property/{extra.parentObjectId}/subopportunity/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"$ref": "#/definitions/explorerForms/opportunity/settings/features"
					}
				}
			},
			"quotation": {
				"title": "Quotations",
				"settings": {
					"features": {
						"code": {
							"caption": "Code",
							"field": "caption"
						},
						"name": {
							"caption": "Name"
						},
						"owner": {
							"field": "owner.caption",
							"caption": "Tender",
							"mode": "full"
						},
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"project": {
				"title": "Projects",
				"settings": {
					"features": {
						"name": {
							"caption": "Project Code"
						},
						"caption": {
							"caption": "Name"
						},
						"metaTextValue": {
							"caption": "Location"
						},
						"metaDescription": {
							"caption": "Description"
						},
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"activity": {
				"title": "Activities",
				"settings": {
					"actions": {
						"edit": "/activity/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"compact_subject": {
							"mode": "compact",
							"caption": "Activity",
							"renderer": "MultiFieldColumn",
							"template": "`grid-column-subvalue` <b>{properties.activity.activityType.name}</b> | {properties.activity.subject} | `grid-column-subvalue` <em>{properties.activity.assignee.caption}</em> "
						},
						"codeName": {
							"field": "ident",
							"template": "ES-{ident}",
							"mode": "full"
						},
						"type": {
							"field": "properties.activity.activityType.name",
							"mode": "full"
						},
						"subject": {
							"field": "properties.activity.subject",
							"mode": "full"
						},
						"remindDate": {
							"field": "properties.activity.remindDate",
							"caption": "Remind Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						},
						"dueDate": {
							"field": "properties.activity.dueDate",
							"caption": "Due Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						},
						"assignee": {
							"field": "properties.activity.assignee.caption",
							"mode": "full"
						}
					}
				}
			},
			"activity_widget": {
				"icon": "activity",
				"title": "Activities",
				"datasource": "activity",
				"actions": [
					"new"
				],
				"settings": {
					"$ref": "#/definitions/explorerForms/activity/settings"
				}
			},
			"opportunity": {
				"title": "Opportunities",
				"settings": {
					"actions": {
						"edit": "/opportunity/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"project": {
							"field": "properties.opportunity.projectCode",
							"caption": "Project",
							"template": "(ES-{properties.opportunity.projectCode}) {properties.opportunity.project.caption}",
							"mode": "full"
						},
						"codeName": {
							"field": "ident",
							"template": "ES-{ident}",
							"mode": "full"
						},
						"name": {
							"caption": "Name/Stage",
							"renderer": "MultiFieldColumn",
							"template": "{name} | `grid-column-subvalue` {properties.opportunity.stage.name}",
							"mode": "full"
						},
						"scopeOfSupply": {
							"field": "properties.opportunity.scopeOfSupply",
							"caption": "Scope Of Supply",
							"mode": "full"
						},
						"successLikelihood": {
							"field": "properties.opportunity.successLikelihood",
							"caption": "Success change",
							"formatter": [
								"percentage"
							],
							"mode": "full"
						},
						"customerBudget": {
							"field": "properties.opportunity.customerBudget",
							"caption": "Customer Budget",
							"formatter": [
								"number"
							],
							"mode": "full"
						},
						"owner": {
							"field": "owner.caption",
							"caption": "Saler",
							"mode": "full"
						},
						"tender": {
							"field": "properties.opportunity.tender.name",
							"caption": "Tender",
							"mode": "full"
						},
						"value": {
							"caption": "Value",
							"field": "properties.opportunity.value",
							"template": "{properties.opportunity.value}",
							"mode": "full"
						},
						"result": {
							"field": "properties.opportunity.stage.metaValue",
							"renderer": "WinLostColumn",
							"mode": "full"
						},
						"expectedDate": {
							"caption": "Construction End",
							"field": "properties.opportunity.expectedDate",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy"
							},
							"mode": "full"
						},
						"organization": {
							"field": "properties.opportunity.organization.name",
							"template": "(C-{properties.opportunity.organization.ident}) {properties.opportunity.organization.name}",
							"mode": "full"
						},
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						},
						"compact_name": {
							"caption": "Name/Stage",
							"renderer": "MultiFieldColumn",
							"template": "{name} | `grid-column-subvalue` <em>{properties.opportunity.organization.name}</em> | `grid-column-subvalue` {properties.opportunity.stage.name}",
							"mode": "compact"
						},
						"compact_value": {
							"caption": "Expected Date",
							"renderer": "CompactOpportunityValue",
							"mode": "compact"
						}
					}
				}
			},
			"opportunity_widget": {
				"icon": "opportunity",
				"title": "Opportunities",
				"actions": [
					"new"
				],
				"datasource": "opportunity",
				"settings": {
					"$ref": "#/definitions/explorerForms/opportunity/settings"
				}
			},
			"opportunity_new_widget": {
				"icon": "opportunity",
				"title": "Opportunities New",
				"actions": [],
				"settings": {
					"$ref": "#/definitions/explorerForms/opportunity/settings"
				}
			},
			"opportunity_request_widget": {
				"icon": "opportunity",
				"title": "Opportunities Request",
				"actions": [],
				"settings": {
					"$ref": "#/definitions/explorerForms/opportunity/settings"
				}
			},
			"opportunity_submited_widget": {
				"icon": "opportunity",
				"title": "Opportunities Submited",
				"actions": [],
				"settings": {
					"$ref": "#/definitions/explorerForms/opportunity/settings"
				}
			},
			"opp_activity": {
				"title": "Activities",
				"actions": {
					"new": "/opportunity/property/{extra.parentObjectId}/subactivity/new"
				},
				"datasource": "activity",
				"settings": {
					"actions": {
						"edit": "/opportunity/property/{extra.parentObjectId}/subactivity/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"$ref": "#/definitions/explorerForms/activity/settings/features"
					}
				}
			},
			"opp_quotation": {
				"title": "Quotation",
				"actions": {
					"RequestUpdate": {
						"icon": "call_missed",
						"caption": "Request Update Quotation",
						"type": "event"
					}
				},
				"datasource": "quotation",
				"settings": {
					"actions": {
						"edit": "/opportunity/property/{extra.parentObjectId}/subquotation/property/{element.objectId}"
					},
					"features": {
						"$ref": "#/definitions/explorerForms/quotation/settings/features"
					}
				}
			},
			"appUser": {
				"title": "Accounts",
				"settings": {
					"features": {
						"name": {
							"caption": "Login name"
						},
						"caption": {
							"caption": "Full name"
						},
						"deactivated": {
							"field": "isArchived",
							"formatter": [
								"boolean"
							],
							"option": {
								"falseLabel": " "
							}
						},
						"territory": {
							"field": "reference.name"
						},
						"manager": {
							"caption": "Direct manager",
							"field": "parent.caption"
						},
						"description": "metaDescription",
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"appRole": {
				"title": "Roles",
				"settings": {
					"features": {
						"name": null,
						"caption": {
							"caption": "Display Name"
						},
						"description": "metaDescription",
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"claim": {
				"title": "Claims",
				"settings": {
					"features": {
						"name": null,
						"domain": "metaTextValue",
						"descriptioni": "metaDescription",
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"assignedClaim": {
				"title": "Permission",
				"actions": {
					"new": "/{extra.parentManifest}/property/{extra.parentObjectId}/assignedclaim/new"
				},
				"settings": {
					"actions": {
						"edit": "/{extra.parentManifest}/property/{extra.parentObjectId}/assignedclaim/property/{element.objectId}",
						"delete": null
					},
					"features": {
						"name": "reference.caption",
						"permission": "metaTextValue",
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"objectDiary": {
				"title": "Object history",
				"hideSelector": true,
				"actions": [],
				"settings": {
					"actions": [],
					"features": {
						"timestamp": {
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						},
						"user": "user.caption",
						"description": {
							"field": "eventDetail",
							"renderer": "ObjectDiaryEventRenderer",
							"option": {
								"eventType": "event"
							}
						}
					}
				}
			}
		}
	}
};