import { ComponentFactoryResolver, ViewChild, Input, Type, OnInit, Injector, ViewContainerRef } from "@angular/core";
import { GlobalRegistryService } from "../registry/global-registry.service";

export abstract class AbstractComponentLoader implements OnInit {

    public abstract get componentTopic(): string;
      
    public abstract get component(): string | Type<any>;

    constructor(protected componentFactoryResolver: ComponentFactoryResolver,
                protected registry: GlobalRegistryService,
                public viewContainerRef: ViewContainerRef,
                protected injector: Injector) {
    }

    ngOnInit() {
        this.loadComponent();
    }

    protected loadComponent(): void {
        let componentType: Type<any>
        if (typeof this.component === "string")
            componentType = this.registry.getValue(this.componentTopic, <string>this.component);
        else componentType = <Type<any>> this.component;

        if (componentType === undefined) {
            console.error('Unknown component of type: ', this.component);
            return;
        }
        console.debug('Load component of type: ', this.component);
        let viewContainerRef = this.viewContainerRef;
        viewContainerRef.clear();

        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        let componentRef = viewContainerRef.createComponent(componentFactory, undefined, this.injector)
        
        this.initializeComponent(componentRef.instance);
    }

    protected abstract initializeComponent(component: any): void;
}

