import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'split'
})
export class StringSplitPipe implements PipeTransform {
    transform(value: string, ...args: any[]): string[] {
        if (!value)
            return [];
        let separator = args.length > 0 ? args[0] : ',';
        return value.split(separator);
    }

}