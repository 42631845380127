import { readField } from "../utils/json.util";

export class JsonResolver {
    public context: any = {};

    public add(name: string, value: any): void {
        this.context[name] = value; 
    }

    public resolvePath(container: string, path: string): any {
        let src = this.evalRef(container, path);
        return this.resolve(container, src);
    }

    /** presume that there is no cyclic reference */
    public resolve(container: string, src: any): any {
        if (!src || typeof src !== 'object' || Array.isArray(src))
            return src;
        let target = {}
        if (src['$ref'])
            return this.resolve(container, this.evalRef(container, src['$ref']));
        this.copy(container, src, target);
        return target;
    }

    private copy(container: string, src: any, target: any) {
        for(var prop in src) {
            if (prop === '$include')
                this.copy(container, this.resolve(container, this.evalRef(container, src[prop])), target)
            else {
                let value = this.resolve(container, src[prop]);
                if (typeof value !== 'object' || Array.isArray(value))
                    target[prop] = value;
                else {
                    target[prop] = {}
                    this.copy(container, value, target[prop]);
                }
            }
        }
    }

    private evalRef(container: string, path: string): any {
        let idx = path.indexOf('/')
        if (idx < 0)
            throw new ReferenceError('Missing container reference: ' + path);
        let name = path.substr(0, idx)
        if (name === '#')
            name = container;
        let host = this.context[name]
        if (!host)
            throw new ReferenceError('Referenced container not loaded: ' + name);
        let expression = path.substr(idx + 1).replace(/\//g, '.');
        return readField(host, expression);
    }
}