import { readField } from "../utils/json.util";
import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";

export type IconType = 'icon' | 'image' | 'svg';

@Injectable()
export class IconProvider {
    private _config: any;

    constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {

    }

    public setIconConfig(value: any) {
        this._config = value;
        let svgIcons = value.svgIcons;
        if (Array.isArray(svgIcons))
            svgIcons.forEach(icon => this.iconRegistry.addSvgIcon(
                icon, this.sanitizer.bypassSecurityTrustResourceUrl(`images/svgIcons/${icon}.svg`)
            ));
    }

    public getIcon(key: string): string {
        return key ? readField(this._config, `icons.${key.toLowerCase()}.icon`) : undefined;
    }

    public getImage(key: string): string {
        return key ? readField(this._config, `icons.${key.toLowerCase()}.image`): undefined;
    }

    public getIconType(key: string): IconType {
        return key ? readField(this._config, `icons.${key.toLowerCase()}.type`) || 'icon' : undefined;
    }
}