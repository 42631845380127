import { Component, Input, forwardRef, ViewChild, OnInit } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { PageEvent, Sort } from "@angular/material";
import {
    Action,
    readField,
    evalField,
    formatData,
    ActionMessageChannel,
    MessageService,
    ActionPreparingContextHandler,
    ActionHandlingContext
} from "../../core";
import {
    ObjectDescriptor,
    ObjectFeature,
    ObjectExploringDescriptor,
    object_viewer_part,
    StandardActionSet,
    FeatureDisplayMode
} from "../../object-explorer";
import { AbstractViewerPartComponent } from "../../object-explorer/components/abstract-viewer-part";
import { isArray } from "util";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "list-viewer",
    template: require("./list-viewer.component.pug")
})
@object_viewer_part("MaterialOvpListViewerComponent")
export class MaterialOvpListViewerComponent extends AbstractViewerPartComponent<ObjectExploringDescriptor> {
    public get actions(): Action[] {
        return this.host.getActions(StandardActionSet.ListItemActions);
    }

    public get hideSelector(): boolean {
        return this.host.manifest.hideSelector;
    }

    public get selection(): SelectionModel<any> {
        return this.dataContext !== undefined ? this.dataContext.selection : undefined;
    }

    public get objectDescriptor(): ObjectDescriptor {
        return this.dataContext !== undefined ? this.manifest.tableSettings : undefined;
    }

    public get displayColumns(): string[] {
        let cols = this.hideSelector ? [] : ["select"];
        if (this.actions && this.actions.length > 0) cols.push("actions");
        cols.push(...this.manifest.columns);
        return cols;
    }

    public get pageIndex(): number {
        return this.dataSource != undefined
            ? this.dataSource.option.page > 0
                ? this.dataSource.option.page - 1
                : -1
            : -1;
    }

    public get pageSize(): number {
        return this.dataSource !== undefined ? this.dataSource.option.pageSize : 10;
    }

    public get recordCount(): number {
        return this.dataSource !== undefined ? this.dataSource.count : 0;
    }

    private getAction(name: string): Action {
        return this.actions.find(action => action.name === name);
    }

    private getCellValue(row: any, feature: ObjectFeature) {
        if (feature.template) return this.format(evalField(feature.template, row), feature);
        if (feature.isMultiFields) {
            let fields: string[] = <string[]>feature.field;
            let template = fields.map(field => `{${field}}`).join(" ");
            return this.format(evalField(template, row), feature);
        }
        return this.format(readField(row, <string>feature.field), feature);
    }

    private format(value: any, feature: ObjectFeature): any {
        if (!feature.formatter) return value;
        return formatData(value, feature.formatter, feature.option);
    }

    private isAllSelected() {
        return this.selection.selected.length === this.dataSource.data.length;
    }

    private masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    private reloadData(event: PageEvent) {
        this.dataSource.option.page = event.pageIndex + 1;
        this.dataSource.option.pageSize = event.pageSize;
        this.dataSource.list();
    }

    private sortData(event: Sort) {
        let selectedFeature = this.manifest.tableSettings.features.find(f => f.name === event.active);
        if (!selectedFeature) return;
        if (event.direction === "") this.dataSource.option.sortFields = [];
        else {
            let fields: string[] = isArray(selectedFeature.field) ? selectedFeature.field : [selectedFeature.field];
            this.dataSource.option.sortFields = fields.map(f => {
                return { name: f, direction: event.direction };
            });
        }
        this.dataSource.list();
    }
}
