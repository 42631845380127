import { Component } from "@angular/core";

import { evalField } from "../../core";
import { column_renderer, AbstractColumnRenderer } from "../../object-explorer";

@Component({
    selector: "html-column",
    template: `
    <div class='html-column-container' #item>
        {{renderValue(item)}}
    </div>
    `
})
@column_renderer("HtmlColumn")
export class HtmlColumnRenderer extends AbstractColumnRenderer {
    private value: string;

    initialize(): void {
        this.value = evalField(this.feature.template, this.element);
    }

    renderValue(item: any) {
        item.innerHTML = this.value;
    }
}
