import { Conditional, ConditionalItem, ConditionChecker } from "../../core";

export class PropertyPageDescriptor extends ConditionalItem {
    private _pageComponent: string;

    public name: string;
    public caption: string;
    public security: string;
    public icon: string;
    public options: any;
    public visibleWhen?: Conditional;
    public enabledWhen?: Conditional;
    public get pageComponent(): string {
        return this._pageComponent || this.name;
    }
    public set pageComponent(value: string) {
        this._pageComponent = value;
    }

    constructor(data?: PropertyPageDescriptor, name?: string, checker?: ConditionChecker) {
        super(data);
        if (data) {
            this.name = data.name;
            this.security = data.security;
            this.icon = data.icon;
            this.pageComponent = data.pageComponent || data["component"];
            this.caption = data.caption;
            this.options = data.options;
        }
        this.checker = checker;
        this.name = this.name || name;
    }
}

export class ObjectPropertyCaption {
    private _new: string;
    private _edit: string;

    public get new(): string {
        return this._new || this._edit;
    }
    public set new(value: string) {
        this._new = value;
    }
    public get edit(): string {
        return this._edit || this._new;
    }
    public set edit(value: string) {
        this._edit = value;
    }
    public icon: string;
    public image: string;

    constructor(data?: any | string, new_caption?: string) {
        if (!data || typeof data === "string") {
            this._edit = data;
            this._new = new_caption;
            return;
        }
        if (typeof data === "object") {
            this._edit = data.edit;
            this._new = data.new || new_caption;
            this.icon = data.icon;
            this.image = data.image;
        }
    }
}
