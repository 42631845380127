import { InjectionToken, Injectable, Inject } from "@angular/core";
import {
    DataSource,
    DataSourceProvider,
    DataSourceDeclaration,
    ApiAdvisorProvider,
    AccessControlService
} from "../../core";
import { Observable } from "rxjs";
import { DatePipe } from "@angular/common";

export const REPORT_DATASOURCE = new InjectionToken<string | DataSourceDeclaration>("report.datasource");

@Injectable()
export class ReportProvider {
    definition: DataSource<ReportDefinition>;

    constructor(
        private dataSourceProvider: DataSourceProvider,
        private apiProvider: ApiAdvisorProvider,
        private authService: AccessControlService,
        @Inject(REPORT_DATASOURCE) private reportDataSource: string | DataSourceDeclaration
    ) {
        this.definition = this.dataSourceProvider.createDataSource(reportDataSource);
        this.definition.fetchData("getReports");
    }

    public createReport(name: string): Report {
        if (!this.definition.data) return null;
        name = name.toLowerCase();
        let definition = this.definition.data.find(report => report.name.toLowerCase() === name);
        let report = new Report(definition);
        report.dataSource = this.dataSourceProvider.createDataSource(this.reportDataSource);
        if (definition.secure) report.arguments["userId"] = this.authService.context.principle.objectId;
        return report;
    }
}

export interface ReportDefinition {
    name?: string;
    icon?: string;
    displayName?: string;
    description?: string;
    module?: string;
    secure?: boolean;
    disabled?: boolean;
    viewRequest?: string;
    downloadRequest?: string;
    arguments?: ReportArgumentDefinition[];
}

export interface ReportArgumentDefinition {
    name?: string;
    displayName?: string;
    type?: string;
    defaultValue?: string;
    optional?: boolean;
}

export class Report {
    definition: ReportDefinition;
    dataSource: DataSource<any>;
    arguments: any;

    constructor(definition: ReportDefinition) {
        this.definition = definition;
        this.arguments = {};
        if (definition.arguments) definition.arguments.forEach(arg => (this.arguments[arg.name] = arg.defaultValue));
    }

    fetchReportData(): Observable<any[]> {
        let reportArgument: any[] = [];
        if (this.arguments)
            for (let argName in this.arguments) reportArgument.push({ name: argName, value: this.arguments[argName] });

        return this.dataSource.fetchData("fetchData", { name: this.definition.name }, reportArgument);
    }

    downloadReport() {
        let renderReportApi = this.dataSource.getApi("renderReport");
        let apiUrl = renderReportApi.getApiUrl({ args: { name: this.definition.name } });
        let args = this.formatArguments();
        for (let argName in args) apiUrl = apiUrl + `&${argName}=${args[argName] || ""}`;

        var link = document.createElement("a");
        link.href = apiUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    private formatArguments(): any {
        let result = Object.assign({}, this.arguments);
        for (let arg of this.definition.arguments)
            if (arg.type === "date") {
                let formater = new DatePipe("en-VN");
                result[arg.name] = formater.transform(result[arg.name], "yyyy-MM-dd");
            }
        return result;
    }
}
