import { Input, ComponentFactoryResolver, Injector, Type, Directive, ViewContainerRef } from "@angular/core";
import { GlobalRegistryService, AbstractComponentLoader } from "../../core";
import {
    ObjectViewerHost,
    ObjectViewerPart,
    TABLE_COLUMN_RENDERER,
    ObjectFeature,
    ObjectExploringDescriptor
} from "../models";
import { ColumnRenderer } from "../models/column-renderer";

@Directive({
    selector: "[column-renderer]"
})
export class ColumnRendererDirective extends AbstractComponentLoader {
    public get componentTopic(): string {
        return TABLE_COLUMN_RENDERER;
    }

    @Input("column-renderer")
    component: string | Type<any>;
    @Input()
    host: ObjectViewerHost<ObjectExploringDescriptor>;
    @Input()
    feature: ObjectFeature;
    @Input()
    element: any;

    constructor(
        protected componentFactoryResolver: ComponentFactoryResolver,
        protected registry: GlobalRegistryService,
        public viewContainerRef: ViewContainerRef,
        protected injector: Injector
    ) {
        super(componentFactoryResolver, registry, viewContainerRef, injector);
    }

    protected initializeComponent(component: any): void {
        let part = component as ColumnRenderer;
        part.host = this.host;
        part.feature = this.feature;
        part.element = this.element;
        part.initialize();
    }
}
