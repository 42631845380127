import { property_page, AbstractPropertyPage, getOrCreateProperty } from "../../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "approle-property-page",
    template: require("./app-role.proppage.pug")
})
@property_page("AppRolePropertyPage")
export class AppRolePropertyPage extends AbstractPropertyPage<any> {
    protected onHostChanged(): void {
        super.onHostChanged();
        getOrCreateProperty(this.editingItem, "properties.users", []);
    }
}
