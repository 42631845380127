import {
    property_page,
    AbstractPropertyPage,
    getOrCreateProperty,
    NgSelectComponent,
    ActionHandlingContext,
    MessageService,
    ActionMessageChannel,
    ActionExecuteHandler,
    ActionHandlingStatus,
    DataSource,
    DataSourceProvider,
    correctDateTimeZone,
    ActionExecutingHandler
} from "../../../jupiter/components";
import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "opportunity-property-page",
    template: require("./opportunity.proppage.pug")
})
@property_page("OpportunityPropertyPage")
export class OpportunityPropertyPage extends AbstractPropertyPage<any>
    implements ActionExecuteHandler, ActionExecutingHandler {
    private businessLineDataSource: DataSource<any>;
    private businessValueNames: any[] = [];
    private projectDataSource: DataSource<any>;
    get opportunity(): any {
        return getOrCreateProperty(this.editingItem, "properties.opportunity");
    }

    get opportunityValues(): any[] {
        return getOrCreateProperty(this.editingItem, "properties.opportunityValues");
    }

    private _incId: number = 0;
    getIncrementalId(): number {
        return Math.round(Math.random() * 100);
    }

    @ViewChild("opportunityStage")
    oppStage: NgSelectComponent;

    get closingReasonObjectType(): string {
        let level = this.opportunityCompletionLevel;
        if (!level) return undefined;
        if (level === -1) return "lost.reason";
        return "win.reason";
    }

    get isClosed(): boolean {
        let level = this.opportunityCompletionLevel;
        return level === 100 || level === -1;
    }

    get opportunityCompletionLevel(): number {
        if (!this.oppStage || !this.oppStage.selectedItems || this.oppStage.selectedItems.length == 0) return undefined;
        let value = this.oppStage.selectedItems[0].value;
        if (typeof value === "string") return undefined;
        let result = value["metaValue"];
        return result === undefined ? -1 : result;
    }

    constructor(
        private router: Router,
        private messageService: MessageService,
        private dataSourceProvider: DataSourceProvider
    ) {
        super();
        ActionMessageChannel.subscribeActionMessageHandler(this.messageService, this).setFilter({});
        this.businessLineDataSource = this.dataSourceProvider.createDataSource("businessLine");
        this.businessLineDataSource.list();

        this.projectDataSource = this.dataSourceProvider.createDataSource("project");
        
    }

    ngOnInit() {
        super.ngOnInit();
        let parentManifest = this.host.routeSnapshot.params["parentManifest"];
        let parentId = this.host.routeSnapshot.params["parentId"];
        if (parentManifest && parentId) this.opportunity.organizationId = parentId;
        if (!this.editingItem.properties) this.editingItem.properties = {}
        if (!this.editingItem.properties.opportunity) this.editingItem.properties.opportunity = {}
        if (!this.editingItem.properties.opportunity.needUpdateQuotation) this.editingItem.properties.opportunity.needUpdateQuotation =false; 
    
        if (this.editingItem.properties.opportunity.projectId)
            this.projectDataSource.get(this.editingItem.properties.opportunity.projectId).subscribe({
                next: (x) => {
                    
                    this.opportunity.projectName = x.caption;
                    //console.log(x);
                    //debugger;
                }
            });
    
    }

    protected getBusinessValueId(idx: number) {
        while (this.businessValueNames.length <= idx) this.businessValueNames.push(this.getIncrementalId());
        return this.businessValueNames[idx];
    }

    protected onHostChanged(): void {
        getOrCreateProperty(this.editingItem, "properties.opportunityValues", []);
    }

    private appendBusinessLine() {
        let oppValueCount = this.opportunityValues.length;
        let id = oppValueCount > 0 ? this.opportunityValues[oppValueCount - 1].id || 1 : 1;
        this.opportunityValues.push({ id: id + 1 });
    }

    private removeBusinessLine(index: number) {
        this.opportunityValues.splice(index, 1);
        this.businessValueNames.slice(index, 1);
        this.updateTotalValue();
    }

    private updateTotalValue() {
        let businessLineValues = this.opportunityValues.map(x => x.value).filter(value => value && value != 0);
        if (businessLineValues.length > 0)
            this.opportunity.value = businessLineValues.reduce((a, b) => a + parseInt(b), 0);
    }

    private updateProject(project: any) {
        if (!project) {
            this.opportunity.projectCode = null;
            this.opportunity.projectLocation = null;
            this.opportunity.projectName = null;
            return;
        }
        console.log(project);
        this.opportunity.projectCode = project.name;
        this.opportunity.projectLocation = project.metaTextValue;
        this.opportunity.projectName = project.caption;
    }

    handleActionExecuting(context: ActionHandlingContext): void {
        if (context.action.name === "save") {
            this.opportunity.startDate = correctDateTimeZone(this.opportunity.startDate);
            this.opportunity.expectedDate = correctDateTimeZone(this.opportunity.expectedDate);
        }
    }

    handleActionExecute(context: ActionHandlingContext) {
        if (context.status !== ActionHandlingStatus.Unhandled) return;
        if (context.action.name === "clone") {
            if (!window.confirm("Are you sure to clone this opportunity?")) return;
            this.dataContext.dataSource.executeApi("clone", { objectId: this.editingItem.objectId }).subscribe({
                next: result => {
                    let url = `/opportunity/property/${result.newOpportunityId}`;
                    console.log(url);
                    this.router.navigateByUrl(url);
                }
            });
        }
    }
}
