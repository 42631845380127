import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccessControlService } from '../../../jupiter/components';
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public auth: AccessControlService, public router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.auth.context.authenticated) {
            this.router.navigate(['login'], { queryParams: {
                return: state.url
            }} );
            return false;
        }
        let manifest = route.params['manifest']
        return true;
    }
}