import { ObserveOnSubscriber } from "rxjs/internal/operators/observeOn";
import { Observable, Subscription } from "rxjs";

export interface Message {
    channel: string;
    topic: string;
    sender: any;
    reciever?: any;
    payload?: any;
    /** The result of message processing */
    result?: any;
    /** Determine the message has been handled completely */
    messageCompleted?: boolean;
}

export interface TypedMessage<TPayload, TResult> extends Message {
    payload?: TPayload;
    result?: TResult;
}

export type MessageHandler = (message: Message) => any;

export interface MessageFilter {
    sender?: any;
    topic?: string;
}

export interface MessageSubscription {
    channel: string;
    receiver?: any;
    filter?: MessageFilter;

    unsubscribe(): void;
    setFilter(value: MessageFilter): MessageSubscription;
}

export class DefaultMessageSubscription implements MessageSubscription {
    public subscription: Subscription;

    constructor(public channel: string, 
                public handler: MessageHandler, 
                public receiver?: any, 
                public filter?: MessageFilter) {

    }

    public handle(message: Message) {
        if (this.isFiltered(message))
            return;
        this.handler(message);
    }

    private isFiltered(message: Message): boolean {
        if (!this.filter)
            return false;
        if (this.filter.sender && this.filter.sender !== message.sender)
            return true;
        if (this.filter.topic && this.filter.topic !== message.topic)
            return true;
        if (message.reciever && message.reciever !== this.receiver)
            return true;
        return false;
    }

    setFilter(value: MessageFilter): MessageSubscription {
        this.filter = value;
        return this;
    }

    public unsubscribe(): void {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}