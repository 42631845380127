import { AuthenticatorService } from "./authenticator";
import { AuthenticationRequest, AuthenticationContext } from "../access-control.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiAdvisorProvider } from "../../api/api-advisor.provider";
import { AccessControlSetting } from "../access-control.service";

@Injectable()
export class PasswordAuthenticatorService implements AuthenticatorService {

    public settings: AccessControlSetting

    constructor(private apiProvider: ApiAdvisorProvider,
                private context: AuthenticationContext) {
        
    }

    public authenticate(request: AuthenticationRequest): Observable<AuthenticationContext> {
        let tokenServer = this.apiProvider.resolveServer(this.settings.tokenServer)
        let url = `${tokenServer}/oauth/token`
        let payload = `username=${request.username}&password=${request.password}&grant_type=password`
        return this.apiProvider.apiService.http.post(url, payload).pipe(
            map(body => body.json()),
            map(result => {
                request.password = undefined; // remove password
                this.context.type = result.token_type;
                this.context.token = result.access_token;
                this.context.expires = result.expires_in;
                this.context.timestamp = (new Date()).valueOf();
                this.context.request = request;
                return this.context;
            })
        );
    }
}

export interface PasswordAuthenticatorSetting {
    /** The token server */
    token?: string;
    /** The api to access user claims */
    authentication?: string;
}