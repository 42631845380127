import { ApiService, ApiMethod } from "./api.service";
import { Observable } from "rxjs/observable";
import { evalField, readField, deepCopy } from "../utils/json.util";
import { RemoteCsvLoaderService } from "../remote-data/remote-csv-loader.service";
import { map, delay } from "rxjs/operators";
import "rxjs/add/observable/of";
import { ApiAdvisor } from "./api-adivsor";
import { StringUtil } from "../utils/string-util";
/**
 * Represent an API callable
 *
 * @argument T type of response object
 */
export class Api<T> {
    public method: ApiMethod = "get";
    public name?: string;
    public url?: string;
    public payload?: string;
    public secure?: boolean;
    public type: ApiType = "api";
    public data: any;
    public advisor: ApiAdvisor;

    constructor(
        private apiService: ApiService,
        private remoteCsv: RemoteCsvLoaderService,
        data?: object,
        name?: string
    ) {
        if (data) {
            this.name = data["name"];
            this.method = data["method"];
            this.url = data["url"];
            this.payload = data["payload"];
            this.secure = data["secure"];
            this.type = data["type"] || "api";
            this.data = data["data"];
        }
        this.name = this.name || name;
    }
    public invoke(context: ApiExecutionContext): Observable<T> {
        switch (this.type) {
            case "api":
                return this.apiService
                    .invokeApi(
                        this.method || "get",
                        this.resolveUrl(context),
                        this.resolvePayload(context),
                        this.secure
                    )
                    .pipe(map(content => content.json()));
            case "csv":
                return this.remoteCsv.fetch(this.resolveUrl(context, true));
            case "data":
                return Observable.of(deepCopy(this.data) || {}).pipe(delay(500));
        }
    }

    public getApiUrl(context: ApiExecutionContext): string {
        return this.resolveUrl(context);
    }

    protected resolveUrl(context: any, ignoreController?: boolean): string {
        var url = this.url || this.name;
        return this.addUrlPrefix(evalField(url, context), ignoreController);
    }

    protected addUrlPrefix(url: string, igngoreController?: boolean): string {
        if (!igngoreController) url = `${this.advisor.controller}/${url}`;
        if (!StringUtil.isBlank(this.advisor.server) && url.startsWith("/")) url = this.advisor.server + url;
        return url;
    }

    protected resolvePayload(context: any): any {
        if (context === undefined) return undefined;
        return readField(context, this.payload || "payload");
    }
}

export type ApiType = "api" | "data" | "csv" | "json" | "method";

export interface ApiExecutionContext {
    payload?: any;
    credential?: any;
    args?: any;
}
