import {
    property_page,
    AbstractPropertyPage,
    ActionHandlingContext,
    MessageService,
    ActionMessageChannel,
    ActionHandlingStatus,
    DataSourceProvider,
    DataSource,
    ObjectExplorerHost
} from "../../../../jupiter/components";
import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { HttpResponse } from "@angular/common/http";
import { __DEFAULT_API_DOMAIN__ } from "../../app/app.component";

@Component({
    selector: "attachment-property-page",
    template: require("./attachment.proppage.pug")
})
@property_page("AttachmentPropertyPage")
export class AttachmentPropertyPage extends AbstractPropertyPage<any> {

    @ViewChild('attachmentListViewer') attachmentListViewer: ObjectExplorerHost;

    get apiDomain(): string {
        return __DEFAULT_API_DOMAIN__;
    }
    private attachmentDataSource: DataSource<any>;
    constructor(
        private router: Router,
        private messageService: MessageService,
        private dataSourceProvider: DataSourceProvider
    ) {
        super();
        ActionMessageChannel.subscribeActionMessageHandler(this.messageService, this).setFilter({});
        this.attachmentDataSource = this.dataSourceProvider.createDataSource("attachment");
    }

    browseFile(event: any, fileinput: any) {
        fileinput.value = null;
        fileinput.click();
    }

    handleActionExecute(context: ActionHandlingContext) {
        if (context.action.name === "getAttachment") {
            window.location.href =
                this.apiDomain +
                `/basecontent/attachment/getAttachment?id=${this.editingItem.objectId}&name=${context.element.name}`;
            this.attachmentDataSource
                .executeApi("getAttachment", { id: this.editingItem.objectId, name: context.element.name })
                .subscribe({
                    next: result => {}
                });
        }
        if (context.action.name === "update") {
            this.attachmentDataSource.get(context.element.objectId).subscribe(x => {
                x.isDeleted = true;
                this.attachmentDataSource.update(x).subscribe(x=>{
                    this.attachmentListViewer.loadData();
                })
            })

            // window.location.href =
            //     this.apiDomain +
            //     `/basecontent/attachment/getAttachment?id=${this.editingItem.objectId}&name=${context.element.name}`;
            // this.attachmentDataSource
            //     .executeApi("getAttachment", { id: this.editingItem.objectId, name: context.element.name })
            //     .subscribe({
            //         next: result => {}
            //     });
        }
    }
    fileUpload(event: any, attachmentListViewer: ObjectExplorerHost) {
        if (!(event.event instanceof HttpResponse) || !attachmentListViewer) return;

        attachmentListViewer.loadData();
    }
}
