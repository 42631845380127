import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import {
    DataSourceProvider,
    ActionProvider,
    AccessControlService,
    MessageService,
    ActionMessageChannel,
    ActionHandlingContext,
    ActionPreparingContextHandler
} from "../../core";
import { ObjectPropertyDescriptorProvider } from "../provider/object-property-descriptor.provider";
import { NgForm } from "@angular/forms";
import { AbstractObjectPropertyHost } from "./abstract-object-property-host";
import { MatTabGroup } from "@angular/material";

@Component({
    selector: "object-property",
    template: require("./object-property.form.pug")
})
export class ObjectPropertyForm extends AbstractObjectPropertyHost implements ActionPreparingContextHandler {
    private queryParams: Params;
    public get isDirty(): boolean {
        return this.formControl && this.formControl.dirty;
    }
    public get isValid(): boolean {
        return this.formControl && this.formControl.valid;
    }

    get parentManifest(): string {
        return this.routeSnapshot.params["parentManifest"];
    }
    get parentObjectId(): any {
        return this.routeSnapshot.params["parentId"];
    }

    @ViewChild(NgForm) formControl: NgForm;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected propertyDescriptor: ObjectPropertyDescriptorProvider,
        protected actionProvider: ActionProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(
            activatedRoute,
            propertyDescriptor,
            actionProvider,
            dataSourceProvider,
            accessControl,
            messageService,
            router
        );
        this.routeSnapshot = this.activatedRoute.snapshot;
        this.activatedRoute.queryParams.subscribe({
            next: params => (this.queryParams = params)
        });
        this.messageSubscriptions.push(
            ActionMessageChannel.subscribeActionMessageHandler(messageService, this).setFilter({
                topic: ActionMessageChannel.PREPARE_CONTEXT
            })
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.activatedRoute.params.subscribe(x => this.processUrlParam(x));
        this.tabGroup.selectedIndex = this.queryParams["tab"] || 0;
    }

    handleActionPreparingContext(context: ActionHandlingContext): void {
        let retParam: string = context.navigationExtras.queryParams["retparam"] || "";
        retParam = `tab=${this.tabGroup.selectedIndex}` + (retParam.length > 0 ? `|${retParam}` : "");
        context.navigationExtras.queryParams["retparam"] = retParam;
    }

    protected processUrlParam(params: any) {
        let defName = params["manifest"];
        let id = params["id"];

        let manifest = this.propertyDescriptor.getDescriptor(defName);
        if (!manifest) {
            console.error(`Undefined object property manifest: ${defName}`);
            return;
        }
        this.manifest = manifest;
        this.isNewItem = id === undefined;
        let obj = this.isNewItem ? this.context.dataSource.newItem() : this.context.dataSource.get(id);
        obj.subscribe({
            next: _ => {
                this.editingItem = this.context.active;
            }
        });
    }
}
