import { Injectable } from "@angular/core";
import { Action } from "./action";
import { TypeUtils } from "../utils/type-utils";
import { ActionHandlingContext, ActionExecuteHandler } from "./action-handler";
import { ConditionChecker } from "../condition/conditional-checker";

@Injectable()
export class ActionProvider {
    private _actions: any = {};
    
    public defaultActionNames: any = {};

    public add(action: Action | object) {
        if (action instanceof Action) {
            this._actions[action.name] = action;
            return;
        }
        for(let name in  action) {
            let a = new Action(action[name]);
            a.name = a.name || name;
            this.add(a);
        }
    }

    public getAction(name: string): Action {
        return this._actions[name];
    }

    public getDefaultActions(defaultSet: string, handler?: ActionExecuteHandler, 
                            context?: ActionHandlingContext, checker?: ConditionChecker): Action[] {
        let actionSet = this.defaultActionNames[defaultSet];
        if (TypeUtils.isArrayOfType(actionSet, "string"))
            return this.createActions(handler, context, checker, ...actionSet);
        return [];
    }

    /** build a list of actions given a template or default action set 
     * 
     * @param actions either an array of action names, or an object of which each property is an action
     * @param defaultActionSet 
     * @param context an optional `ActionHandlingContext` passing to  `Action`'s constructor
    */
    public buildActionList(actions: any, defaultActionSet: string, 
                handler?: ActionExecuteHandler, context?: ActionHandlingContext,
                checker?: ConditionChecker): Action[] {
        if (!actions)
            return this.getDefaultActions(defaultActionSet, handler, context, checker);
        if (TypeUtils.isArrayOfType(actions, "string"))
            return this.createActions(handler, context, checker, ...actions);
        let result: Action[] = [];
        for(let name in actions) {
            let a = new Action(actions[name], handler, context, checker);
            a.name = a.name || name;
            a.mergeProperty(this.getAction(name));
            result.push(a);
        }
        return result;
    }

    private createActions(handler?: ActionExecuteHandler, context?: ActionHandlingContext, 
                        checker?: ConditionChecker, ...actionNames: string[]): Action[] {
        let result: Action[] = [];
        for(let name of actionNames) {
            let a = this._actions[name];
            if (a !== undefined)
                result.push(new Action(a, handler, context, checker));
        }
        return result;
    }
}

export namespace DefaultActionSet {
    export const DEFAULT_GRID_ACTION_SET = "explorer.grid";
    export const DEFAULT_EXPLORER_ACTION_SET = "explorer";
    export const DEFAULT_PROPERTY_ACTION_SET = "property";
}
