import {
    property_page,
    AbstractPropertyPage,
    DataSourceProvider,
    DataSource,
    MessageService,
    ActionMessageChannel,
    ActionHandlingContext
} from "../../../../jupiter/components";
import { Component } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
@Component({
    selector: "attachment-page",
    template: require("./attachment-detail.proppage.pug")
})
@property_page("AttachmentPage")
export class AttachmentDetailPage extends AbstractPropertyPage<any> {
    private attachmentDataSource: DataSource<any>;
    private attachmentFiles: any[];
    private bytesPipe: any;
    get apiDomain(): string {
        return __API_DOMAIN__;
    }
    formatDatetime(date: string): string {
        let formater = new DatePipe("en-VN");
        return formater.transform(date, "yyyy-MM-dd hh:mm");
    }

    constructor(private dataSourceProvider: DataSourceProvider, private messageService: MessageService) {
        super();
        ActionMessageChannel.subscribeActionMessageHandler(this.messageService, this).setFilter({});
        this.attachmentDataSource = this.dataSourceProvider.createDataSource("attachment");
    }

    ngOnInit() {
        super.ngOnInit();
        let parentId: any = this.host.routeSnapshot.params["parentId"];
        if (parentId) this.editingItem.parentId = parentId;
        this.editingItem.createdDate = this.formatDatetime(this.editingItem.createdDate);
        this.editingItem.modifiedDate = this.formatDatetime(this.editingItem.modifiedDate);
    }

    browseFile(event: any, fileinput: any) {
        fileinput.value = null;
        fileinput.click();
    }

    fileUpload(event: any) {
        if (!(event.event instanceof HttpResponse)) return;
        this.attachmentDataSource.fetchData("getAttachments", { objectId: this.editingItem.objectId }).subscribe({
            next: _ => (this.attachmentFiles = this.attachmentDataSource.data)
        });
        this.ngOnInit();
    }

    handleActionExecute(context: ActionHandlingContext) {
        if (context.action.name === "getAttachment") {
            window.location.href =
                this.apiDomain +
                `/basecontent/attachment/getAttachment?id=${this.editingItem.parentId}&name=${this.editingItem.name}`;
            this.attachmentDataSource
                .executeApi("getAttachment", { id: this.editingItem.parentId, name: this.editingItem.name })
                .subscribe({
                    next: result => {}
                });
        }
    }
}
