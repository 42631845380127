import { Input, OnInit, OnDestroy } from "@angular/core";
import {
    DataSource,
    CRUD,
    DataSourceProvider,
    MessageService,
    MessageSubscription,
    Message
} from "../../../../../jupiter/components";
import { Dashboard } from "../dashboard";
import { WidgetContainerComponent } from "../widget.component";

export interface WidgetContent {
    widgetContainer: WidgetContainerComponent;
}

export abstract class AbstractWidgetContent implements WidgetContent, OnInit, OnDestroy {
    @Input()
    set ngDataSource(value: DataSource<any> | string) {
        if (value === this.dataSource) return;
        if (typeof value === "string") {
            let ds = this.dataSourceProvider.createDataSource(value);
            if (!ds) console.log("Data source id not found:", value);
            value = ds;
        }
        this.dataSource = value;
    }
    get ngDataSource(): DataSource<any> | string {
        return this.dataSource;
    }
    protected dataSource: DataSource<any>;
    public widgetContainer: WidgetContainerComponent;

    @Input()
    api: string = CRUD.List;
    @Input()
    dataset: string;
    @Input()
    dashboard: Dashboard;

    private widgetSubscription: MessageSubscription;

    constructor(protected dataSourceProvider: DataSourceProvider, protected messageService: MessageService) {
        this.dataSource = dataSourceProvider.createDataSource("dashboard");
        this.widgetSubscription = this.messageService.subsribe("widget", msg => this.handleWidgetMessage(msg));
    }

    ngOnInit() {
        this.setupDataConnection();
        this.refresh();
    }

    ngOnDestroy() {
        this.widgetSubscription.unsubscribe();
    }

    protected setupDataConnection() {
        if (!this.dataSource || !this.dashboard) return;
        this.dashboard.trigger.subscribe({
            next: _ => this.refresh()
        });
    }

    protected handleWidgetMessage(msg: Message) {
        switch (msg.topic) {
            case "refresh":
                this.refresh();
                break;
        }
    }

    public refresh(): void {
        this.dataSource
            .fetchData(this.api, {
                dataset: this.widgetContainer.widget.dataSet,
                period: this.widgetContainer.widget.data.period,
                territory: this.widgetContainer.option.territory
            })
            .subscribe({
                next: _ => this.updateContents()
            });
    }

    protected abstract updateContents(): void;
}
