module.exports = {
	"definitions": {
		"reports": {
			"oppReport": {
				"title": "Opportunity Report",
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"codeName": {
							"template": "ES-{codeName}"
						},
						"name": null,
						"value": {
							"formatter": [
								"number"
							],
							"option": {
								"format": "1.0-0"
							}
						},
						"organization": null,
						"territory": null,
						"status": null,
						"scopeOfSupply": {
							"caption": "Scope"
						},
						"segmentation": null,
						"incoterm": null,
						"owner": null,
						"expectedDate": {
							"caption": "Construction End",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy"
							}
						}
					}
				}
			},
			"orgReport": {
				"title": "Organization Report",
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"localName": {
							"caption": "Local name"
						},
						"organizationType": {
							"capton": "Type"
						},
						"organizationStatus": {
							"caption": "Status"
						},
						"territory": {
							"caption": "Territory"
						},
						"website": {
							"caption": "Website"
						},
						"creator": null,
						"createdDate": {
							"caption": "Created Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						}
					}
				}
			},
			"peopleReport": {
				"title": "Contact People Report",
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"territory": null,
						"name": {
							"renderer": "MultiFieldColumn",
							"template": "{name} | `grid-column-subvalue` {organization}"
						},
						"title": {
							"caption": "Position/Department",
							"renderer": "MultiFieldColumn",
							"template": "{title} |  `grid-person-department` <em>{department}</em>"
						},
						"phone": {
							"field": [
								"mobile",
								"phone"
							],
							"template": "{mobile}, {phone}"
						},
						"email": null,
						"creator": null,
						"createdDate": {
							"caption": "Created Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						}
					}
				}
			},
			"marketValueReport": {
				"title": "Market Value Report",
				"icon": "report",
				"hideSelector": true
			},
			"activityReport": {
				"title": "Salesman Activity Report",
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"territory": null,
						"assignee": null,
						"subject": {
							"renderer": "MultiFieldColumn",
							"template": "{subject} | `grid-column-subvalue` {type}, Priority: {priority}"
						},
						"dueDate": {
							"renderer": "MultiFieldColumn",
							"template": "{dueDate} | `grid-column-subvalue` {status}"
						},
						"opportunity": {
							"caption": "Opportunity/Org.",
							"renderer": "MultiFieldColumn",
							"template": "{opportunity} | `grid-column-subvalue` {organization}"
						}
					}
				}
			},
			"userActivityReport": {
				"icon": "report",
				"title": "User Activity Report",
				"hideSelector": true
			},
			"overdueOppReport": {
				"title": "Overdue Opportunity Report",
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"code": {
							"template": "ES-{code}"
						},
						"opportunity": null,
						"stage": null,
						"value": {
							"formatter": [
								"number"
							],
							"option": {
								"format": "1.0-0"
							}
						},
						"organization": null,
						"territory": null,
						"overdueDays": null,
						"expectedDate": {
							"caption": "Construction End",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy"
							}
						}
					}
				}
			},
			"activeOppReport": {
				"title": "To-Expired Opportunity Report",
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"code": {
							"template": "ES-{code}"
						},
						"opportunity": null,
						"stage": null,
						"value": {
							"formatter": [
								"number"
							],
							"option": {
								"format": "1.0-0"
							}
						},
						"organization": null,
						"territory": null,
						"daysToExpired": null,
						"expectedDate": {
							"caption": "Expected Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy"
							}
						}
					}
				}
			},
			"accountReport": {
				"icon": "report",
				"hideSelector": true,
				"settings": {
					"actions": [],
					"features": {
						"loginName": {
							"caption": "Login Name"
						},
						"fullName": {
							"caption": "Full Name"
						},
						"territory": null,
						"directManager": {
							"caption": "Direct Manager"
						},
						"createdDate": {
							"caption": "Created Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						},
						"modifiedDate": {
							"caption": "Modified Date",
							"formatter": [
								"datetime"
							],
							"option": {
								"format": "dd-MM-yyyy HH:mm"
							}
						}
					}
				}
			}
		}
	}
};