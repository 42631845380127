import { DataFormatter, data_formatter } from "./data-formatter-decorators.utils";
import { DatePipe, CurrencyPipe, DecimalPipe } from "@angular/common";

@data_formatter('number')
export class NumberFormatter  {

    private static DEFAULT_NUMBER_FORMAT: string = '1.0-2';

    formatter: DecimalPipe = new DecimalPipe("en-US");
    format(value: any, options?: any): string {
        let format = (options) ? (options.format || NumberFormatter.DEFAULT_NUMBER_FORMAT) 
                        : NumberFormatter.DEFAULT_NUMBER_FORMAT
        return this.formatter.transform(value, format);
    }

}