import { AccessControlService, AuthorizeRequest } from "../access-control/access-control.service";
import { ClaimRequest } from "../access-control/access-control.model";

export function createClaim(name: string, value: string): ClaimRequest {
	return { name: name, value: value }
}

/**
 * Parse claim in text into an array of Claim
 * 
 * Example: role:Administrators; approle:read,edit; 
 */
export function parseClaims(value: string): ClaimRequest[] {
	var claims: ClaimRequest[] = []
	if (!value)
		return claims
	var claimText = value.split(";")
	claimText.forEach(text => {
		let claim = parseSingleClaim(text.trim())
		if (claim)
			claims.push(claim)
	})
	return claims
}

function parseSingleClaim(value: string): ClaimRequest {
	var fragment = value.split(':', 2)
	if (fragment.length > 1)
		return { name: fragment[0], value: fragment[1] }
	return { name: value, value: '' }
}

export function authorize(auth: AccessControlService, item: AuthorizeRequest): boolean {
    return auth.authorize(item);
}