import { Component, Inject, ViewChild } from "@angular/core";
import { AbstractObjectExplorerHost, ObjectExploringDescriptorProvider, StandardStatus } from "../../object-explorer";
import { ActivatedRoute, Router } from "@angular/router";
import {
    DataSourceProvider,
    ActionProvider,
    AccessControlService,
    MessageService,
    Action,
    ActionHandlingContext
} from "../../core";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { ReportProvider, Report, ReportArgumentDefinition } from "./report";
import { REPORT_ACTIONS, createReportActions } from "./report-action";
import { NgForm } from "@angular/forms";

@Component({
    selector: "report-viewer",
    template: require("./report-viewer.component.pug"),
    animations: [
        trigger("activeTab", [
            state(
                "parameter",
                style({
                    width: window.innerWidth > 500 ? "500px" : "100%",
                    maxWidth: "100%",
                    marginLeft: window.innerWidth < 800 ? "auto" : "calc(50vw - 250px)"
                })
            ),
            state("data", style({})),
            transition("parameter => data, data => parameter", [animate("800ms 100ms ease-in-out")])
        ])
    ]
})
export class ReportViewerComponent extends AbstractObjectExplorerHost {
    private report: Report;
    private reportActions: Action[];
    private selectedTab: number = 0;

    @ViewChild(NgForm)
    formControl: NgForm;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected browsingDescriptor: ObjectExploringDescriptorProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router,
        private reportProvider: ReportProvider
    ) {
        super(
            activatedRoute,
            browsingDescriptor,
            dataSourceProvider,
            actionProvider,
            accessControl,
            messageService,
            router
        );
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(x => this.processUrlParam(x));
    }

    protected processUrlParam(params: any) {
        let manifestName = params["reportName"];
        let subscription = this.reportProvider.definition.connect(null).subscribe({
            next: _ => {
                this.report = this.reportProvider.createReport(manifestName);
                if (this.report) this.updateManifest(manifestName);
            }
        });
    }

    protected get actions(): Action[] {
        if (this.reportActions || !this.report) return this.reportActions;
        this.reportActions = this.buildActions(this.reportActions, createReportActions(this.report), null);
        return this.reportActions;
    }

    protected manifestChanged() {
        this.dataSource = this.report.dataSource;
        this.reportActions = undefined;
        super.manifestChanged();
    }

    loadData() {
        // do nothing as data is loaded via report api
    }

    public hasStatus(status: string): boolean {
        if (!this.formControl) return super.hasStatus(status);

        switch (status) {
            case StandardStatus.Dirty:
                return this.formControl.dirty;
            case StandardStatus.Invalid:
                return this.formControl.invalid;
            case StandardStatus.Valid:
                return this.formControl.valid;
            default:
                return super.hasStatus(status);
        }
    }

    protected handleEventAction(context: ActionHandlingContext) {
        switch (context.action.name) {
            case "view_report":
                this.report.fetchReportData().subscribe({
                    next: _ => (this.selectedTab = 1)
                });
                break;
            case "download_report":
                this.report.downloadReport();
                break;
        }
    }

    private isLookupArgument(arg: ReportArgumentDefinition) {
        return arg.type.startsWith("@");
    }

    private getLookupDataSource(arg: ReportArgumentDefinition) {
        return arg.type.substring(1);
    }
}
