import { property_page,  AbstractPropertyPage, getOrCreateProperty } from '../../../jupiter/components';
import { Component } from '@angular/core';

@Component({
    selector: 'organization-property-page',
    template: require('./organization.proppage.pug')
})
@property_page('OrganizationPropertyPage')
export class OrganizationPropertyPage extends AbstractPropertyPage<any> {

    get organization(): any { return getOrCreateProperty(this.editingItem, 'properties.organization'); }

}
