import { DataFormatter, data_formatter } from "./data-formatter-decorators.utils";
import { DatePipe, CurrencyPipe, DecimalPipe } from "@angular/common";
import { NumberFormatter } from "./number.formatter";

@data_formatter("percentage")
export class PercentageFormatter extends NumberFormatter {
    format(value: any, options?: any): string {
        if (value > 0) {
            if (value <= 1)
                return super.format(value * 100, options) + "%";
            else
                return super.format(value, options) + "%";
        }
        else
            return '';
    }
}
