export interface QueryOption {
    fieldSet?: string;
    page?: number;
    pageSize?: number;
    searchFields?: SearchField[];
    sortFields?: SortField[];
    properties?: any;
}

export namespace DefaultFieldSet {
    export const LOOKUP: string = "lookup";
    export const DEFAULT: string = "";
}

export class DefaultQueryOption implements QueryOption {
    fieldSet?: string;
    page?: number = -1;
    pageSize?: number = -1;

    searchFields?: SearchField[] = [];
    sortFields?: SortField[] = [];

    properties?: any;

    constructor(option?: QueryOption) {
        if (!option) return;
        this.fieldSet = option.fieldSet;
        this.page = option.page;
        this.pageSize = option.pageSize;
        this.searchFields = option.searchFields || [];
        this.sortFields = option.sortFields || [];
        this.properties = option.properties || [];
    }

    public updatePage(page: number, pageSize?: number) {
        this.page = page;
        this.pageSize = pageSize || this.pageSize;
    }
}

export type SortDirection = "ASC" | "DESC" | "" | "asc" | "desc";
export interface DataSourceField {
    name: string;
}

export type SearchField = QuickSearchField | BinarySearchField | RangeSearchField;

export type BinaryOperator = ">" | ">=" | "=" | "<" | "<=" | "<>" | "isNull";
export type RangeOperator = "in" | "between";

export interface QuickSearchField extends DataSourceField {
    name: "@quicksearch";
    value?: any;
}

export interface BinarySearchField extends DataSourceField {
    operator: BinaryOperator;
    value?: any;
}

export interface RangeSearchField extends DataSourceField {
    operator: RangeOperator;
    lowerBound: any;
    upperBound: any;
}

export interface SortField extends DataSourceField {
    direction: SortDirection;
}
