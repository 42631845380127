import { NgModule, Provider, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { HttpModule } from "@angular/http";
import { CommonModule } from "@angular/common";
import {
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    MatSortModule,
    MatMenuModule
} from "@angular/material";
import { CoreModule } from "../core";
import { MaterialOvpListViewerComponent } from "./object-viewer-parts/list-viewer.component";
import { MaterialOvpActionBarComponent } from "./object-viewer-parts/action-bar.component";
import { ActionRendererComponent } from "./components/action-renderer.component";
import { HttpClientModule } from "@angular/common/http";
import { IconComponent } from "./object-viewer-parts/icon.component";
import { ObjectDiaryEventRenderer } from "./components/object-diary-event.renderer";
import { ObjectViewerModule } from "../object-explorer";
import { MultiFieldColumnRenderer } from "./column-renderers/multi-field-column.renderer";
import { HtmlColumnRenderer } from "./column-renderers/html-column.renderer";

const ENTRY_COMPONENTS: any[] = [
    ObjectDiaryEventRenderer,
    MultiFieldColumnRenderer,
    HtmlColumnRenderer,
    MaterialOvpListViewerComponent,
    MaterialOvpActionBarComponent
];

const EXPORTED_COMPONENTS: any[] = [...ENTRY_COMPONENTS, IconComponent, ActionRendererComponent];

const EXPORTED_PROVIDERS: Provider[] = [];

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        HttpModule,
        HttpClientModule,
        ObjectViewerModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatMenuModule,
        MatTooltipModule
    ],
    exports: [...EXPORTED_COMPONENTS],
    declarations: [...EXPORTED_COMPONENTS],
    providers: [...EXPORTED_PROVIDERS],
    entryComponents: [...ENTRY_COMPONENTS],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class MaterialComponentModule {}
