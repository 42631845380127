import { MatTableModule, MatInputModule, MatCheckboxModule, 
         MatIconModule, MatMenuModule, MatButtonModule, 
         MatDatepickerModule, MatNativeDateModule, 
         MatProgressSpinnerModule, 
         MatChipsModule,
         MatProgressBarModule,
         MatPaginatorModule,
         MatSortModule,
         MatTooltipModule,
         MatListModule,
         MatSelectModule} from '@angular/material';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { PieChartModule, GaugeModule, BarChartModule } from '@swimlane/ngx-charts';
import { MatFileUploadModule } from '../../../jupiter/components';
const MATERIAL_MODULES : any[] = [
    NgxMaterialTimepickerModule.forRoot(),
    MatFileUploadModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatTooltipModule
]

const CHART_MODULES: any[] = [
    PieChartModule,
    BarChartModule,
    GaugeModule
]

export const IMPORTED_MODULES = [
    ...MATERIAL_MODULES,
    ...CHART_MODULES
]