import { Input, ComponentFactoryResolver, Injector, Type, ViewContainerRef, Directive } from "@angular/core";
import { GlobalRegistryService } from "../../core";
import { PROPERTY_PAGE_ENTRY } from "../models";
import { ObjectViewerPartDirective } from "./object-viewer-part.directive";



@Directive({
    selector: '[object-property-page]'
})
export class ObjectPropertyPageDirective extends ObjectViewerPartDirective {
    public get componentTopic(): string { return PROPERTY_PAGE_ENTRY; }

    @Input('object-property-page') component: string | Type<any>;
    
    constructor(protected componentFactoryResolver: ComponentFactoryResolver,
        protected registry: GlobalRegistryService,
        public viewContainerRef: ViewContainerRef,
        protected injector: Injector) {
        
        super(componentFactoryResolver, registry, viewContainerRef, injector);
    }
}
