import { MessageService } from "./message.service";
import { ActionHandlingContext } from "../action/action-handler";
import { MessageSubscription } from "./message";
import { OnDestroy } from "@angular/core";

export class AbstractMessageHandler implements OnDestroy {
    public messageSubscriptions: MessageSubscription[] = [];

    ngOnDestroy() {
        this.messageSubscriptions.filter(s => s !== null && s !== undefined)
                                .forEach(s => s.unsubscribe());
    }
}