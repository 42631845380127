import { ObjectDescriptor } from "./object-descriptor";
import { ObjectViewerDescriptor } from "./object-viewer-descriptor";
import { ObjectFeature } from "./object-feature";
import { DisplayMode } from "../../core";

export interface DisplayColumns {
    compactMode?: string[];
    fullMode?: string[];
}

export class ObjectExploringDescriptor extends ObjectViewerDescriptor {
    private _tableSettings: ObjectDescriptor;
    private _title: string;
    private _columns: string[] | DisplayColumns;

    /** Get a list of visible columns. */
    public get columns(): string[] {
        if (!this._columns) {
            this._columns = {
                compactMode: this.tableSettings.features
                    .filter(feature => DisplayMode.isVisible(feature.displayMode, "compact"))
                    .map(feature => feature.name),
                fullMode: this.tableSettings.features
                    .filter(feature => DisplayMode.isVisible(feature.displayMode, "full"))
                    .map(feature => feature.name)
            };
        }
        if (Array.isArray(this._columns)) return this.columns;

        return (DisplayMode.isCompactMode() ? this._columns.compactMode : this._columns.fullMode) || [];
    }
    public set columns(value: string[]) {
        this.columns = value;
    }
    public icon: string;
    public get title(): string {
        return this._title || this.name;
    }
    public hideSelector: boolean = true;
    public get tableSettings(): ObjectDescriptor {
        return this._tableSettings;
    }

    constructor(data: any, name?: string) {
        super(data, name);
        this.hideSelector = data.hideSelector || true;
        this._title = data.title;
        this.icon = data.icon;
        this._tableSettings = new ObjectDescriptor(data.tableSettings || data["settings"]);
    }
}
