import { data_formatter } from "./data-formatter-decorators.utils";

@data_formatter("boolean")
export class BooleanFormatter {
    private readonly DEFAULT_TRUE_LABEL: string = "YES";
    private readonly DEFAULT_FALSE_LABEL: string = "NO";

    format(value: any, options?: any): string {
        if (!options)
            options = {
                trueLabel: this.DEFAULT_TRUE_LABEL,
                falseLabel: this.DEFAULT_FALSE_LABEL
            };
        return value ? options.trueLabel || this.DEFAULT_TRUE_LABEL : options.falseLabel || this.DEFAULT_FALSE_LABEL;
    }
}
