import { Component, forwardRef, Input, Optional, Inject, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { AbstractFormField } from "../abstract-form-field";
import { CurrencyPipe, DecimalPipe } from "@angular/common";

@Component({
    selector: "currency-field",
    template: `
    <mat-form-field [class.mat-form-field-invalid] = 'invalid | async'>
        <mat-label *ngIf='label'> {{label}} </mat-label>
        <input matInput [(ngModel)]='value' [placeholder]='placeholder'>
        <span matPrefix> 
            <ng-content select='[prefix]' ></ng-content>
        </span>
        <span matSuffix> 
            <ng-content select='[suffix]' ></ng-content>
        </span>
        <mat-hint> 
            <ng-content select='hint' ></ng-content>
        </mat-hint>
    </mat-form-field>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyFieldComponent),
            multi: true
        }
    ]
})
export class CurrencyFieldComponent extends AbstractFormField implements OnInit {
    @Input()
    code: string = "USD";
    @Input()
    symbol: string = "$";
    @Input()
    display: boolean | string = "symbol";
    @Input()
    digitInfo: string = "1.0-0";
    @Input()
    locale: string = "en";
    @Input()
    type: string = "currency";

    private currenyPipe: CurrencyPipe;
    private decimalPipe: DecimalPipe;

    constructor(
        @Optional()
        @Inject(NG_VALIDATORS)
        validators: Array<any>,
        @Optional()
        @Inject(NG_ASYNC_VALIDATORS)
        asyncValidators: Array<any>
    ) {
        super(validators, asyncValidators);
    }

    ngOnInit() {
        if (this.type === "currency") this.currenyPipe = new CurrencyPipe(this.locale);
        else this.decimalPipe = new DecimalPipe(this.locale);
    }

    protected getValue(): any {
        if (this.type === "currency")
            return this.currenyPipe.transform(super.getValue(), this.code, this.display, "1.0-0");
        return this.decimalPipe.transform(super.getValue(), "1.0-0");
    }

    protected setValue(value: any) {
        if (typeof value !== "string") {
            super.setValue(value);
            return;
        }
        value = value
            .replace(this.code, "")
            .replace(this.symbol, "")
            .replace(/,/g, m => "");
        super.setValue(Number(value) || undefined);
    }
}
