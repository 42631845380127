import { AuthorizeRequest } from "./access-control.service";
import { Injectable } from "@angular/core";
import { StringUtil } from "../utils/string-util";

export type AuthenticationType = 'password' | 'secret-key' | 'username';

export interface AuthenticationRequest {
    type: AuthenticationType;
    username?: string;
    password?: string;
    apiKey?: string;
    apiSecreteKey?: string;
}

const AUTH_CONTEXT_ITEM: string = 'auth-context';

@Injectable()
export class AuthenticationContext {
    /** token type */
    type?: string;
    /** access token */
    token?: string;
    /** token's expired time in seconds */
    expires?: number; 
    timestamp?: number;
    principle?: IPrinciple;
    request?: AuthenticationRequest;
    claims: Claim[];

    public get authenticated(): boolean {
        return !StringUtil.isBlank(this.token) 
                && this.ellapsedSecond < this.expires;
    }

    get ellapsedSecond(): number {
        let d = new Date();
        return (d.valueOf() - this.timestamp) / 1000;
    }

    constructor() {
        this.loadAuthenticationContext();
    }

    private loadAuthenticationContext() {
        let value : string = window.localStorage.getItem(AUTH_CONTEXT_ITEM);
        if (value) {
            let obj = JSON.parse(value);
            Object.assign(this, obj);
        }
    }

    public updateContext() {
        window.localStorage.setItem(AUTH_CONTEXT_ITEM, JSON.stringify(this));
    }

    public clear(): void {
        this.type = undefined;
        this.token = undefined;
        this.principle = undefined;
        this.request = undefined;
        this.claims = undefined;
        window.localStorage.removeItem(AUTH_CONTEXT_ITEM);
    }
}

export interface IPrinciple {
    objectId?: string;
    name?: string;
    caption?: string;
}

/** Represent an authorization condition */
export class ClaimRequest {
    name: string;
    value: string;
}

/** Represent a contextual information about the granted permission */
export class Claim {
    type: string;
    value: string;
}

export interface Authorizable {
    request: AuthorizeRequest;
}

export interface ChangePasswordModel {
    userId?: string;
    currentPassword: string;
    newPassword: string;
}