import { Component, ViewChild, Input, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { ObjectPropertyDescriptor, StandardActionSet, ObjectPropertyHost } from "../models";
import {
    DataContext,
    EditingDataContext,
    DataSourceProvider,
    ActionProvider,
    Action,
    DefaultActionSet,
    AccessControlService,
    ActionHandlingContext,
    MessageService,
    ActionMessageChannel
} from "../../core";
import { ObjectPropertyDescriptorProvider } from "../provider/object-property-descriptor.provider";
import { evalField } from "../../core";
import { PropertyPageDescriptor } from "../models/property-page-descriptor";
import { AbstractObjectViewerHost, StandardStatus } from "./abstract-object-viewer-host";
import { NgForm } from "@angular/forms";

export abstract class AbstractObjectPropertyHost extends AbstractObjectViewerHost<ObjectPropertyDescriptor>
    implements ObjectPropertyHost<ObjectPropertyDescriptor> {
    subtitle?: string;

    protected _item: any;
    private _actions: Action[];
    private _pages: PropertyPageDescriptor[];

    public isNewItem: boolean;
    onEditingItemChanged: EventEmitter<any> = new EventEmitter();

    protected abstract readonly formControl: NgForm;
    public abstract readonly isDirty: boolean;
    public abstract readonly isValid: boolean;

    public get title(): string {
        if (!this.manifest) return undefined;
        if (!this.manifest.caption) return this.manifest.name;
        if (this.isNewItem) return this.manifest.caption.new;
        return evalField(this.manifest.caption.edit, this);
    }

    public get editingItem(): any {
        return this._item;
    }

    public set editingItem(value: any) {
        this._item = value;
        this.onEditingItemChanged.emit(value);
    }

    public get pages(): PropertyPageDescriptor[] {
        if (!this._pages) this._pages = this.manifest.pages.map(page => new PropertyPageDescriptor(page, null, this));
        return this._pages.filter(x => x.visible);
    }

    public get ready(): boolean {
        return this.editingItem;
    }

    @Input("manifest") manifestName: string = undefined;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected propertyDescriptor: ObjectPropertyDescriptorProvider,
        protected actionProvider: ActionProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(activatedRoute, actionProvider, accessControl, messageService, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected createActionHandlingContext() {
        let context = super.createActionHandlingContext();
        context.extra = {
            parentManifest: this.routeSnapshot.params["parentManifest"],
            parentObjectId: this.routeSnapshot.params["parentId"]
        };
        return context;
    }

    protected updateManifest(value: string) {
        if (value === this.manifestName && this.manifest) return;
        this.manifestName = value;
        if (this.manifestName) {
            let manifest = this.propertyDescriptor.getDescriptor(this.manifestName);
            if (!manifest) {
                console.error(`Undefined object property manifest: ${this.manifestName}`);
                return;
            }
            this.manifest = manifest;
        }
    }

    protected manifestChanged(): void {
        super.manifestChanged();
        this._actions = undefined;
        this._pages = undefined;
        this.editingItem = undefined;
        let datasource = this.dataSourceProvider.createDataSource<any>(this.manifest.dataSource);
        if (!datasource) {
            console.error(`Undefined data source: ${this.manifest.dataSource}`);
        }
        this.context = new EditingDataContext<any>(datasource);
    }

    public getActions(name: string): Action[] {
        if (!this.manifest) return undefined;
        switch (name) {
            case StandardActionSet.DefaultActions:
            case StandardActionSet.PropertyActions:
                this._actions = this.buildActions(
                    this._actions,
                    this.manifest.actions,
                    DefaultActionSet.DEFAULT_PROPERTY_ACTION_SET
                );
                return this._actions;
            default:
                return undefined;
        }
    }

    public hasStatus(status: string): boolean {
        if (!this.formControl) return super.hasStatus(status);

        switch (status) {
            case StandardStatus.Dirty:
                return this.formControl.dirty;
            case StandardStatus.Invalid:
                return this.formControl.invalid;
            case StandardStatus.Pristine:
                return this.formControl.pristine;
            case StandardStatus.Valid:
                return this.formControl.valid;
            case StandardStatus.New:
                return this.isNewItem;
            case StandardStatus.Child:
                return this.routeSnapshot.params["parentId"] ? true : false;
            default:
                return super.hasStatus(status);
        }
    }

    protected handlePostAction(context: ActionHandlingContext) {
        super.handlePostAction(context);
        switch (context.action.name) {
            case "save":
                if (!this.isNewItem) {
                    this.resetState(context);
                    return;
                }
                var options = context.action.options;

                let url: string = options ? options.redirectOnSaveNew : undefined;
                if (!url) {
                    this.resetState(context);
                    return;
                }
                url = evalField(url, context);
                this.router.navigateByUrl(url);
                break;
        }
    }

    protected resetState(context: ActionHandlingContext) {
        this.editingItem = this.context.active;
        let value = this.formControl.value;
        this.formControl.reset(value);
    }
}
