module.exports = {
	"definitions": {
		"explorerForms": {
			"activityType": {
				"title": "Activity types",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"organizationType": {
				"title": "Organization types",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"organizationStatus": {
				"title": "Organization status",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"territory": {
				"title": "Territories",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"country": {
				"title": "Countries",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"province": {
				"title": "Provinces",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"saltuation": {
				"title": "Saltuations",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"leadSource": {
				"title": "Lead source",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"influenceLevel": {
				"title": "Influence levels",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"contactMethod": {
				"title": "Contact methods",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"currency": {
				"title": "Currencies",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"businessLine": {
				"title": "Business lines",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"supplyScope": {
				"title": "Scopes of supply",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"segment": {
				"title": "Segments",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"opportunityStage": {
				"title": "Opportunity stages",
				"settings": {
					"features": {
						"name": null,
						"metaValue": {
							"caption": "Completion Level"
						},
						"description": "metaDescription",
						"createdDate": {
							"$ref": "#/definitions/shared/createdDate"
						}
					}
				}
			},
			"opportunityWorkflowStage": {
				"title": "Opportunity Worflow Stage",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"projectStage": {
				"title": "Project stages",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"incoterm": {
				"title": "Incoterms",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"winReason": {
				"title": "Win reasons",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			},
			"lostReason": {
				"title": "Lost reasons",
				"settings": {
					"$ref": "#/definitions/default/directoryFormSettings"
				}
			}
		},
		"default": {
			"directoryFormSettings": {
				"features": {
					"name": {
						"caption": "Name"
					},
					"description": {
						"field": "metaDescription",
						"caption": "Description"
					},
					"createdDate": {
						"$ref": "view/definitions/shared/createdDate"
					}
				}
			}
		}
	}
};