import { Injectable } from "@angular/core";
import * as Rx from "rxjs/rx";
import { Http, Response, Headers, ResponseContentType } from "@angular/http";
import { AuthenticationContext } from "../access-control";

declare var __DEFAULT_API_DOMAIN__: string;

export type ApiMethod = "get" | "put" | "post";

@Injectable({
    providedIn: "root"
})
export class ApiService {
    static readonly DEFAULT_CONTEXT: string = "application/json; charshet=utf-8";
    static readonly URL_ENCORED_CONTEXT: string = "application/x-www-form-urlencoded";

    public constructor(public http: Http, private context: AuthenticationContext) {
        console.log("Create ApiService...");
    }

    /**
     * Invoke a Web API
     * @argument method the http verb, should be either 'get', 'put', or 'post'.
     * @argument url the Api url. If the Url starts with '/', it will be prefixed with the default domain.
     */
    public invokeApi(
        method: ApiMethod,
        url: string,
        payload?: any,
        secure?: boolean,
        header?: any,
        responseType: ResponseContentType = ResponseContentType.Json
    ): Rx.Observable<Response> {
        if (!header) {
            header = new Headers();
            header.append(
                "Context-Type",
                payload != null ? ApiService.DEFAULT_CONTEXT : ApiService.URL_ENCORED_CONTEXT
            );
        }
        if (secure && this.context.authenticated) {
            header.append("Authorization", "Bearer " + this.context.token);
        }
        if (url.startsWith("/")) url = __DEFAULT_API_DOMAIN__ + url;
        return this.http.request(url, {
            method: method,
            headers: header,
            responseType: responseType,
            body: payload
        });
    }

    public get(
        url: string,
        payload?: any,
        secure: boolean = false,
        responseType: ResponseContentType = ResponseContentType.Json
    ) {
        return this.invokeApi("get", url, payload, secure, null, responseType);
    }

    public put(
        url: string,
        payload?: any,
        secure: boolean = false,
        responseType: ResponseContentType = ResponseContentType.Json
    ) {
        return this.invokeApi("put", url, payload, secure, null, responseType);
    }

    public post(
        url: string,
        payload?: any,
        secure: boolean = false,
        responseType: ResponseContentType = ResponseContentType.Json
    ) {
        return this.invokeApi("post", url, payload, secure, null, responseType);
    }
}
