import { DataFormatter, data_formatter } from "./data-formatter-decorators.utils";
import { DatePipe } from "@angular/common";

@data_formatter("datetime")
export class DateTimeFormatter implements DataFormatter {
    private static DEFAULT_DATETIME_FORMAT: string = "yyyy-MM-dd";

    formatter: DatePipe = new DatePipe("en-VN");
    format(value: any, options?: any): string {
        let format = options
            ? options.format || DateTimeFormatter.DEFAULT_DATETIME_FORMAT
            : DateTimeFormatter.DEFAULT_DATETIME_FORMAT;
        return this.formatter.transform(value, format);
    }
}
