export class DataSourceDeclaration {
    type?: string;
    data_url?: string;
    api?: string;

    constructor(data?: any) {
        if (!data)
            return;
        if (typeof data == 'string') {
            this.api = <string> data;
            return;
        }
        this.type = data.type;
        this.data_url = data.data_url;
        this.api = data.api;
    }
}