import { GlobalRegistryService, DataContext } from "../../core";

export const OBJECT_VIEWER_PART_ENTRY = 'object.viewer.part';
export const PROPERTY_PAGE_ENTRY = OBJECT_VIEWER_PART_ENTRY + '/property.pages';
export const TABLE_COLUMN_RENDERER = 'column.renderer';

export function property_page(name: string) {
    return function(target: any) {
        GlobalRegistryService.instance.register(PROPERTY_PAGE_ENTRY, name, target);
        return target;
    }
}

export function object_viewer_part(name: string) {
    return function(target: any) {
        GlobalRegistryService.instance.register(OBJECT_VIEWER_PART_ENTRY, name, target);
        return target;
    }
}

export function column_renderer(name: string) {
    return function(target: any) {
        GlobalRegistryService.instance.register(TABLE_COLUMN_RENDERER, name, target);
        return target;
    }
}
