import { Action, ActionMessageChannel } from "./action";
import { ObjectExploringDescriptor } from "../../object-explorer/models/object-exploring-descriptor";
import { DataContext } from "../data-source/data-context";
import { ObjectPropertyDescriptor, ObjectViewerDescriptor } from "../../object-explorer";
import { AbstractMessageHandler } from "../message/message-handler";
import { MessageService } from "../message/message.service";
import { MessageSubscription } from "../message/message";
import { NavigationExtras } from "@angular/router";

export interface ActionExecuteHandler {
    handleActionExecute(context: ActionHandlingContext): void;
}

export interface ActionExecutingHandler {
    handleActionExecuting(context: ActionHandlingContext): void;
}

export interface ActionExecutedHandler {
    handleActionExecuted(context: ActionHandlingContext): void;
}

export interface ActionPreparingContextHandler {
    handleActionPreparingContext(context: ActionHandlingContext): void;
}

export abstract class ActionHandlingContext {
    /** The relevant data object */
    element?: any;
    activeElement?: any;
    action?: Action;
    manifest?: ObjectViewerDescriptor;
    dataContext?: DataContext<any>;
    status: ActionHandlingStatus = ActionHandlingStatus.Unhandled;
    extra?: any;
    navigationExtras?: NavigationExtras;
    result?: any;
}

export enum ActionHandlingStatus {
    Unhandled,
    Completed,
    Canceled
}

export class DefaultActionHandlingContext extends ActionHandlingContext {
    public get activeElement(): any {
        return this.dataContext ? this.dataContext.active : undefined;
    }
    constructor(data?: ActionHandlingContext) {
        super();
        this.mergeProperties(data);
    }

    public mergeProperties(data: ActionHandlingContext): ActionHandlingContext {
        if (!data) return this;
        this.element = data.element || this.element;
        this.manifest = data.manifest || this.manifest;
        this.action = data.action || this.action;
        this.dataContext = data.dataContext || this.dataContext;
        this.extra = data.extra || this.extra;

        return this;
    }
}
