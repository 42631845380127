import { Observable, BehaviorSubject, Subject, ReplaySubject } from "rxjs";
import { QueryOption, DefaultQueryOption } from "./query-option";
import { Api } from "../api/api";
import { DataSourceDeclaration } from "./data-source-descriptor";
import { ApiAdvisorProvider } from "../api/api-advisor.provider";
import { ApiAdvisor } from "../api/api-adivsor";
import { Injector } from "@angular/core";
import { DataSource as CdkDataSource, CollectionViewer } from "@angular/cdk/collections";
import { CRUD } from "../api/crud-api-advisor";

/** Represent an asynchronous data source  */
export abstract class DataSource<T> extends CdkDataSource<T> {
    /** Get the data of the data source */
    data: T[];
    /** Get the option passing to the remote API while fetching data */
    option: DefaultQueryOption = new DefaultQueryOption();
    status: DataSourceStatus;

    public get count(): number {
        return this.data == undefined ? -1 : this.data.length;
    }

    public get pageCount(): number {
        if (this.count <= 0) return 0;
        if (this.option.pageSize <= 0) return 1;
        return Math.ceil(this.count / this.option.pageSize);
    }

    public get ready(): boolean {
        return this.status == DataSourceStatus.Ready;
    }

    /** Fetch data from data source */
    public abstract fetchData(name: string, args?: any, payload?: any): Observable<T[]>;
    public abstract executeApi(name: string, args?: any, payload?: any): Observable<any>;

    /** Count the number of data items */
    public abstract countData(): Observable<number>;
    public abstract getApi(name: string): Api<T>;

    public list(option?: QueryOption): Observable<T[]> {
        if (option) this.option = new DefaultQueryOption(option);
        this.countData();
        return this.fetchData(CRUD.List, null, this.option);
    }

    public get(id: any): Observable<any> {
        return this.fetchData(CRUD.Get, { id: id });
    }

    public update(item: any): Observable<any> {
        return this.fetchData(CRUD.Save, null, item);
    }

    public delete(id: any): Observable<any> {
        return this.executeApi(CRUD.Delete, { id: id });
    }

    public newItem(): Observable<any> {
        return this.fetchData(CRUD.New);
    }
}

export enum DataSourceStatus {
    UnInitialized,
    Fetching,
    Ready,
    Error
}

export abstract class DeclarativeDataSource<T> extends DataSource<T> {
    private _status: DataSourceStatus = DataSourceStatus.UnInitialized;
    protected apiProvider: ApiAdvisorProvider;

    protected descriptor: DataSourceDeclaration;
    protected apiAdvisor: ApiAdvisor;
    protected dataSubject: Subject<T[]> = new BehaviorSubject<T[]>(this.data);

    public get status(): DataSourceStatus {
        return this._status;
    }

    constructor(descriptor: DataSourceDeclaration, protected injector: Injector) {
        super();
        this.descriptor = descriptor;
        this.apiProvider = this.injector.get(ApiAdvisorProvider);
        this.apiAdvisor = this.apiProvider.get(descriptor.api);
    }

    public getApi<T>(name: string): Api<T> {
        if (!this.apiAdvisor) return undefined;
        return this.apiAdvisor.getApi<T>(name);
    }

    public connect(viewer?: CollectionViewer): Observable<T[]> {
        return this.dataSubject;
    }

    public disconnect(viewer?: CollectionViewer): void {}

    public countData(): Observable<number> {
        return Observable.of(this.count);
    }

    public executeApi(name: string, args?: any, payload?: any): Observable<any> {
        var api = this.getApi(name);
        if (!api) {
            console.error(`Api not found: ${name}`);
            return;
        }
        return api.invoke({ args: args, payload: payload });
    }

    public fetchData(name: string, args?: any, payload?: any): Observable<any> {
        var api = this.getApi(name);
        if (!api) return;
        this.setStatus(DataSourceStatus.Fetching);
        let subject = new Subject<any>();
        api.invoke({ args: args, payload: payload }).subscribe({
            next: item => this.onNext(subject, item),
            error: err => this.onError(subject, err),
            complete: () => this.onComplete(subject)
        });
        return subject;
    }

    protected setStatus(value: DataSourceStatus) {
        this._status = value;
    }

    protected onError(subject: Subject<any>, err: any) {
        this.setStatus(DataSourceStatus.Error);
        subject.error(err);
        console.log(err);
    }

    protected onNext(subject: Subject<any>, data: any) {
        this.setStatus(DataSourceStatus.Ready);
        this.data = Array.isArray(data) ? data : [data];
        this.pushData();
        subject.next(data);
    }

    protected pushData() {
        this.dataSubject.next(this.data);
    }

    protected onComplete(subject: Subject<any>): void {
        subject.complete();
    }
}
