import { Type, Input, ComponentFactoryResolver, ViewContainerRef, Injector, Directive } from "@angular/core";
import { AbstractComponentLoader, GlobalRegistryService } from "../../../../../jupiter/components";
import { WIDGET } from "./widget-decorator";
import { WidgetContainerComponent } from "../widget.component";
import { AbstractWidgetContent, WidgetContent } from "./abstract-widget-content";

@Directive({
    selector: '[widget-content]'
})
export class WidgetContentDirective extends AbstractComponentLoader {
    public componentTopic: string = WIDGET;    
    @Input('widget-content') public component: string | Type<any>;
    @Input('host') host: WidgetContainerComponent;

    constructor(protected componentFactoryResolver: ComponentFactoryResolver,
        protected registry: GlobalRegistryService,
        public viewContainerRef: ViewContainerRef,
        protected injector: Injector) {
        
        super(componentFactoryResolver, registry, viewContainerRef, injector);
    }

    protected initializeComponent(component: any): void {
        let widgetContent = <WidgetContent>component;
        widgetContent.widgetContainer = this.host;
    }


}