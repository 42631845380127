module.exports = {
	"actions": {
		"templates": {
			"edit": {
				"icon": "create",
				"caption": "Edit",
				"hint": "Display the property of this row",
				"type": "link",
				"url": "/{manifest.name}/property/{element.objectId}",
				"visibleWhen": {
					"security": [
						"role:administrators",
						"{manifest.secureToken}:edit"
					]
				}
			},
			"new": {
				"icon": "add",
				"caption": "New",
				"type": "link",
				"url": "/{manifest.name}/new",
				"visibleWhen": {
					"security": [
						"role:administrators",
						"{manifest.secureToken}:edit"
					]
				},
				"options": {
					"class": "default"
				}
			},
			"clone": {
				"icon": "library_add",
				"caption": "Clone",
				"type": "event",
				"hint": "Clone this opportunity",
				"visibleWhen": {
					"status": [
						"!new"
					],
					"security": [
						"role:administrators",
						"{manifest.secureToken}:edit"
					]
				}
			},
			"resetPassword": {
				"icon": "resetkey",
				"caption": "Reset Password",
				"type": "event",
				"visibleWhen": {
					"status": [
						"!new"
					],
					"security": [
						"role:administrators",
						"role:system"
					]
				}
			},
			"delete": {
				"icon": "delete",
				"caption": "Delete",
				"type": "event",
				"visibleWhen": {
					"security": [
						"role:administrators",
						"{manifest.secureToken}:delete"
					]
				}
			},
			"deleteSelection": {
				"icon": "delete",
				"caption": "Delete selection",
				"type": "event",
				"visibleWhen": {
					"security": [
						"role:administrators",
						"{manifest.secureToken}:delete"
					]
				}
			},
			"save": {
				"icon": "save",
				"caption": "Save",
				"type": "event",
				"enabledWhen": {
					"status": [
						"dirty",
						"valid"
					],
					"security": [
						"role:administrators",
						"{manifest.secureToken}:edit"
					]
				},
				"options": {
					"class": "primary",
					"redirectOnSaveNew": "/{manifest.name}/property/{activeElement.objectId}"
				}
			},
			"close": {
				"icon": "close",
				"caption": "Close",
				"type": "event"
			}
		},
		"defaults": {
			"explorer": [
				"new",
				"close"
			],
			"explorer.grid": [
				"edit",
				"delete"
			],
			"property": [
				"new",
				"save",
				"close"
			]
		}
	}
};