import { ControlValueAccessor, Validator, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from "@angular/forms";
import { Observable } from "rxjs";
import { ValidationResult, validate, message } from "./validate";
import { map } from "rxjs/operators";

export abstract class AbstractNgCustomComponent implements ControlValueAccessor, Validator {
    private _value: any;
    protected abstract model: NgModel;

    /**
     * Invoked when the model has been changed
     */
    onChanged = new Array<(_: any) => void>();

    /**
     * Invoked when the model has been touched
     */
    onTouched = new Array<() => void>();

    public get value(): any {
        return this.getValue();
    }

    public set value(v: any) {
        this.setValue(v);
    }

    constructor(protected validators: Array<any>, protected asyncValidators: Array<any>) {}

    validate(): Observable<ValidationResult> {
        return validate(this.validators, this.asyncValidators)(this.model.control);
    }

    protected getValue(): any {
        return this._value;
    }

    protected setValue(v: any) {
        if (this._value !== v) {
            this._value = v;
            this.onChanged.forEach(f => f(v));
        }
    }

    protected get invalid(): Observable<boolean> {
        let result = this.validate();
        return result.pipe(map(v => Object.keys(v || {}).length > 0));
    }

    protected get failures(): Observable<Array<string>> {
        return this.validate().pipe(map(v => Object.keys(v).map(k => message(v, k))));
    }

    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChanged.push(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched.push(fn);
    }

    registerOnValidatorChange?(fn: () => void): void {
        // do nothing
    }

    setDisabledState?(isDisabled: boolean): void {
        // do nothing now
    }
}
