module.exports = {
	"icons": {
		"dashboard": {
			"icon": "dashboard"
		},
		"organization": {
			"icon": "business"
		},
		"person": {
			"icon": "person",
			"type": "svg"
		},
		"opportunity": {
			"icon": "opportunity",
			"type": "svg"
		},
		"activity": {
			"icon": "assignment"
		},
		"directory": null,
		"setting": {
			"icon": "settings"
		},
		"appuser": {
			"icon": "account_circle"
		},
		"approle": {
			"icon": "supervised_user_circle"
		},
		"new_opportunity": {
			"icon": "add"
		},
		"new_organization": {
			"icon": "add"
		},
		"new_activity": {
			"icon": "add"
		},
		"new_person": {
			"icon": "add"
		},
		"contactmethod": {
			"icon": "phone-settings",
			"type": "svg"
		},
		"logout": {
			"icon": "logout",
			"type": "svg"
		},
		"changepassword": {
			"icon": "key-change",
			"type": "svg"
		},
		"winreason": {
			"icon": "thumb_up"
		},
		"lostreason": {
			"icon": "thumb_down"
		},
		"incoterm": {
			"icon": "incoterm",
			"type": "svg"
		},
		"businessline": {
			"icon": "business-line",
			"type": "svg"
		},
		"territory": {
			"icon": "terrain"
		},
		"province": {
			"icon": "location_city"
		},
		"country": {
			"icon": "public"
		},
		"project": {
			"icon": "score"
		},
		"new_project": {
			"icon": "add"
		},
		"currency": {
			"icon": "monetization_on"
		},
		"opportunityworkflowstage": {
			"icon": "priority",
			"type": "svg"
		},
		"opportunitystage": {
			"icon": "priority",
			"type": "svg"
		},
		"segment": {
			"icon": "segment",
			"type": "svg"
		},
		"organizationtype": {
			"icon": "organization-type",
			"type": "svg"
		},
		"organizationstatus": {
			"icon": "ballot"
		},
		"leadsource": {
			"icon": "accessibility"
		},
		"claim": {
			"icon": "verified_user"
		},
		"switchuser": {
			"icon": "switch-user",
			"type": "svg"
		},
		"resetkey": {
			"icon": "resetkey",
			"type": "svg"
		},
		"profile": {
			"icon": "user-profile",
			"type": "svg"
		},
		"report": {
			"icon": "report",
			"type": "svg"
		}
	},
	"svgIcons": [
		"business-line",
		"person",
		"key-change",
		"incoterm",
		"opportunity",
		"organization-type",
		"logout",
		"phone-settings",
		"priority",
		"segment",
		"switch-user",
		"resetkey",
		"user-profile",
		"report"
	]
};