import { Component } from "@angular/core";

import { AbstractColumnRenderer, ObjectFeature } from "../../object-explorer";
import { column_renderer } from "../../object-explorer";
import { evalField } from "../../core";

@Component({
    selector: "multi-field-column",
    template: `
    <div class='flex multi-field-column-container'>
        <ng-container *ngFor='let template of templates;' >
            <span *ngIf='template.value && template.value.length > 0' 
                    [ngClass]='template.cssClass' #item> {{renderValue(item, template)}} </span>
        </ng-container>
    </div>
    `
})
@column_renderer("MultiFieldColumn")
export class MultiFieldColumnRenderer extends AbstractColumnRenderer {
    private regExp = /(`([\w-]*)`)?\s?(.+?)$/;
    templates: Template[] = [];

    /** template format: `css-class` {field} | `css-class1` {field} */

    initialize(): void {
        if (!this.feature) return;
        this.parseFeatureTemplate(this.feature);
    }

    parseFeatureTemplate(feature: ObjectFeature) {
        if (!feature.template) return;
        feature.template.split("|").forEach(x => this.parseTemplate(x));
    }

    parseTemplate(pattern: string) {
        pattern = pattern.trim();
        let result = this.regExp.exec(pattern);
        this.templates.push({ cssClass: result[2], value: evalField(result[3], this.element) });
    }

    renderValue(item: any, template: Template) {
        item.innerHTML = template.value;
    }
}

interface Template {
    cssClass?: string;
    value?: string;
}
