import { NgModule, Provider, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { HttpModule } from "@angular/http";
import { CommonModule } from "@angular/common";
import { MatCheckboxModule, MatTableModule, MatPaginatorModule, 
        MatButtonModule, MatTooltipModule, MatIconModule, MatTabsModule } from "@angular/material";
import { CoreModule } from "../core";
import { ObjectExplorerHost } from "./components/object-explorer.host";
import { ObjectExploringDescriptorProvider } from "./provider/object-exploring-descriptor.provider";
import { ObjectPropertyForm } from "./components/object-property.form";
import { ObjectPropertyPageDirective } from "./components/object-property-page.directive";
import { ObjectPropertyDescriptorProvider } from "./provider/object-property-descriptor.provider";
import { ObjectViewerPartDirective } from "./components/object-viewer-part.directive";
import { FormsModule } from "@angular/forms";
import { ImportParentFormDirective } from "./components/import-parent-form.directive";
import { ColumnRendererDirective } from "./components";

const FORM_COMPONENTS: any[] = [
   ObjectExplorerHost,
   ObjectPropertyForm,
   ObjectPropertyPageDirective,
   ObjectViewerPartDirective,
   ImportParentFormDirective,
   ColumnRendererDirective
]

const FORM_PROVIDERS: Provider[] = [
    { provide: ObjectExploringDescriptorProvider, useClass: ObjectExploringDescriptorProvider },
    { provide: ObjectPropertyDescriptorProvider, useClass: ObjectPropertyDescriptorProvider }
]

@NgModule({
    imports: [ 
        CommonModule,
        CoreModule,
        FormsModule,
        HttpModule, 
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatTabsModule,
        MatCheckboxModule  ],
    exports: [ ...FORM_COMPONENTS ],
    declarations: [ ...FORM_COMPONENTS ],
    providers: [ 
        ... FORM_PROVIDERS
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class ObjectViewerModule {

}
