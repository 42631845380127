import { Component, forwardRef, ViewChildren } from "@angular/core";
import { DASHBOARD_TOKEN } from "./export";
import { MessageService, Authorizable, AccessControlService } from "../../../../jupiter/components";
import { WidgetDescription } from "./widget.component";
import { max } from "rxjs-compat/operator/max";
import { ActivatedRoute, Params } from "@angular/router";

let _DASHBOARD_ = require("../settings/dashboard.yml");

@Component({
    selector: "dashboard",
    template: require("./dashboard.component.pug")
    // providers:[
    //     { provide: DASHBOARD_TOKEN, useExisting: forwardRef(() => DashboardComponent) }
    // ]
})
export class DashboardComponent {
    widgets: WidgetDefinition[] = _DASHBOARD_.widgets;
    option: DashboardOption = {};
    dashboardType: "default" | "sales" = "default";

    constructor(
        private messageService: MessageService,
        private routedService: ActivatedRoute,
        private auth: AccessControlService
    ) {
        this.routedService.params.subscribe({
            next: params => this.processParams(params)
        });
    }

    get userName(): string {
        return this.auth.context.principle.caption;
    }

    get isSalesDashboard(): boolean {
        return this.dashboardType === "sales";
    }

    get boardWidgets(): WidgetDefinition[] {
        return this.widgets.filter(widget => widget.boards.indexOf(this.dashboardType) >= 0);
    }

    get columns(): number[] {
        if (window.innerWidth < 700) return [0];
        else if (this.isSalesDashboard || window.innerWidth < 1200) return [0];
        return [0, 1, 2];
    }

    getColumnIndex(w: WidgetDescription, index: number) {
        let value = w.column || index % 3;
        return Math.min(value, this.columns.length - 1);
    }

    refreshWidgets() {
        this.messageService.send({ channel: "widget", topic: "refresh", sender: this });
    }

    private processParams(params: Params) {
        this.dashboardType = params["type"];
        if (!this.dashboardType) {
            let isSalePerson = this.auth.context.claims.findIndex(
                x => x.type === "role" && x.value.indexOf("Sales") >= 0
            );
            this.dashboardType = isSalePerson ? "sales" : "default";
        }
        this.widgets = (<Authorizable[]>_DASHBOARD_.widgets).filter(w => this.auth.authorize(w));
    }
}

export interface DashboardOption {
    territory?: string;
}

export interface WidgetDefinition extends Authorizable {
    boards?: string[];
}
