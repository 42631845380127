import { Component, forwardRef, Input, Optional, Inject } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { AbstractFormField } from "../abstract-form-field";

@Component({
    selector: 'input-autocomplete',
    template: require('./input-autocomplete.component.pug'),
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputAutocompleteComponent),
        multi: true
    }
]
})
export class InputAutocompleteComponent extends AbstractFormField {
    @Input()
    public  items: string[]= [];

    filteredOptions: string[];
    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
        super(validators, asyncValidators);
     }

    filter(event: any): void {
        this.filteredOptions = this._filter(event.target.value);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.items.filter(option => option.toLowerCase().includes(filterValue));
    }
}