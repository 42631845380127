import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatInputModule, MatDatepickerModule, MatChipsModule, MatAutocompleteModule,MatOptionModule } from "@angular/material";
import { InputFieldComponent } from "./text-fields/input-field.component";
import { InputAutocompleteComponent } from "./text-fields/input-autocomplete.component";
import { FormsModule } from "@angular/forms";
import { TextFieldComponent } from "./text-fields/text-field.component";
import { DateFieldComponent } from "./text-fields/date-field.component";
import { ChipOptionEditComponent } from "./text-fields/chip-option-edit.component";
import { CurrencyFieldComponent } from "./text-fields/currency-field.component";
import { CurrencyInputDirective } from "./text-fields/currency-input.directive";

const ENTRY_COMPONENTS: any[] = [];

const EXPORTED_COMPONENTS: any[] = [
    InputFieldComponent,
    InputAutocompleteComponent,
    TextFieldComponent,
    DateFieldComponent,
    CurrencyFieldComponent,
    ChipOptionEditComponent,
    CurrencyInputDirective,
    ...ENTRY_COMPONENTS
];

@NgModule({
    imports: [CommonModule, FormsModule, MatInputModule, MatChipsModule, MatDatepickerModule,MatAutocompleteModule,MatOptionModule],
    declarations: [...EXPORTED_COMPONENTS],
    exports: [...EXPORTED_COMPONENTS],
    entryComponents: [...ENTRY_COMPONENTS],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class MaterialFormFieldModule {}
