import { Directive, Optional } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";

@Directive({
    selector: '[importParentForm]',
    providers: [{
        provide: ControlContainer,
        deps: [NgForm],
        useFactory: identity
    }]
})
export class ImportParentFormDirective {}

export function identity<T>(value: T): T { return value; }