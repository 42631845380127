import { Input, Component } from "@angular/core";
import { Action } from "../../core";

export type ButtonType = 'button' | 'raised-button' | 'stroked-button' | 
                         'icon-button' | 'fab' | 'mini-fab'

@Component({
    selector: 'action',
    template: require('./action-renderer.component.pug')
})
export class ActionRendererComponent {
    
    @Input() action: Action;

    @Input() type: ButtonType = 'stroked-button';

    public get caption(): string {
        if (!this.action) 
            return "N/A";
        return this.action.caption;
    }

    public get tooltip(): string {
        if (!this.action)
            return "N/A";
        return this.action.hint;
    }
}