import { DashboardComponent } from "./dashboard.component";
import { WidgetContainerComponent, WidgetContainer } from "./widget.component";
import { WIDGETS } from "./widget/exports";
import { WidgetContentDirective } from "./widget/widget-content.directive";
import { InjectionToken } from "@angular/core";

export const DASHBOARD_TOKEN = new InjectionToken<DashboardComponent>('Dashboard');
export const WIDGET_CONTAINER_TOKEN = new InjectionToken<WidgetContainer>('WidgetContainer');

export const DASHBOARD_COMPONENTS = [
    DashboardComponent,
    WidgetContentDirective,
    WidgetContainerComponent
];

export const DASHBOARD_WIDGETS: any[] = [...WIDGETS];