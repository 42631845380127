import { Component, forwardRef, Input, Optional, Inject } from "@angular/core";
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from "@angular/forms";
import { AbstractFormField } from "../abstract-form-field";

@Component({
    selector: 'text-field',
    template: `
    <mat-form-field [class.mat-form-field-invalid] = 'invalid | async'>
        <mat-label *ngIf='label'> {{label}} </mat-label>
        <textarea matInput matTextareaAutosize [rows]='rows' [(ngModel)]='value'></textarea>
        <span matPrefix> 
            <ng-content select='[prefix]' ></ng-content>
        </span>
        <span matSuffix> 
            <ng-content select='[suffix]' ></ng-content>
        </span>
        <mat-hint> 
            <ng-content select='hint' ></ng-content>
        </mat-hint>
    </mat-form-field>
    `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextFieldComponent),
        multi: true
    }
]
})
export class TextFieldComponent extends AbstractFormField {

    @Input() rows: number = 2;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
        super(validators, asyncValidators);
     }
}