import { property_page, AbstractPropertyPage } from "../../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "sub-property-page",
    template: `
        <ng-container *ngIf="editingItem">
            <object-explorer-host
                [manifest]="options.manifest"
                [parentField]="options.foreignField"
                [parentManifest]="host.manifest"
                [parentObject]="editingItem.objectId"
            >
            </object-explorer-host>
        </ng-container>
    `
})
@property_page("SubPropertyPage")
export class SubPropertyPage extends AbstractPropertyPage<any> {}
