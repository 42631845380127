import { AbstractNgCustomComponent } from "./abstract-ng-custom-component";
import { Input, ViewChild, Optional, Inject, ContentChild, OnInit, AfterContentInit, ViewChildren } from "@angular/core";
import { NgModel, NG_VALIDATORS, NG_ASYNC_VALIDATORS, RequiredValidator, FormControl } from "@angular/forms";
import { MatFormFieldControl, MatInput, MatFormField } from "@angular/material";
import { QueryList } from "@angular/core";

export abstract class AbstractFormField extends AbstractNgCustomComponent
                                        implements AfterContentInit
                                         {

    protected required: boolean;

    @Input() public name: string;
    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public readonly: boolean;
    @Input() public disabled: boolean;
   
    @Input()  public appearance: string = 'outline';

    @ViewChild(NgModel) _model: NgModel;
    @ViewChildren(NgModel) _models: QueryList<NgModel>;
    @ViewChild(MatFormField) _control: MatFormField; 

    get model(): NgModel { return this._models ? this._models.first : this._model; }

    get control(): MatFormField { return this._control; }

    constructor(protected validators: Array<any>, protected asyncValidators: Array<any>) {
        super(validators, asyncValidators); 
        this.required = validators && validators.some( v => v instanceof RequiredValidator);
      }

    ngAfterContentInit() {        
       this.updateControlMandatory();
    }

    protected updateControlMandatory(): void {
        if (this.control._control instanceof MatInput) {
            let control = (<MatInput>this.control._control);
            control.required = this.required;
            control.readonly = this.readonly;
        }
    }
}