import { Pipe, Injectable, PipeTransform } from "@angular/core";
import { AccessControlService } from "../access-control.service";
import { Authorizable } from "../access-control.model";


/** Transport a list of IAuthorizable item by filtering unauthorized item 
 * within the current principle.
 */
@Pipe({
    name: 'authorize'
})
@Injectable()
export class AuthorizePipe implements PipeTransform {
    constructor(private auth: AccessControlService) {

	}

	transform(items: Authorizable[]): Authorizable[] {
		if (!items) return null
		return items.filter(item => this.auth.authorize(item));
	}

}