import { Component } from "@angular/core";
import { ApiAdvisorProvider, IconProvider } from "../../../jupiter/components";
import { DataSourceProvider } from "../../../jupiter/components";
import { ObjectExploringDescriptorProvider } from "../../../jupiter/components";
import { ActionProvider, AuthenticatorService, AccessControlService, JsonResolver } from "../../../jupiter/components";
import { ObjectPropertyDescriptorProvider } from "../../../jupiter/components";
import { Http } from "@angular/http";
import { map } from "rxjs/operators";

var x = require("../../styles/app.styles.sass");
export var __DEFAULT_API_DOMAIN__: string;
export var __DEFAULT_WORK_FLOW_STAGE_REQUEST_ID__: string;
export var __DEFAULT_WORK_FLOW_STAGE_SUBMIT_ID__: string;
export var __RELEASE_DATE__: string = "2019-05-25";

@Component({
    selector: "app",
    template: "<router-outlet *ngIf='config_loaded' ></router-outlet>"
})
export class AppComponent {
    config_loaded: boolean = false;
    private config: any;

    constructor(
        private http: Http,
        private browsingDescriptor: ObjectExploringDescriptorProvider,
        private propertyDescriptor: ObjectPropertyDescriptorProvider,
        private apiProvider: ApiAdvisorProvider,
        private iconProvider: IconProvider,
        private dataSourceProvider: DataSourceProvider,
        private actionProvider: ActionProvider,
        private authenticator: AuthenticatorService,
        private accessControl: AccessControlService
    ) {
        this.http
            .get("/config.txt")
            .pipe(map(content => content.json()))
            .subscribe({
                next: value => this.initialize(value),
                error: err => this.initialize({})
            });
    }

    private initialize(config: any) {
        this.config = config;
        this.config_loaded = true;
        console.log(config);
        __DEFAULT_API_DOMAIN__ = this.config.server || __API_DOMAIN__;
        __DEFAULT_WORK_FLOW_STAGE_REQUEST_ID__ = this.config.workflowStageRequestId || '78370bac-586a-e911-80d4-0050560a0357';
        __DEFAULT_WORK_FLOW_STAGE_SUBMIT_ID__ = this.config.workflowStageSubmitId || '239da8bc-586a-e911-80d4-0050560a0357';
        let resolver = new JsonResolver();
        resolver.add("view", require("./settings/browsing-view.yml"));
        resolver.add("view.directory", require("./settings/browsing-view.directory.yml"));
        resolver.add("view.widget", require("./settings/browsing-view.widget.yml"));
        resolver.add("view.report", require("./settings/reports.yml"));
        resolver.add("propview", require("./settings/property-view.yml"));
        resolver.add("propview.directory", require("./settings/property-view.directory.yml"));

        let apiDef = require("./settings/api.yml");
        let dataSourceDef = require("./settings/data-sources.yml");
        let actionDef = require("./settings/actions.yml");

        this.browsingDescriptor.add(resolver.resolvePath("view", "#/definitions/explorerForms"));
        this.browsingDescriptor.add(resolver.resolvePath("view.directory", "#/definitions/explorerForms"));
        this.browsingDescriptor.add(resolver.resolvePath("view.widget", "#/definitions/explorerForms"));
        this.browsingDescriptor.add(resolver.resolvePath("view.report", "#/definitions/reports"));
        this.propertyDescriptor.add(resolver.resolvePath("propview", "#/definitions/propertyForms"));
        this.propertyDescriptor.add(resolver.resolvePath("propview.directory", "#/definitions/propertyForms"));
        this.actionProvider.add(actionDef.actions.templates);
        this.actionProvider.defaultActionNames = actionDef.actions.defaults || [];
        this.apiProvider.servers = apiDef.servers;
        if (!this.apiProvider.servers.default) this.apiProvider.servers.default = __DEFAULT_API_DOMAIN__;
        this.apiProvider.add(apiDef.advisors);
        this.dataSourceProvider.addDescriptor(null, dataSourceDef.datasources);
        this.accessControl.settings = apiDef.accessControl;
        this.iconProvider.setIconConfig(require("./settings/icons.yml"));

        console.log(this.apiProvider);
        console.log(this.browsingDescriptor);
        console.log(this.propertyDescriptor);
        console.log(this.dataSourceProvider);
        console.log(this.actionProvider);
        console.log(this.accessControl);
    }
}
