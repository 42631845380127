import { Injector } from "@angular/core";
import { DeclarativeDataSource } from "./data-source";
import { DataSourceDeclaration } from "./data-source-descriptor";
import { Observable, Subject } from "rxjs";
import { CRUD } from "../api/crud-api-advisor";

export class ApiDataSource<T> extends DeclarativeDataSource<T> {

    private _count: number = -1;

    public get count(): number {
        return this._count >= 0 ? this._count : -1; 
    }
    constructor(descriptor: DataSourceDeclaration, protected injector: Injector) {
        super(descriptor, injector);
    }

    public countData(): Observable<number> {
        var api = this.getApi<any>(CRUD.Count);
        if (!api)
            return Observable.of( this.data ? this.data.length : -1);
        var result = new Subject<number>();
        api.invoke({ payload: this.option }).subscribe({
            next: data => {
                this._count = data.result || data.count;
                result.next(this._count);
            },
            error: err => result.error(err),
            complete: () => result.complete()
        });
        return result;
    }
}