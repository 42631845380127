export class Conditional {
    public status?: string[];
    public security?: string[];
    constructor(data?: any) {
        if (data) {
            this.status = data.status;
            this.security = data.security;
        }
    }

    public static create(data?: any): Conditional {
        if (data) return new Conditional(data);
        return undefined;
    }

    public static mergeCondition(items: any[]): Conditional {
        let result = new Conditional();
        items.forEach(item => {
            if (!item) return;
            result.security = result.security || item.security;
            result.status = result.status || item.status;
        });
        return result;
    }
}
