import { Input, Component } from "@angular/core";
import { IconProvider } from "../../core";

@Component({
    selector: 'icon',
    template: `
        <ng-container *ngIf='icon' [ngSwitch]='type || "icon"'>
            <mat-icon *ngSwitchCase='"icon"'>{{icon}}</mat-icon>
            <mat-icon *ngSwitchCase='"svg"' svgIcon='{{icon}}'></mat-icon>
        </ng-container>
    `
})
export class IconComponent {
    @Input('icon') _icon: string;
    @Input('type') _type: string;
    @Input() key: string;
    @Input() showEmptyIcon: boolean = true;
    
    get icon(): string { return this._icon || this.iconProvider.getIcon(this.key) || this.defaultIcon; }
    get type(): string { return this._type || this.iconProvider.getIconType(this.key); }

    get defaultIcon(): string { return this.showEmptyIcon ? this.key : undefined; }
    
    constructor(private iconProvider: IconProvider) {

    }
}