import {
    property_page,
    AbstractPropertyPage,
    getOrCreateProperty,
    DataSourceProvider,
    DataSource,
    AccessControlService,
    CRUD
} from "../../../../jupiter/components";
import { Component } from "@angular/core";
import { DefaultQueryOption } from "../../../../jupiter/components";
import { DataSource as CdkDataSource, CollectionViewer } from "@angular/cdk/collections";
import { Observable, Subject, BehaviorSubject } from "rxjs";

@Component({
    selector: "object-permission-property-page",
    template: require("./object-permission.proppage.pug")
})
@property_page("ObjectPermissionPage")
export class ObjectPermissionPage extends AbstractPropertyPage<any> {
    displayColumns: string[] = ["action", "account", "readable", "editable", "deletable", "ownership"];
    userDataSource: DataSource<any>;
    aclDataSource: AclDataSource;

    constructor(private dataSourceProvider: DataSourceProvider, private auth: AccessControlService) {
        super();
        this.userDataSource = this.dataSourceProvider.createDataSource("appUser");
    }

    onEditingItemChanged() {
        super.onEditingItemChanged();
        if (!this.editingItem) return;
        this.aclDataSource = new AclDataSource(this.editingItem.properties.objectACL);

        if (!this.userDataSource.ready) {
            let option = new DefaultQueryOption({
                fieldSet: "lookup",
                sortFields: [{ direction: "asc", name: "objectType" }, { direction: "asc", name: "caption" }],
                properties: { objectType: "user;role" }
            });
            this.userDataSource.fetchData(CRUD.List, null, option);
        }
    }

    get hasOwnership(): boolean {
        return (
            this.editingItem.ownerId === this.auth.context.principle.objectId ||
            this.auth.authorize([`${this.host.manifest.name}:ownership`, "role:administrators"])
        );
    }

    addAcl() {
        this.aclDataSource.addData({ objectId: this.editingItem.objectId });
    }
}

class AclDataSource extends CdkDataSource<any> {
    data: any[];
    dataSubject: Subject<any>;

    constructor(data: any[]) {
        super();
        this.data = data;
        this.dataSubject = new BehaviorSubject<any>(this.data);
    }

    connect(collectionViewer: CollectionViewer): Observable<any[]> {
        return this.dataSubject;
    }

    addData(item: any) {
        this.data.push(item);
        this.dataSubject.next(this.data);
    }

    remove(item: any) {
        let idx = this.data.indexOf(item);
        this.data.splice(idx, 1);
        this.dataSubject.next(this.data);
    }

    disconnect(collectionViewer: CollectionViewer): void {}
}
