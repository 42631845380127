import { Component, Optional, Inject, Input } from "@angular/core";
import { Action, IconProvider } from "../../core";
import { AbstractViewerPartComponent } from "../../object-explorer/components/abstract-viewer-part";
import { object_viewer_part, StandardActionSet, ObjectPropertyDescriptor } from "../../object-explorer";
import { SearchableComponent } from "../../object-explorer/provider/searchable-provider";

@Component({
    selector: 'object-explorer-bar',
    template: require('./action-bar.component.pug')
})
@object_viewer_part('MaterialOvpActionBarComponent')
export class MaterialOvpActionBarComponent extends AbstractViewerPartComponent<any> {
   
    @Input('actions') customActions: Action[];

    constructor(@Optional() @Inject(SearchableComponent) private searchProvider: SearchableComponent,
                    private iconProvider: IconProvider) {
        super();
    }

    public get actions(): Action[] {
        return this.customActions || this.getActions(StandardActionSet.DefaultActions);
    }

    public get isSearchable(): boolean { return this.searchProvider !== undefined; }

    get showPageTopic(): boolean { return this.host.manifest instanceof ObjectPropertyDescriptor; }

    get icon(): string {
        if (!this.host || !this.host.manifest)
            return undefined;
        return this.host.manifest.name;
    }

    ngOnInit() {
        super.ngOnInit();
        console.log('ActionBar options: ', this.options)
    }

    search(value: any) {
        if (this.isSearchable)
            this.searchProvider.search(value);
    }

    remove(value: any) { 
        if (this.isSearchable)
            this.searchProvider.search(value);
    }
}
