import { DeclarativeDataSource, DataSourceStatus } from "./data-source";
import { DataSourceDeclaration } from "./data-source-descriptor";
import { Observable } from "rxjs";
import { Injector } from "@angular/core";
import { QueryOption } from "./query-option";

export class CsvDatasource<T> extends DeclarativeDataSource<T> {
    constructor(descriptor: DataSourceDeclaration, protected injector: Injector) {
        super(descriptor, injector);
    }

    public countData(): Observable<number> {
        return Observable.of(this.count);
    }

    protected pushData(): void {
        let pageIndex = this.option.page - 1;
        if (pageIndex < 0 || this.option.pageSize <= 0) this.dataSubject.next(this.data);
        else {
            let pageStartIndex = pageIndex * this.option.pageSize;
            this.dataSubject.next(this.data.slice(pageStartIndex, pageStartIndex + this.option.pageSize));
        }
    }

    public list(option?: QueryOption): Observable<T[]> {
        this.pushData();
        return this.dataSubject;
    }
}
