module.exports = {
	"widgets": [
		{
			"title": "Pipline Analysis - Current Year",
			"dataSet": "widget_Pipeline",
			"component": "ChartWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"chartType": "bar-horizontal",
				"period": "thisyear"
			}
		},
		{
			"title": "Market Share - Current Year",
			"dataSet": "widget_MarketShare",
			"component": "ChartWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"chartType": "pie",
				"period": "thisyear"
			}
		},
		{
			"title": "Market Share (Segment) - Current Year",
			"dataSet": "widget_MarketShareBySegment",
			"component": "ChartWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"chartType": "grouped-bar-horizontal",
				"period": "thisyear"
			}
		},
		{
			"title": "Opps by Market Segment - Current Year",
			"dataSet": "widget_OppsBySegment",
			"component": "ChartWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"chartType": "pie",
				"period": "thisyear"
			}
		},
		{
			"title": "Opps by Business Line - Current Year",
			"dataSet": "widget_OppsByBusinessLine",
			"component": "ChartWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"chartType": "pie",
				"period": "thisyear"
			}
		},
		{
			"title": "Success Rate - Current Year",
			"dataSet": "widget_SuccessRate",
			"component": "ChartWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"chartType": "gauge",
				"period": "thisyear"
			}
		},
		{
			"title": "Opportunities by Chance",
			"dataSet": "widget_OppsBySuccessChance",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "widget_OppBySuccessChance",
				"period": "thisyear"
			}
		},
		{
			"title": "Top 5 Salesman - Current Year",
			"dataSet": "widget_TopTenAccounts",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "topten_accounts",
				"period": "thisyear"
			}
		},
		{
			"title": "Customer Visit - Current Month",
			"dataSet": "widget_CustomerVisit",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "customer_visit",
				"period": "thismonth"
			}
		},
		{
			"title": "Top 10 Opps (by value)",
			"dataSet": "widget_TopTenOpps",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "topten_opportunity",
				"period": "last30days"
			}
		},
		{
			"title": "Top 10 Opps (by chance of success)",
			"dataSet": "widget_TopTenOppsByChance",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "topten_opportunity",
				"period": "last30days"
			}
		},
		{
			"title": "Top 10 Organization - Current Year",
			"dataSet": "widget_TopTenOrgs",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "topten_organization",
				"period": "thisyear"
			}
		},
		{
			"title": "Top 10 Overdue Opps",
			"dataSet": "widget_OverdueOpps",
			"component": "TableWidget",
			"request": "dashboard:manager",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "overdue_opportunity",
				"period": "thismonth"
			}
		},
		{
			"title": "Top 10 Opps Expired Next 10 Days",
			"dataSet": "widget_ToExpiredOpps",
			"component": "TableWidget",
			"request": "dashboard:sale-executive",
			"boards": [
				"default"
			],
			"data": {
				"tableManifest": "active_opportunity",
				"period": "thismonth"
			}
		},
		{
			"title": "Ongoing Activities",
			"dataSet": "widget_Activity",
			"component": "ActivityWidget",
			"boards": [
				"sales"
			],
			"data": {
				"tableManifest": "active_activity",
				"period": "thismonth"
			}
		},
		{
			"title": "Active Opportunities",
			"dataSet": "widget_ActiveOpps",
			"component": "OpportunityWidget",
			"boards": [
				"sales"
			],
			"data": {
				"tableManifest": "active_opportunity",
				"period": "thismonth"
			}
		},
		{
			"title": "New Opportunities",
			"dataSet": "widget_ActiveOpps",
			"component": "OpportunityNewWidget",
			"boards": [
				"sales"
			],
			"data": {
				"tableManifest": "active_opportunity",
				"period": "thismonth"
			}
		},
		{
			"title": "Request Opportunities",
			"dataSet": "widget_ActiveOpps",
			"component": "OpportunityRequestWidget",
			"boards": [
				"sales"
			],
			"data": {
				"tableManifest": "active_opportunity",
				"period": "thismonth"
			}
		},
		{
			"title": "Submited Opportunities",
			"dataSet": "widget_ActiveOpps",
			"component": "OpportunitySubmitedWidget",
			"boards": [
				"sales"
			],
			"data": {
				"tableManifest": "active_opportunity",
				"period": "thismonth"
			}
		}
	]
};