module.exports = {
	"definitions": {
		"propertyForms": {
			"organization": {
				"caption": {
					"edit": "(C-{editingItem.ident}) {editingItem.name}",
					"new": "New organization",
					"icon": "business"
				},
				"datasource": "organization",
				"pages": {
					"OrganizationPropertyPage": {
						"caption": "Details"
					},
					"OrganizationPersonPropertyPage": {
						"caption": "Contact Person",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"OpportunityPage": {
						"component": "SubPropertyPage",
						"caption": "Opportunity",
						"visibleWhen": {
							"status": [
								"!new"
							]
						},
						"options": {
							"foreignField": "properties.opportunity.organizationId",
							"manifest": "org_opportunity"
						}
					},
					"SubPropertyPage": {
						"caption": "Activity",
						"visibleWhen": {
							"status": [
								"!new"
							]
						},
						"options": {
							"foreignField": "properties.activity.organizationId",
							"manifest": "org_activity"
						}
					},
					"AttachmentPropertyPage": {
						"caption": "Attachment",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							],
							"security": [
								"organization:ownership",
								"role:administrators"
							]
						}
					}
				}
			},
			"person": {
				"caption": {
					"edit": "(ES-{editingItem.ident}) {editingItem.name}",
					"new": "New contact person"
				},
				"actions": {
					"new": {
						"url": "/organization/property/{extra.parentObjectId}/person/new",
						"visibleWhen": {
							"status": [
								"child"
							],
							"security": [
								"role:administrators",
								"{manifest.name}:edit"
							]
						}
					},
					"save": {
						"options": {
							"redirectOnSaveNew": "/organization/property/{extra.parentObjectId}/person/property/{activeElement.objectId}",
							"class": "primary"
						}
					},
					"close": null
				},
				"pages": {
					"PersonPropertyPage": {
						"caption": "Details"
					},
					"SubPropertyPage": {
						"caption": "Activity",
						"visibleWhen": {
							"status": [
								"!new"
							]
						},
						"options": {
							"foreignField": "personId",
							"manifest": "person_activity"
						}
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"AttachmentPropertyPage": {
						"caption": "Attachment",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							],
							"security": [
								"person:ownership",
								"role:administrators"
							]
						}
					}
				}
			},
			"opportunity": {
				"caption": {
					"edit": "(ES-{editingItem.properties.opportunity.projectCode}) {editingItem.properties.opportunity.projectName}",
					"new": "New opportunity"
				},
				"actions": {
					"new": null,
					"clone": null,
					"save": null,
					"close": null
				},
				"pages": {
					"OpportunityPropertyPage": {
						"caption": "Details"
					},
					"SubPropertyPage": {
						"caption": "Activity",
						"visibleWhen": {
							"status": [
								"!new"
							]
						},
						"options": {
							"foreignField": "properties.activity.opportunityId",
							"manifest": "opp_activity"
						}
					},
					"QuotationPropertyPage": {
						"caption": "Quotations",
						"component": "OppQuotationPropertyPage",
						"visibleWhen": {
							"status": [
								"!new"
							]
						},
						"options": {
							"foreignField": "properties.quotation.opportunityId",
							"manifest": "opp_quotation"
						}
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"AttachmentPropertyPage": {
						"caption": "Attachment",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					}
				}
			},
			"subopportunity": {
				"datasource": "opportunity",
				"caption": {
					"$ref": "#/definitions/propertyForms/opportunity/caption"
				},
				"actions": {
					"new": "/{extra.parentManifest}/property/{extra.parentObjectId}/{manifest.name}/new",
					"save": {
						"options": {
							"redirectOnSaveNew": "/{extra.parentManifest}/property/{extra.parentObjectId}/{manifest.name}/property/{activeElement.objectId}",
							"class": "primay"
						}
					},
					"close": null
				},
				"pages": {
					"$ref": "#/definitions/propertyForms/opportunity/pages"
				}
			},
			"viewimage": {
				"datasource": "attachment"
			},
			"subattachment": {
				"datasource": "attachment",
				"caption": {
					"edit": "(ES-{editingItem.ident}) {editingItem.name}"
				},
				"actions": {
					"view": {
						"icon": "search",
						"caption": "View",
						"type": "link",
						"url": "/attachment/{extra.parentObjectId}/preview/{activeElement.objectId}"
					},
					"getAttachment": {
						"icon": "cloud_download",
						"caption": "Download",
						"type": "event"
					},
					"close": null
				},
				"pages": {
					"AttachmentPage": {
						"caption": "Details"
					}
				}
			},
			"activity": {
				"caption": {
					"edit": "(ES-{editingItem.ident}) {editingItem.properties.activity.subject}",
					"new": "New activity"
				},
				"pages": {
					"ActivityPropertyPage": {
						"caption": "Details"
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							],
							"security": [
								"activity:ownership",
								"role:administrators"
							]
						}
					},
					"AttachmentPropertyPage": {
						"caption": "Attachment",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					}
				}
			},
			"subactivity": {
				"datasource": "activity",
				"caption": {
					"$ref": "#/definitions/propertyForms/activity/caption"
				},
				"actions": {
					"new": "/{extra.parentManifest}/property/{extra.parentObjectId}/{manifest.name}/new",
					"save": {
						"options": {
							"redirectOnSaveNew": "/{extra.parentManifest}/property/{extra.parentObjectId}/{manifest.name}/property/{activeElement.objectId}",
							"class": "primary"
						}
					},
					"close": null
				},
				"pages": {
					"$ref": "#/definitions/propertyForms/activity/pages"
				}
			},
			"project": {
				"caption": {
					"edit": "(ES-{editingItem.ident}) {editingItem.name}",
					"new": "New project"
				},
				"pages": {
					"ProjectPropertyPage": {
						"caption": "Details"
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							],
							"security": [
								"quotation:ownership",
								"role:administrators"
							]
						}
					},
					"AttachmentPropertyPage": {
						"caption": "Attachment",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					}
				}
			},
			"quotation": {
				"caption": {
					"edit": "(ES-{editingItem.ident}) {editingItem.name} Rev. {editingItem.metaIndex}",
					"new": "New quotation"
				},
				"pages": {
					"QuotationPropertyPage": {
						"caption": "Details"
					},
					"ObjectHistoryPage": {
						"caption": "History",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					},
					"ObjectPermissionPage": {
						"caption": "Permission",
						"visibleWhen": {
							"status": [
								"!new"
							],
							"security": [
								"quotation:ownership",
								"role:administrators"
							]
						}
					},
					"AttachmentPropertyPage": {
						"caption": "Attachment",
						"visibleWhen": {
							"status": [
								"!new"
							]
						}
					}
				}
			},
			"subquotation": {
				"datasource": "quotation",
				"caption": {
					"$ref": "#/definitions/propertyForms/quotation/caption"
				},
				"actions": {
					"new": "/{extra.parentManifest}/property/{extra.parentObjectId}/{manifest.name}/new",
					"save": {
						"options": {
							"redirectOnSaveNew": "/{extra.parentManifest}/property/{extra.parentObjectId}/{manifest.name}/property/{activeElement.objectId}",
							"class": "primary"
						}
					},
					"close": null
				},
				"pages": {
					"$ref": "#/definitions/propertyForms/quotation/pages"
				}
			}
		},
		"templates": {
			"objectPermissionPage": {
				"caption": "Permission",
				"visibleWhen": {
					"status": [
						"!new"
					],
					"security": [
						"role:Administrators"
					]
				}
			}
		}
	}
};