import { AbstractParameterizePage } from "./abtract-param-page";
import { ObjectViewerHost, ObjectViewerDescriptor } from "../models";
import {
    DataContext,
    Action,
    ActionProvider,
    ActionExecuteHandler,
    ActionHandlingContext,
    evalField,
    ConditionChecker,
    ConditionalStatusProvider,
    SecurityChecker,
    AccessControlService,
    AuthorizeRequest,
    Conditional,
    ConditionalItem,
    MessageService,
    MessageSubscription,
    ActionMessageChannel,
    ActionHandlingStatus
} from "../../core";
import { ActivatedRoute, Router, ActivatedRouteSnapshot, NavigationExtras } from "@angular/router";
import { Input, EventEmitter, Output, OnDestroy, OnInit } from "@angular/core";
import { AbstractMessageHandler } from "../../core/message/message-handler";

export abstract class AbstractObjectViewerHost<TManifest extends ObjectViewerDescriptor> extends AbstractMessageHandler
    implements
        ActionExecuteHandler,
        ObjectViewerHost<TManifest>,
        ConditionalStatusProvider,
        SecurityChecker,
        ConditionChecker,
        OnInit,
        OnDestroy {
    public routeSnapshot: ActivatedRouteSnapshot;

    @Input()
    options: any;

    @Output()
    onExecuting: EventEmitter<ActionHandlingContext> = new EventEmitter();
    @Output()
    onExecute: EventEmitter<ActionHandlingContext> = new EventEmitter();
    @Output()
    onExecuted: EventEmitter<ActionHandlingContext> = new EventEmitter();

    public get manifest(): TManifest {
        return this._manifest;
    }
    public set manifest(value: TManifest) {
        if (value === this._manifest) return;
        this._manifest = value;
        this.manifestChanged();
    }
    private _manifest: TManifest;

    public context: DataContext<any>;

    public abstract get title(): string;

    private _actionHandlerSubscription: MessageSubscription;
    private _returnUrl: string;
    protected returnUrlExtra: NavigationExtras;

    get returnUrl(): string {
        return this._returnUrl;
    }
    set returnUrl(value: string) {
        this._returnUrl = value;
    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super();
        this._actionHandlerSubscription = ActionMessageChannel.subscribeActionMessageHandler(
            this.messageService,
            this
        ).setFilter({
            topic: ActionMessageChannel.EXECUTE_TOPIC
        });
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe({
            next: params => {
                this.returnUrl = params["ret"];
                this.returnUrlExtra = this.parseNativationExtra(params["retparam"]);
            }
        });
    }

    private parseNativationExtra(value: string): NavigationExtras {
        if (!value) return;
        let result: NavigationExtras = { queryParams: {} };
        value.split("|").forEach(param => {
            let i = param.indexOf("=");
            if (i < 0) return;
            result.queryParams[param.substr(0, i)] = param.substr(i + 1);
        });
        return result;
    }

    ngOnDestroy() {
        this._actionHandlerSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    public abstract getActions(name: string): Action[];

    public get ready(): boolean {
        return this.context && this.context.dataSource && this.context.dataSource.ready;
    }

    protected manifestChanged(): void {
        // do nothing
    }

    protected buildActions(cache: Action[], temmplateActions: any, defaultActionSet: string): Action[] {
        if (cache) return cache;
        let actions = this.actionProvider.buildActionList(
            temmplateActions,
            defaultActionSet,
            null,
            this.createActionHandlingContext(),
            this
        );
        actions.forEach(action => this.updateConditionItem(action, this));
        return actions.filter(action => action.visible);
    }

    protected createActionHandlingContext(): ActionHandlingContext {
        return {
            manifest: this.manifest,
            dataContext: this.context,
            status: ActionHandlingStatus.Unhandled
        };
    }

    protected updateConditionItem(item: Action, context: any): ConditionalItem {
        if (!item) return item;
        item.messageService = this.messageService;
        if (item.enabledWhen) this.updateCondition(item.enabledWhen, context);
        if (item.visibleWhen) this.updateCondition(item.visibleWhen, context);
        return item;
    }

    protected updateCondition(condition: Conditional, context: any) {
        if (!condition || !condition.security) return;
        condition.security = condition.security.map(item => evalField(item, context));
    }

    public handleActionExecute(context: ActionHandlingContext) {
        if (context.manifest.name != this.manifest.name) return;
        this.onExecuting.emit(context);
        if (context.status !== ActionHandlingStatus.Unhandled) return;
        switch (context.action.type) {
            case "event":
                this.handleEventAction(context);
                break;
            case "link":
                this.prepareContext(context);
                let url = evalField(context.action.url, context);
                this.router.navigate([url], context.navigationExtras);
                break;
        }
        return true;
    }

    private prepareContext(context: ActionHandlingContext) {
        if (context.action.name !== "edit" && context.action.name !== "new") return;
        context.navigationExtras = { queryParams: { ret: this.getActivatedRouteUrl(this.activatedRoute) } };
        this.messageService.send({
            sender: this,
            channel: ActionMessageChannel.CHANNEL_ID,
            topic: ActionMessageChannel.PREPARE_CONTEXT,
            payload: context
        });
    }

    protected handleEventAction(context: ActionHandlingContext) {
        switch (context.action.name) {
            case "save":
                this.doSave(context);
                break;
            case "delete":
                this.doDelete(context);
                break;
            case "close":
                this.doClose(context);
                break;
            default:
                this.onExecute.emit(context);
                break;
        }
    }

    protected doSave(context: ActionHandlingContext) {
        if (!context.dataContext.active) return;
        context.status = ActionHandlingStatus.Completed;
        let subscription = context.dataContext.dataSource.update(context.dataContext.active).subscribe({
            next: _ => {
                this.handlePostAction(context);
                subscription.unsubscribe();
            },
            error: err => {
                this.handleError(err.json());
                subscription.unsubscribe();
            }
        });
    }

    protected doDelete(context: ActionHandlingContext) {
        if (!context.element) return;
        context.status = ActionHandlingStatus.Completed;
        if (!window.confirm(`This will delete '${context.element.name}'. Are you sure to continue?`)) return;
        context.dataContext.dataSource.delete(context.element.objectId).subscribe({
            next: _ => this.handlePostAction(context),
            error: err => this.handleError(err.json())
        });
    }

    protected doClose(context: ActionHandlingContext) {
        context.status = ActionHandlingStatus.Completed;
        if (this.returnUrl) {
            this.router.navigate([this.returnUrl], this.returnUrlExtra);
            return;
        }
        let parent = this.activatedRoute.parent;
        if (this.activatedRoute.snapshot.url.length == 0 && parent.parent) parent = parent.parent;
        this.router.navigateByUrl(this.getActivatedRouteUrl(parent));
    }

    private getActivatedRouteUrl(route: ActivatedRoute, defaultRoute: string = "/"): string {
        let routerLink = route.snapshot.pathFromRoot
            .map(s => s.url)
            .reduce((a, e) => a.concat(e))
            .map(s => s.path);
        if (routerLink.length > 0) {
            return routerLink.join("/");
        }
        return defaultRoute;
    }

    protected handleError(err: any) {
        window.alert(err.message);
        console.log(err);
    }

    protected handlePostAction(context: ActionHandlingContext) {
        this.messageService.send({
            channel: ActionMessageChannel.CHANNEL_ID,
            topic: ActionMessageChannel.POST_EXECUTE_TOPIC,
            sender: this,
            payload: context
        });
        this.onExecuted.emit(context);
    }

    public checkSecurity(request: AuthorizeRequest) {
        return this.accessControl.authorize(request);
    }

    public hasStatus(status: string): boolean {
        if (status.startsWith("!")) return !this.hasStatus(status.substring(1));

        switch (status) {
            case StandardStatus.EmptySelection:
                return this.context.selection.isEmpty();
            case StandardStatus.SingleSelection:
                return this.context.selection.selected.length == 1;
            case StandardStatus.MultiSelection:
                return this.context.selection.isMultipleSelection() && this.context.selection.selected.length > 0;
            default:
                return false;
        }
    }

    public satisfy(condition: Conditional): boolean {
        if (!condition) return true;
        return (
            (!condition.status || !condition.status.some(s => !this.hasStatus(s))) &&
            this.checkSecurity(condition.security)
        );
    }
}

export namespace StandardStatus {
    export const Dirty = "dirty";
    export const Valid = "valid";
    export const Invalid = "invalid";
    export const Pristine = "pristine";
    export const New = "new";
    export const Child = "child";
    export const EmptySelection = "selection/empty";
    export const SingleSelection = "selection/single";
    export const MultiSelection = "selection/multi";
}
