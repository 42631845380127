import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Optional, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { MatFileUploadQueue } from '../matFileUploadQueue/matFileUploadQueue.component';
import { AuthenticationContext } from '../../core';


/**
 * A material design file upload component.
 */
@Component({
    selector: 'mat-file-upload',
    template: require('./matFileUpload.component.html'),
    exportAs: 'matFileUpload',
    host: {
        'class': 'mat-file-upload',
    }
})
export class MatFileUpload implements OnDestroy {

    constructor(
        private HttpClient: HttpClient,
        private authContext: AuthenticationContext
        , @Inject(forwardRef(() => MatFileUploadQueue)) public matFileUploadQueue: MatFileUploadQueue
    ) {

        if (matFileUploadQueue) {
            this.httpUrl = matFileUploadQueue.httpUrl || this.httpUrl;
            this.httpRequestHeaders = matFileUploadQueue.httpRequestHeaders || this.httpRequestHeaders;
            this.httpRequestParams = matFileUploadQueue.httpRequestParams || this.httpRequestParams;
            this.fileAlias = matFileUploadQueue.fileAlias || this.fileAlias;
        }

    }

    private isUploading: boolean = false;



    /* Http request input bindings */
    @Input()
    httpUrl: string = 'http://localhost:8080';

    @Input()
    httpRequestHeaders: HttpHeaders | {
        [header: string]: string | string[];
    } = new HttpHeaders();

    @Input()
    httpRequestParams: HttpParams | {
        [param: string]: string | string[];
    } = new HttpParams();

    @Input()
    fileAlias: string = "file";

    @Input()
    get file(): any {
        return this._file;
    }
    set file(file: any) {
        this._file = file;
        this.total = this._file.size;
    }

    @Input()
    set id(id: number) {
        this._id = id;
    }
    get id(): number {
        return this._id;
    }

    /** Output  */
    @Output() removeEvent = new EventEmitter<MatFileUpload>();
    @Output() onUpload = new EventEmitter();

    private progressPercentage: number = 0;
    public loaded: number = 0;
    private total: number = 0;
    private _file: any;
    private _id: number;
    private fileUploadSubscription: any;

    public upload(): void {
        this.isUploading = true;
        // How to set the alias?
        let headers = new HttpHeaders()
        // headers.append('Auhtorization', 'Bearer ' + this.authContext.token);
        headers = headers.append('Authorization', 'Bearer ' + this.authContext.token);
        //this.httpRequestHeaders = headers;

        // let formData = new FormData();
        // const uuidv4 = require("uuid/v4");
        // let tmp = this._file.name.split('.');
        // let ext = uuidv4() + (tmp.length > 0 ? ('.' + tmp[tmp.length-1]) : '');
        // formData.set(this.fileAlias, this._file, ext);
        
        let formData = new FormData();
        formData.set(this.fileAlias, this._file, this._file.name);
        this.fileUploadSubscription = this.HttpClient.post(this.httpUrl, formData, {
            headers: headers,
            observe: "events",
            params: this.httpRequestParams,
            reportProgress: true,
            responseType: "json"
        }).subscribe((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
                this.progressPercentage = Math.floor(event.loaded * 100 / event.total);
                this.loaded = event.loaded;
                this.total = event.total;
            }
            this.onUpload.emit({ file: this._file, event: event });
            if (event instanceof HttpResponse)
                this.remove();
        }, (error: any) => {
            if (this.fileUploadSubscription) {
                this.fileUploadSubscription.unsubscribe();
            }
            this.isUploading = false;
            this.onUpload.emit({ file: this._file, event: event });
        });
    }

    public remove(): void {
        if (this.fileUploadSubscription) {
            this.fileUploadSubscription.unsubscribe();
        }
        this.removeEvent.emit(this);
    }

    ngOnDestroy() {
        console.log('file ' + this._file.name + ' destroyed...');
    }

}