import { property_page, AbstractPropertyPage, getOrCreateProperty } from "../../../../jupiter/components";
import { Component } from "@angular/core";

@Component({
    selector: "account-assignedclaim-property-page",
    template: `
        <ng-container *ngIf="editingItem">
            <object-explorer-host
                manifest="assignedClaim"
                parentField="parentId"
                [parentManifest]="host.manifest.name"
                [parentObject]="editingItem.objectId"
            >
            </object-explorer-host>
        </ng-container>
    `
})
@property_page("AccountAssignedClaimPropertyPage")
export class AccountAssignedClaimPropertyPage extends AbstractPropertyPage<any> {}
