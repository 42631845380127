import { GlobalRegistryService } from "../registry/global-registry.service";

export const DATA_FORMATTER = 'data.formatter';

export function data_formatter(name: string) {
    return function(target: any) {
        let instance: any = new target();
        console.log('data formatter ', name, ":", instance)
        GlobalRegistryService.instance.register(DATA_FORMATTER, name, instance);
        return target;
    }
}

export function formatData(data: any, formatter: string[], option: any): any {
    let formatters = formatter.map(f => <DataFormatter>GlobalRegistryService.instance.getValue(DATA_FORMATTER, f))
                                .filter(f => f);
    let result = data;
    formatters.forEach(f => result = f.format(result, option));
    return result;
}

export abstract class DataFormatter {
    abstract format(value: any, options?: any): string;
}

