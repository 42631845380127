module.exports = {
	"main": [
		{
			"name": "dashboard",
			"label": "Dashboard",
			"items": [
				{
					"name": "statistic",
					"icons": "dashboard",
					"label": "Statistics Dashboard",
					"routerLink": [
						"/dashboard/default"
					]
				},
				{
					"name": "sales",
					"icons": "dashboard",
					"label": "Sales Dashboard",
					"routerLink": [
						"/dashboard/sales"
					]
				}
			]
		},
		{
			"name": "organization",
			"label": "Organization",
			"routerLink": [
				"/organization"
			],
			"request": [
				"organization:view",
				"role:administrators"
			],
			"items": [
				{
					"name": "organization",
					"icon": "business",
					"label": "List of organizations",
					"routerLink": [
						"/organization"
					],
					"request": [
						"organization:view",
						"role:administrators"
					]
				},
				{
					"name": "new_organization",
					"label": "New organization",
					"routerLink": [
						"/organization/new"
					],
					"request": [
						"organization:edit",
						"role:administrators"
					]
				},
				{
					"name": "-"
				},
				{
					"name": "organizationtype",
					"label": "Organization type",
					"routerLink": [
						"/organizationtype"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "organizationstatus",
					"label": "Organization status",
					"routerLink": [
						"/organizationstatus"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "businessline",
					"label": "Bussiness line",
					"routerLink": [
						"/businessline"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "segment",
					"label": "Segment",
					"routerLink": [
						"/segment"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				}
			]
		},
		{
			"name": "person",
			"label": "Person",
			"routerLink": [
				"/person"
			],
			"request": [
				"person:view",
				"role:administrators"
			],
			"items": [
				{
					"name": "person",
					"label": "List of people",
					"routerLink": [
						"/person"
					],
					"request": [
						"person:view",
						"role:administrators"
					]
				},
				{
					"name": "-"
				},
				{
					"label": "Saltuation",
					"routerLink": [
						"/saltuation"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "leadsource",
					"label": "Lead source",
					"routerLink": [
						"/leadsource"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "contactmethod",
					"label": "Contact method",
					"routerLink": [
						"/contactmethod"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "currency",
					"label": "Currency",
					"routerLink": [
						"/currency"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				}
			]
		},
		{
			"name": "project",
			"label": "Project",
			"routerLink": [
				"/project"
			],
			"request": [
				"project:view",
				"role:administrators"
			],
			"items": [
				{
					"name": "project",
					"label": "List of projects",
					"routerLink": [
						"/project"
					],
					"request": [
						"project:view",
						"role:administrators"
					]
				},
				{
					"name": "new_project",
					"label": "New project",
					"routerLink": [
						"/project/new"
					],
					"request": [
						"project:edit",
						"role:administrators"
					]
				}
			]
		},
		{
			"name": "opportunity",
			"label": "Opportunity",
			"routerLink": [
				"/opportunity"
			],
			"request": [
				"opportunity:view",
				"role:administrators"
			],
			"items": [
				{
					"name": "opportunity",
					"label": "List of opportunities",
					"routerLink": [
						"/opportunity"
					],
					"request": [
						"opportunity:view",
						"role:administrators"
					]
				},
				{
					"name": "new_opportunity",
					"label": "New opportunity",
					"routerLink": [
						"/opportunity/new"
					],
					"request": [
						"opportunity:edit",
						"role:administrators"
					]
				},
				{
					"name": "-"
				},
				{
					"name": "opportunityworkflowstage",
					"label": "Opportunity workflow stage",
					"routerLink": [
						"/opportunityworkflowstage"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "opportunitystage",
					"label": "Opportunity stage",
					"routerLink": [
						"/opportunitystage"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "incoterm",
					"label": "Incoterm",
					"routerLink": [
						"/incoterm"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "winreason",
					"label": "Win reason",
					"routerLink": [
						"/winreason"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				},
				{
					"name": "lostreason",
					"label": "Lost reason",
					"routerLink": [
						"/lostreason"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				}
			]
		},
		{
			"name": "activity",
			"label": "Activity",
			"icon": "assignment",
			"routerLink": [
				"/activity"
			],
			"request": [
				"activity:view",
				"role:administrators"
			],
			"items": [
				{
					"name": "activity",
					"label": "List of activities",
					"routerLink": [
						"/activity"
					],
					"request": [
						"activity:view",
						"role:administrators"
					]
				},
				{
					"name": "new_activity",
					"label": "New activity",
					"routerLink": [
						"/activity/new"
					],
					"request": [
						"activity:edit",
						"role:administrators"
					]
				},
				{
					"name": "-"
				},
				{
					"name": "activity_type",
					"label": "Activity types",
					"routerLink": [
						"/activitytype"
					],
					"request": [
						"directory:view",
						"role:administrators"
					]
				}
			]
		},
		{
			"name": "report",
			"icon": "list_alt",
			"label": "Reports"
		}
	],
	"right": [
		{
			"name": "setting",
			"label": null,
			"request": [
				"directory:view",
				"role:administrators"
			],
			"items": [
				{
					"name": "territory",
					"label": "Teritory",
					"routerLink": [
						"/territory"
					]
				},
				{
					"name": "country",
					"label": "Country",
					"routerLink": [
						"/country"
					]
				},
				{
					"name": "province",
					"label": "Province",
					"routerLink": [
						"/province"
					]
				}
			]
		},
		{
			"name": "appuser",
			"label": " {principle.caption}",
			"items": [
				{
					"name": "changepassword",
					"label": "Change password..."
				},
				{
					"name": "profile",
					"label": "User profile"
				},
				{
					"name": "-"
				},
				{
					"name": "appuser",
					"label": "Accounts",
					"routerLink": [
						"/appuser"
					],
					"request": [
						"account:view",
						"role:administrators"
					]
				},
				{
					"name": "approle",
					"label": "Roles",
					"routerLink": [
						"/approle"
					],
					"request": [
						"account:view",
						"role:administrators"
					]
				},
				{
					"name": "claim",
					"label": "Claim",
					"routerLink": [
						"/claim"
					],
					"request": [
						"role:administrators"
					]
				},
				{
					"name": "-"
				},
				{
					"name": "switchuser",
					"label": "Change ownership...",
					"routerLink": [
						"/changeowner"
					],
					"request": [
						"role:bod",
						"role:administrators"
					]
				},
				{
					"name": "-"
				},
				{
					"name": "logout",
					"label": "Logout"
				}
			]
		}
	]
};