import { Injectable, Injector } from "@angular/core";
import { DataSource } from "./data-source";
import { DataSourceDeclaration } from "./data-source-descriptor";
import { CsvDatasource } from "./csv-datasource";
import { ApiDataSource } from "./api-datasource";

@Injectable()
export class DataSourceProvider {

    private _descriptors: any = {}
    private _dataSourceType: any = {
        csv: CsvDatasource,
        api: ApiDataSource,
        default: ApiDataSource
    }

    constructor(private injector: Injector) {

    }

    public createDataSource<T>(id: string | DataSourceDeclaration): DataSource<T> {
        if (id == undefined)
            return undefined;
        if (typeof id == "string")
            return this.createDataSource(this._descriptors[id.toLowerCase()]);
        
        let descriptor = <DataSourceDeclaration> id;
        let datasourceType = this._dataSourceType[descriptor.type || 'default'];
        if (datasourceType == undefined) {
            console.log(`Unknown data source of type: `, descriptor.type);
            return undefined;
        }
        return new datasourceType(descriptor, this.injector);
    }

    public addDescriptor(name: string, descriptor: DataSourceDeclaration | object | string) {
        if (!descriptor)
            return;
        if (descriptor instanceof DataSourceDeclaration) {
            this._descriptors[name.toLowerCase()] = descriptor;
            return;
        }
        if (typeof descriptor == 'string') {
            this.addDescriptor(name, new DataSourceDeclaration(descriptor));
            return;
        }
        for(let ds_name in descriptor) {
             this.addDescriptor(ds_name, new DataSourceDeclaration(descriptor[ds_name]));
        }
    }
}