import { Component } from "@angular/core";
import { AbstractColumnRenderer, column_renderer } from "../../../../jupiter/components";

@Component({
    selector: "opportunity-win-lost",
    template: `<span>{{winLostText}}</span>`
})
@column_renderer("WinLostColumn")
export class OpportunityWinLostColumnRenderer extends AbstractColumnRenderer {
    get winLostText(): string {
        let value = this.element.properties.opportunity.stage.metaValue;
        if (value === 100) return "Win";
        if (value === undefined) return "Lost/Canceled";
        return "";
    }
}
