import { ConditionChecker } from "./conditional-checker";
import { Conditional } from "./conditional";

export class ConditionalItem {

    private _enabled:  boolean = true;
    private _visible: boolean = true;

    public enabledWhen?: Conditional;
    public visibleWhen?: Conditional;
    public checker?: ConditionChecker;

    public get enabled(): boolean {
        if (!this._enabled)
            return false;
        if (!this.checker || !this.enabledWhen)
            return true;
        return this.checker.satisfy(this.enabledWhen);
    }
    public set enabled(value: boolean) { this._enabled = value; }

    public get visible(): boolean {
        return this._visible && (!this.checker || this.checker.satisfy(this.visibleWhen));
    }
    public set visible(value: boolean) { this._visible = value; }

    constructor(data?: any) {
        if (typeof data === 'object') {
            this.enabledWhen = Conditional.create(data.enabledWhen);
            this.visibleWhen = Conditional.create(data.visibleWhen);
        }
    }
}