import { Component, Input } from "@angular/core";
import { MenuItem } from "./app.menu.component";
import { Router } from "@angular/router";
import { IconProvider, AccessControlService, evalField } from "../../../../jupiter/components";
import { MatMenuTrigger } from "@angular/material";

@Component({
    selector: "[angular-sub], angular-menu",
    template: require("./angular-menu.component.pug")
})
export class AngularMenuComponent {
    @Input("items")
    items: any;

    @Input()
    showIcon: boolean = true;
    @Input()
    compactMode: boolean = false;

    constructor(
        private iconProvider: IconProvider,
        private accessControl: AccessControlService,
        private router: Router
    ) {}

    get showRootMenuLabel(): boolean {
        return window.innerWidth > 500;
    }

    handle(item: MenuItem) {
        if (item.routerLink) {
            this.router.navigate(item.routerLink);
        } else
            switch (item.name) {
                case "logout":
                    this.accessControl.signOut();
                    this.router.navigate(["/login"]);
                    break;
                case "profile":
                    this.router.navigateByUrl(`/appuser/property/${this.accessControl.context.principle.objectId}`);
                    break;
                case "changepassword":
                    this.router.navigate(["/changepassword"]);
                    break;
                default:
                    break;
            }
    }

    handleSubMenu(item: MenuItem, trigger: MatMenuTrigger) {
        if (item.routerLink) {
            this.handle(item);
            return;
        }
        if (item.name === "dashboard") {
            let isSalePerson = this.accessControl.context.claims.findIndex(
                x => x.type === "role" && x.value.indexOf("Sales") >= 0
            );
            if (isSalePerson) this.router.navigate(["/dashboard/sales"]);
            else this.router.navigate(["/dashboard/default"]);
        }
        trigger.toggleMenu();
    }

    getText(item: MenuItem): string {
        return evalField(item.label, this.accessControl.context);
    }

    getIcon(item: MenuItem): string {
        return item.icon ? item.icon : this.iconProvider.getIcon(item.name);
    }

    getIconType(item: MenuItem): string {
        return this.iconProvider.getIconType(item.icon || item.name);
    }
}
