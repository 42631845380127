import { Injectable } from "@angular/core";

@Injectable()
export class GlobalRegistryService {
    private _storages: any = {};

    private static _instance: GlobalRegistryService = new GlobalRegistryService;

    public static get instance(): GlobalRegistryService { return GlobalRegistryService._instance; }

    constructor() {

    }

    public register(topic: string, name: string, value: any) {
        let substorage = this._storages[topic]
        if (substorage === undefined) {
            substorage = {};            
            this._storages[topic] = substorage;
        }
        substorage[name] = value;
    }

    public getValue(topic: string, name: string): any {
        let substorage = this._storages[topic]
        if (substorage === undefined)
            return undefined;
        return substorage[name];
    }
}