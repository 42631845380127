import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";

import * as Jupiter from "../../../jupiter/components";

import { AppComponent } from "./app.component";
import * as APP from "./app.decl";
import * as APP_IMPORT from "./app.import";
import { DYNAMIC_COMPONENTS } from "./app.decl";
import { FormsModule } from "@angular/forms";
import { AuthGuardService } from "./auth-guard.service";
import { HttpClientModule } from "@angular/common/http";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material";
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_FORMATS,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import { VietnameseDateAdapter, VIETNAMESE_DATE_FORMATS } from "../../../jupiter/components";

export const DD_MM_YYYY_Format = {
    parse: {
        dateInput: "DD/MM/YYYY"
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "DD/MM/YYYY",
        monthYearA11yLabel: "MMMM YYYY"
    }
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [...APP.APP_COMPONENTS, AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        Jupiter.CoreModule,
        Jupiter.ObjectViewerModule,
        Jupiter.MaterialComponentModule,
        Jupiter.MaterialFormFieldModule,
        Jupiter.NgSelectModule,
        Jupiter.CommonPagesModule,
        APP_IMPORT.IMPORTED_MODULES,
        APP.APP_ROUTES
    ],
    entryComponents: [...DYNAMIC_COMPONENTS],
    providers: [
        AuthGuardService,
        // { provide: DateAdapter, useClass: VietnameseDateAdapter },
        // { provide: MAT_DATE_FORMATS, useValue: VIETNAMESE_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: "vi" },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
