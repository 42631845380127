import { Component, ElementRef } from "@angular/core";
import {
    ApiAdvisorProvider,
    AccessControlService,
    ReportDefinition,
    ReportProvider
} from "../../../../jupiter/components";
import { __RELEASE_DATE__ } from "../app.component";

const _MENU_ = require("../settings/menus.yml");

@Component({
    selector: "main-form",
    template: require("./main.form.pug")
})
export class MainForm {
    menu: any;

    constructor(
        private el: ElementRef,
        private apiAdvisorProvider: ApiAdvisorProvider,
        private accessControl: AccessControlService,
        private reportProvider: ReportProvider
    ) {
        this.menu = _MENU_;
        this.reportProvider.definition.connect(null).subscribe({
            next: reports => this.populateReports(reports)
        });
    }

    get ReleaseDate(): string {
        return __RELEASE_DATE__;
    }

    get MainMenu(): any {
        return _MENU_.main;
    }

    get compactMode(): boolean {
        return window.innerWidth <= 1000;
    }

    private populateReports(reports: ReportDefinition[]) {
        if (!reports) return;
        let reportEntry = (<any[]>this.menu.main).find(item => item.name === "report");
        reportEntry.items = reports
            .filter(
                report =>
                    !report.viewRequest ||
                    this.accessControl.authorize(report.viewRequest) ||
                    this.accessControl.authorize(report.downloadRequest)
            )
            .map(report => {
                return {
                    name: report.name,
                    label: report.displayName,
                    icon: report.icon || report.name,
                    routerLink: ["/report", report.name]
                };
            });
    }
}
