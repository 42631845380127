import { MainForm } from "./main.form";
import { AppMenuComponent, AppSubMenuComponent } from "./app.menu.component";
import { AngularMenuComponent } from "./angular-menu.component";
import { LoginForm } from "./login-form.component";
import { ChangePasswordComponent } from "./change-password.component";
import { ChangeOwnerComponent } from "./change-owner.component";

export var FORM_COMPONENTS = [
    MainForm,
    LoginForm,
    ChangePasswordComponent,
    ChangeOwnerComponent,
    AngularMenuComponent,
    AppMenuComponent,
    AppSubMenuComponent
];
