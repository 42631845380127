import {
    property_page,
    AbstractPropertyPage,
    getOrCreateProperty,
    NgSelectComponent,
    DataSourceProvider,
    CRUD
} from "../../../../jupiter/components";
import { Component, ViewChild, OnInit } from "@angular/core";
import { MatChip } from "@angular/material";
import { Observable, Subject } from "rxjs";
import { delay } from "rxjs/operators";

@Component({
    selector: "assigned-claim-property-page",
    template: require("./assigned-claim.proppage.pug")
})
@property_page("AssignedClaimPropertyPage")
export class AssignedClaimPropertyPage extends AbstractPropertyPage<any> implements OnInit {
    domainValue: any[];
    lastValue: any;

    constructor(private dataSourceProvider: DataSourceProvider) {
        super();
    }

    updateDomainValues(value: any): any[] {
        if (!value || this.lastValue === value) return this.domainValue;
        this.lastValue = value;
        // let value = claim.value ? claim.value.metaTextValue: '';
        if (value.startsWith("@")) {
            let dataSource = this.dataSourceProvider.createDataSource(value.substr(1));
            if (dataSource) {
                dataSource.fetchData(CRUD.List).subscribe({
                    next: _ => (this.domainValue = dataSource.data.map(item => item["name"])),
                    error: _ => (this.domainValue = [])
                });
            } else this.domainValue = [];
        } else this.domainValue = value.split(",");
        return this.domainValue;
    }

    updateName(parent: NgSelectComponent, claim: NgSelectComponent) {
        this.editingItem.name = `${claim.selectedLabels.join(", ")} for ${parent.selectedLabels.join(",")}`;
    }

    ngOnInit() {
        super.ngOnInit;
        if (this.host.isNewItem) {
            this.editingItem.parentId = this.host.routeSnapshot.params["parentId"];
        }
    }
}
