import { AbstractViewerPartComponent } from "./abstract-viewer-part";
import { ObjectPropertyDescriptor, ObjectPropertyHost } from "../models";
import { Input } from "@angular/core";

export class AbstractPropertyPage<T> 
        extends AbstractViewerPartComponent<ObjectPropertyDescriptor> {

    @Input() get host(): ObjectPropertyHost<ObjectPropertyDescriptor> { return this._host; }
    set host(value: ObjectPropertyHost<ObjectPropertyDescriptor>) {
        if (value === this._host)
            return;
        this._host = value;
        this.onHostChanged();
        if (value.editingItem)
            this.onEditingItemChanged();
        this._host.onEditingItemChanged
                    .subscribe((_ : any) => this.onEditingItemChanged());
    }
    private _host: ObjectPropertyHost<ObjectPropertyDescriptor>;

    public get editingItem(): T { return this.host.editingItem; }
    public get isNewItem(): boolean { return this.host.isNewItem; }

    protected onHostChanged(): void {
        // do nothing
    }

    protected onEditingItemChanged(): void {
        // do nothing
    }

}