import { AbstractObjectViewerHost } from "./abstract-object-viewer-host";
import {
    Action,
    evalField,
    DataSourceProvider,
    ActionProvider,
    AccessControlService,
    DefaultActionSet,
    ActionHandlingContext,
    DataContext,
    MessageService,
    DataSource,
    ActionPreparingContextHandler,
    ActionMessageChannel,
    SearchField
} from "../../core";
import { ObjectDescriptor, ObjectExploringDescriptor, StandardActionSet } from "../models";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { ObjectExploringDescriptorProvider } from "../provider";
import { Input, OnInit } from "@angular/core";

export abstract class AbstractObjectExplorerHost extends AbstractObjectViewerHost<ObjectExploringDescriptor>
    implements ActionPreparingContextHandler, OnInit {
    private _listAction: Action[] = undefined;
    private _explorerAction: Action[] = undefined;
    private queryParams: Params;

    @Input("manifest") manifestName: string = undefined;

    @Input() parentManifest: string;
    @Input("parentObject") parentObjectId: string = undefined;
    @Input("parentField") parentField: string;
    @Input("searchFields") searchFields: SearchField[];
    @Input() dataSource: DataSource<any>;

    public get tableSettings(): ObjectDescriptor {
        return this.manifest.tableSettings;
    }
    public get title(): string {
        return evalField(this.manifest.title, this.manifest);
    }

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected browsingDescriptor: ObjectExploringDescriptorProvider,
        protected dataSourceProvider: DataSourceProvider,
        protected actionProvider: ActionProvider,
        protected accessControl: AccessControlService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        super(activatedRoute, actionProvider, accessControl, messageService, router);
        this.activatedRoute.queryParams.subscribe({
            next: params => (this.queryParams = params)
        });
        this.messageSubscriptions.push(
            ActionMessageChannel.subscribeActionMessageHandler(messageService, this).setFilter({
                topic: ActionMessageChannel.PREPARE_CONTEXT
            })
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.updateManifest(this.manifestName);
    }

    protected createActionHandlingContext() {
        let context = super.createActionHandlingContext();

        context.extra = {
            parentManifest: this.parentManifest,
            parentField: this.parentField,
            parentObjectId: this.parentObjectId
        };
        return context;
    }

    protected updateManifest(value: string) {
        if (value === this.manifestName && this.manifest) return;
        this.manifestName = value;
        if (this.manifestName) {
            let manifest = this.browsingDescriptor.getDescriptor(this.manifestName);
            if (!manifest) {
                console.error(`Undefined object exploring manifest: ${this.manifestName}`);
                return;
            }
            this.manifest = manifest;
        }
    }

    handleActionPreparingContext(context: ActionHandlingContext): void {
        let retParam: string = context.navigationExtras.queryParams["retparam"] || "";
        retParam =
            (retParam.length > 0 ? `${retParam}|` : "") +
            `p.${this.manifest.name}=${this.context.dataSource.option.page}|ps.${this.manifest.name}=${
                this.context.dataSource.option.pageSize
            }`;
        context.navigationExtras.queryParams["retparam"] = retParam;
    }

    protected manifestChanged(): void {
        this._explorerAction = undefined;
        this._listAction = undefined;
        super.manifestChanged();
        let dataSource = this.dataSource;
        if (!dataSource) dataSource = this.dataSourceProvider.createDataSource<any>(this.manifest.dataSource);
        if (!dataSource) {
            console.error(`Undefined data source: ${this.manifest.dataSource}`);
        }
        let page: number = 1;
        let pageSize: number = 10;

        if (this.queryParams) {
            page = this.queryParams[`p.${this.manifest.name}`] || page;
            pageSize = this.queryParams[`ps.${this.manifest.name}`] || pageSize;
        }

        this.context = new DataContext<any>(dataSource);
        this.context.dataSource.option.updatePage(page, pageSize);
        this.loadData();
    }

    public getActions(name: string): Action[] {
        if (!this.manifest) return undefined;
        switch (name) {
            case StandardActionSet.ListItemActions:
                this._listAction = this.buildActions(
                    this._listAction,
                    this.manifest.tableSettings.actions,
                    DefaultActionSet.DEFAULT_GRID_ACTION_SET
                );
                return this._listAction;
            case StandardActionSet.DefaultActions:
            case StandardActionSet.ExplorerActions:
                this._explorerAction = this.buildActions(
                    this._explorerAction,
                    this.manifest.actions,
                    DefaultActionSet.DEFAULT_EXPLORER_ACTION_SET
                );
                return this._explorerAction;
            default:
                return undefined;
        }
    }

    public loadData() {
        this.prepareQueryOption();
        this.context.dataSource.list();
    }

    protected prepareQueryOption() {
        this.context.dataSource.option.searchFields = this.searchFields || [];
        if (this.parentObjectId)
            this.context.dataSource.option.searchFields.push({
                name: this.parentField || "parentId",
                operator: "=",
                value: this.parentObjectId
            });
    }

    protected handlePostAction(context: ActionHandlingContext) {
        super.handlePostAction(context);
        switch (context.action.name) {
            case "delete":
                this.loadData();
                break;
        }
    }
}
